import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root"
})

export class CrashReportWindowService {
    _window = new BehaviorSubject<Window | undefined>(undefined);
    _isOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    _showing = new BehaviorSubject<string | undefined>(undefined);
    $window = this._window.asObservable();
    $isOpen = this._isOpen.asObservable();
    userName = "mtc";
    constructor() {}

    public get window(): Window | undefined {
        return this._window.value;
    }
    public set window(reportWindow: Window | undefined) {
        this._window.next(reportWindow);
    }

    public get isOpen(): boolean {
        return this._isOpen.value;
    }
    public set isOpen(state: boolean) {
        this._isOpen.next(state);
    }

    public get showing(): string | undefined {
        return this._showing.value;
    }
    public set showing(reportNumber: string | undefined) {
        this._showing.next(reportNumber);
    }

    manage(reportNumber: string) {
        if (!this.window) {
            this.getUFCrash(reportNumber);
        } else {
            if (this.window.closed) {
                // the observable shows a closed window
                this.close();
                this.getUFCrash(reportNumber);
            } else {
                if (reportNumber !== this.showing) {
                    this.updateUFCrash(reportNumber);
                }
            }
        }
    }

    close() {
        if (this.window) {
            this.window.close();
        }
        this.window = undefined;
        this.isOpen = false;
        this.showing = undefined;
    }

    private getUFCrash(reportNumber: string) {
        let url = this.getCrashUrl(reportNumber);
        this.window = window.open(url) as Window;
        this.isOpen = true;
        this.showing = reportNumber;
    }

    private updateUFCrash(reportNumber: string) {
        let url = this.getCrashUrl(reportNumber);
        if (this.window) {
            this.window.location.replace(url);
            this.isOpen = true;
            this.showing = reportNumber;
        }
    }

    private getCrashUrl(reportNumber: string) {
        return `api/diagram/${this.userName}/view-report/${reportNumber}.pdf`;
    }
}
