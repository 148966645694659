import { UIService } from "src/app/shared/services/ui.service";
import { Component, HostListener } from "@angular/core";
import { DIAGRAM_STYLES } from "src/app/cdt-diagram/cdt-diagram-models";
import { diagramStyle, setStyle } from "src/app/shared/diagram-map";
import { CRUDService } from "src/app/shared/services/crud.service";
import { DiagramService } from "src/app/shared/services/diagram.service";

@Component({
    selector: "appearance-control",
    templateUrl: "./appearance-control.component.html",
    styleUrls: [
        "../map-controls.component.scss",
        "./appearance-control.component.scss"
    ]
})
export class AppearanceControlComponent {
    isOpen = false;
    options: string[] = DIAGRAM_STYLES;

    set selected(style) {
        setStyle(style);
        for (const inset of this.ui.insets) {
            inset.component?.changeDetector.detectChanges();
        }
    }

    get selected() {
        return diagramStyle;
    }

    constructor(
        private ui: UIService,
        private crud: CRUDService,
        private diagram: DiagramService
    ) {
        this.diagram.isReady.when(true, () => {
            this.applyStyle(diagramStyle);
        });
    }

    @HostListener("mouseenter", ["$event"]) mouseover($event: Event) {
        if (this.options.length > 0) {
            this.isOpen = true;
        }
    }
    @HostListener("mouseleave", ["$event"]) mouseleave($event: Event) {
        this.isOpen = false;
    }

    onSelect($event: string) {
        if ($event === this.selected) return;
        this.applyStyle($event);
        this.selected = $event;
        this.crud.saveDiagram({ styleCD: true });
    }

    applyStyle(style: string) {
        const diagram = document.getElementById("diagram");
        const mapPane = document.querySelectorAll(
            ".leaflet-map-pane"
        ) as NodeListOf<HTMLElement>;
        if (diagram && mapPane) {
            switch (style) {
                case "Color":
                    {
                        diagram.style.removeProperty("filter");
                        mapPane.forEach((el) => {
                            el.style.removeProperty("filter");
                        });
                    }
                    break;
                case "B&W Map":
                    {
                        diagram.style.removeProperty("filter");
                        mapPane.forEach((el) => {
                            el.style.setProperty("filter", "grayscale(1)");
                        });
                    }
                    break;
                case "All B&W":
                    {
                        diagram.style.setProperty("filter", "grayscale(1)");
                        mapPane.forEach((el) => {
                            el.style.setProperty("filter", "grayscale(1)");
                        });
                    }
                    break;
                default:
                    break;
            }
        }

        for (const inset of this.ui.insets) {
            inset.component?.changeDetector.detectChanges();
        }
    }
}
