import { AfterContentInit, Component} from "@angular/core";
import { UIService } from "../shared/services/ui.service";
import { goHome, isAtMaxZoom, isAtMinZoom, leafletMap, setMapOpacity, setupIsComplete } from "../shared/diagram-map";
import { CRUDService } from "../shared/services/crud.service";
import { getDiagramBounds } from "../shared/helpers";
@Component({
    selector: "map-controls",
    templateUrl: "./map-controls.component.html",
    styleUrls: ["./map-controls.component.scss"]
})
export class MapControlsComponent implements AfterContentInit {
    hasWarning = false;
    isShowingWarning = false;
    warningMessage = "";
    constructor(private ui: UIService, private crud: CRUDService) {}
    get isCropping() {
        return this.ui.isCropping;
    }
    get isRepositioning() {
        return this.ui.isRepositioning;
    }
    get layerOpacityString() {
        return `${this.layerOpacity * 100}%`;
    }
    get hasSidePanel() {
        return this.ui.sidePanelOpen;
    }
    set hasSidePanel(value: boolean) {
        this.ui.sidePanelOpen = value;
    }
    get layerOpacity() {
        return this.ui.layerOpacity;
    }

    set layerOpacity(value: number) {
        this.ui.layerOpacity = value;
        setMapOpacity(value);
        this.crud.saveDiagram({
            opacity: true
        });
    }

    ngAfterContentInit() {
        setupIsComplete.when(true, () => {
            this.watchZoom();
        });
    }

    public goHome(): void {
        goHome(this.ui.padding, getDiagramBounds());
    }

    public toggleSidePanel(e: MouseEvent) {
        this.hasSidePanel = !this.hasSidePanel;
    }

    private showWarningBriefly() {
        this.isShowingWarning = true;
        setTimeout(() => {
            this.isShowingWarning = false;
        }, 2000);
    }

    private watchZoom() {
        leafletMap.on("zoomend", () => {
            if (isAtMaxZoom()) {
                this.warningMessage = "max zoom in reached";
                this.showWarningBriefly();
                this.hasWarning = true;
            } else if (isAtMinZoom()) {
                this.warningMessage = "max zoom out reached";
                this.hasWarning = true;
                this.showWarningBriefly();
            } else {
                this.hasWarning = false;
                this.warningMessage = "";
            }
        });
    }
}
