import { Component } from "@angular/core";
import { UIService } from "src/app/shared/services";

@Component({
    selector: "crash-legend",
    templateUrl: "./crash-legend.component.html",
    styleUrls: [
        "./crash-legend.component.scss",
        "../map-controls.component.scss"
    ]
})
export class CrashLegendComponent {
    constructor(private ui: UIService) {}
    get isOpen() {
        return this.ui.legendIsOpen && !this.ui.isCropping;
    }
    set isOpen(value) {
        this.ui.legendIsOpen = value;
    }
}
