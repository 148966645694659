import { environment } from "./../../environments/environment";
import { KeyValue } from "@angular/common";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
    CrashFormData,
    ncicColorCodes,
    surrogateToHSMVLookupTable
} from "../cdt-diagram/cdt-diagram-models";
import { promptBox } from "../page-disabler/disable-enable-page";
import { CrashReportWindowService } from "../shared/services";
import { CRUDService } from "../shared/services/crud.service";
import { getUniqueId } from "../shared/helpers";

@Component({
    selector: "test-page",
    templateUrl: "./test-page.component.html",
    styleUrls: ["./test-page.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class TestPageComponent implements OnInit {
    newRecordCreated = false;
    artId = 0;
    vendorId = "";
    latitude = 0;
    longitude = 0;
    numberOfVehicles = 1;
    numberOfNonMotorists = 0;
    dataPut = false;
    private geoServiceBaseProd =
        "https://s4.geoplan.ufl.edu/geolocation30/GeolocationService.svc";
    private geoServiceBaseEnv = environment.production
        ? this.geoServiceBaseProd
        : "https://s4.geoplan.ufl.edu/geolocation301dev/GeolocationService.svc";

    crashFormData: {
        mannerOfCollision: string;
        firstHarmfulEvent: string;
        vehicles: {
            number: number;
            bodyType?: string;
            specialFunction?: string;
            cmvConfig?: string;
            cargoType?: string;
            colorCode?: string;
            travelDirection?: string;
            impactArea?: string;
            maneuver?: string;
            isHitAndRun?: string;
        }[];
        nonMotorists: {
            number: number;
            desc?: string;
        }[];
    } = {
        mannerOfCollision: "",
        firstHarmfulEvent: "",
        vehicles: [
            {
                number: 1
            }
        ],
        nonMotorists: []
    };

    crashReportFields = {
        mannerOfCollision: {
            "Front to Rear": 1,
            "Front to Front": 2,
            Angle: 3,
            "Sideswipe, same direction": 4,
            "Sideswipe, Opposite Direction": 5,
            "Rear to Side": 6,
            "Rear to Rear": 7,
            "Other, Explain in Narrative": 77,
            Unknown: 88
        },
        firstHarmfulEvent: {
            "Overturn/Rollover": 1,
            "Fire/Explosion": 2,
            Immersion: 3,
            Jackknife: 4,
            "Cargo/Equipment Loss or Shift": 5,
            "Fell/Jumped From Motor Vehicle": 6,
            "Thrown or Falling Object": 7,
            "Ran into Water/Canal": 8,
            "Other Non-Collision": 9,
            Pedestrian: 10,
            Pedalcycle: 11,
            "Railway Vehicle (train, engine)": 12,
            Animal: 13,
            "Motor Vehicle in Transport": 14,
            "Parked Motor Vehicle": 15,
            "Work Zone/Maintenance Equipment": 16,
            "Struck By Falling/Shifting Cargo": 17,
            "Other Non-Fixed Object": 18,
            "Impact Attenuator/Crash Cushion": 19,
            "Bridge Overhead Structure": 20,
            "Bridge Pier or Support": 21,
            "Bridge Rail": 22,
            Culvert: 23,
            Curb: 24,
            Ditch: 25,
            Embankment: 26,
            "Guardrail Face": 27,
            "Guardrail End": 28,
            "Cable Barrier": 29,
            "Concrete Traffic Barrier": 30,
            "Other Traffic Barrier": 31,
            "Tree (standing)": 32,
            "Utility Pole/Light Support": 33,
            "Traffic Sign Support": 34,
            "Traffic Signal Support": 35,
            "Other Post, Pole or Support": 36,
            Fence: 37,
            Mailbox: 38,
            "Other Fixed Object": 39
        },
        bodyType: {
            "Passenger Car": 1,
            "Passenger Van": 2,
            Pickup: 3,
            "Motor Home": 7,
            Bus: 8,
            Motorcycle: 11,
            Moped: 12,
            ATV: 13,
            "Low Speed Vehicle": 15,
            SUV: 16,
            "Cargo Van": 17,
            "Motor Coach": 18,
            "Other Light Trucks": 19,
            "Medium/Heavy Trucks": 20,
            "Farm Labor Vehicle": 21,
            Other: 77,
            Unknown: 88
        },
        specialFunction: {
            "No Special Function": 1,
            "Farm Vehicle": 2,
            Police: 3,
            Taxi: 7,
            Military: 8,
            Ambulance: 9,
            "Fire Truck": 10,
            "Farm Labor Transport": 11,
            "School Bus": 12,
            "Transit/Commuter Bus": 13,
            "Intercity Bus": 14,
            "Charter/Tour Bus": 15,
            "Shuttle Bus": 16,
            "Farm Labor Bus": 17,
            Unknown: 88
        },
        cmvConfig: {
            "Hazardous Materials": 1,
            "Single-Unit Truck 2 axles": 2,
            "Single-Unit Truck 3+ axles": 3,
            "Truck Pulling Trailer": 4,
            "Bobtail Truck Tractor": 5,
            "Truck Tractor/Semi-Trailer": 6,
            "Truck Tractor/Double Truck": 7,
            "Tractor/Triple": 8,
            "Heavy & Cannot Classify": 9,
            "Small Bus/Large Van": 10,
            Bus: 11,
            Other: 77,
            Unknown: 88
        },
        cargoType: {
            "No Cargo": 1,
            Bus: 2,
            "Van/Enclosed Box": 3,
            Hopper: 4,
            "Pole-Trailer": 5,
            "Cargo Tank": 6,
            Flatbed: 7,
            Dump: 8,
            "Concrete Mixer": 9,
            "Auto Transport": 10,
            "Garbage/Refuse": 11,
            Log: 12,
            "Intermodal Container Chassis": 13,
            "Vehicle Towing Another": 14,
            "Not Applicable": 15,
            Other: 77,
            Unknown: 88
        },
        maneuver: {
            "Straight Ahead": 1,
            "Turning Left": 3,
            Backing: 4,
            "Turning Right": 5,
            "Changing Lanes": 6,
            Parked: 8,
            "Making U-Turn": 10,
            "Overtaking/Passing": 11,
            "Stopped in Traffic": 13,
            Slowing: 14,
            "Negotiating a Curve": 15,
            "Leaving Traffic Lane": 16,
            "Entering Traffic Lane": 17,
            Other: 77,
            Unknown: 88
        },
        nmDescription: {
            Pedestrian: 1,
            "Other Pedestrian": 2,
            Bicyclist: 3,
            "Other Cyclist": 4,
            "Occupant of Vehicle Not in Transport": 5,
            "Occupant of Non-Motor Vehicle": 6,
            "Unknown Type": 7
        },
        colorCodes: ncicColorCodes,
        directions: ["North", "East", "South", "West", "Off-Road", "Unknown"],
        AOII: {
            "Front Center Bumper": 1,
            "Front Right Bumper": 2,
            "Right Front Fender": 3,
            "Right Front Door": 4,
            "Right Rear Door": 5,
            "Right Rear Fender": 6,
            "Rear Right Bumper": 7,
            "Rear Center Bumper": 8,
            "Rear Left Bumper": 9,
            "Left Rear Fender": 10,
            "Left Rear Door": 11,
            "Left Front Door": 12,
            "Left Front Fender": 13,
            "Front Left Bumper": 14,
            Hood: 15,
            Roof: 16,
            Trunk: 17,
            Undercarriage: 18,
            Overturn: 19,
            Windshield: 20,
            Trailer: 21
        }
    };

    constructor(
        private crashRptService: CrashReportWindowService,
        private crud: CRUDService
    ) {}

    async ngOnInit() {
        const existingArtId = window.sessionStorage.getItem("artifactId");
        const existingVendorId = window.sessionStorage.getItem("vendorId");

        if (existingArtId && existingVendorId) {
            this.artId = parseInt(existingArtId);
            this.vendorId = existingVendorId;

            const crashFormData = await this.crud.getCrashFormData(
                this.artId,
                this.vendorId
            );

            if (crashFormData) {
                this.loadThisCrashFormData(crashFormData);
                this.dataPut = true;
            }

            this.getLocation();
        }

        window.onchange = (e: Event) => {
            if (
                e.target &&
                (e.target as HTMLElement).classList.contains("align-right")
            ) {
                this.dataPut = false;
            }
        };

        window.onbeforeunload = () => {
            if (this.artId && !this.dataPut) {
                this.putData(true);
            }
        };
    }

    changeVehNumber(e: Event) {
        const number = parseInt((e.target as HTMLInputElement).value);

        if (number != this.crashFormData.vehicles.length) {
            if (number > this.crashFormData.vehicles.length) {
                for (
                    let i = this.crashFormData.vehicles.length;
                    i < number;
                    ++i
                ) {
                    this.crashFormData.vehicles.push({
                        number: i + 1
                    });
                }
            } else {
                this.crashFormData.vehicles.length = number;
            }
        }
    }

    changeNMNumber(e: Event) {
        const number = parseInt((e.target as HTMLInputElement).value);

        if (number && number != this.crashFormData.nonMotorists.length) {
            if (number > this.crashFormData.nonMotorists.length) {
                for (
                    let i = this.crashFormData.nonMotorists.length;
                    i < number;
                    ++i
                ) {
                    this.crashFormData.nonMotorists.push({ number: i + 1 });
                }
            } else {
                this.crashFormData.nonMotorists.length = number;
            }
        } else {
            this.crashFormData.nonMotorists.length = 0;
        }
    }

    sortByValue(a: KeyValue<string, number>, b: KeyValue<string, number>) {
        return a.value - b.value;
    }

    async import() {
        const input = document.querySelector("#hsmv-input") as HTMLInputElement;

        if (input.checkValidity()) {
            const number = parseInt(input.value);
            if (number) {
                this.importHSMV(number);
            }
        } else {
            await promptBox(
                "Come on, dude",
                "That is not a valid HSMV number!",
                ["I'm sorry"],
                "full-width"
            );
        }
    }

    private async importHSMV(hsmvNbr: number) {
        if (this.vendorId) {
            const choice = await promptBox(
                "Alert",
                "<span class='at-least-20'>The current form already has " +
                    "an S4 Geolocation record associated with it. This will overwrite " +
                    "the form data and create a new record</span>",
                ["Continue", "Cancel"]
            );

            if (choice != "Continue") {
                return;
            }

            this.newRecordCreated = false;
            this.clearForm();
        }

        const crashData = await this.crud.getHistoricCrashFormData(hsmvNbr);

        if (!crashData || !crashData.vehicles?.length) {
            await promptBox(
                "Uh-oh",
                `Report data for "${hsmvNbr}" could not be retrieved or doesn't exist.
                Make sure you are using a report number that exists in the S4_FLAT database.`,
                ["OK"]
            );
            return;
        }

        this.crashRptService.manage(hsmvNbr.toString());
        this.loadThisCrashFormData(crashData);
        this.dataPut = false;

        fetch(`api/diagram/get-art-id/${hsmvNbr}`)
            .then(async (response) => {
                if (!response.ok) {
                    if (response.status == 512) {
                        throw new Error("Not v3");
                    }

                    throw new Error(await response.text());
                }

                return response.text();
            })
            .then((idStr: string) => {
                this.copyGeolocationData(idStr);
            })
            .catch(async (error: Error) => {
                if (error.message == "Not v3") {
                    this.generateV3Record(hsmvNbr);
                } else {
                    await promptBox("Failure", error.message, ["OK"]);
                }
            });
    }

    private async generateV3Record(hsmvNbr: number) {
        const coordsResult = await this.getCoords(hsmvNbr);
        if (!coordsResult) return;
        const [lat, lng] = coordsResult;
        const relToNetwork = await this.getRelToNetwork(hsmvNbr);
        if (!relToNetwork) return;
        const geocodeResult = await this.geocode(lat, lng, relToNetwork);
        if (!geocodeResult) return;

        const location = geocodeResult.Location;
        const uniqueId = this.createId();
        this.vendorId = uniqueId;
        this.latitude = location.Latitude;
        this.longitude = location.Longitude;

        this.insertCopyIntoDevDatabase({
            Location: location,
            Incident: {
                ApiKey: "KM2YJTkm4L",
                AgencyCaseNumber: uniqueId,
                AgencyId: "FL9999999"
            },
            Artifact: {
                ArtifactType: "Crash",
                StateReportNumber: uniqueId,
                VendorReportNumber: uniqueId,
                UserId: "S4Diagram_Tester",
                SubtypeJson: JSON.stringify({
                    FormType: "L"
                }),
                IsTest: "Y"
            }
        });
    }

    private geocode(lat: number, lng: number, mode: string) {
        return fetch(
            `${this.geoServiceBaseEnv}/geocode/json/KM2YJTkm4L/${lat}/${lng}/FL9999999/${mode}`
        )
            .then((res) => {
                if (!res.ok) {
                    throw new Error(
                        "Couldn't geocode the record's coordinates"
                    );
                }

                return res.json();
            })
            .catch(async (error) => {
                await promptBox("Failure", error.message, ["OK"]);
            });
    }

    private getRelToNetwork(hsmvNbr: number) {
        return fetch(`api/diagram/get-rel-to-network/${hsmvNbr}`)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(
                        "Something went wrong trying to get the record's relationship to network"
                    );
                }

                return res.text();
            })
            .catch(async (error) => {
                await promptBox("Failure", error.message, ["OK"]);
            });
    }

    private getCoords(hsmvNbr: number) {
        return fetch(`api/diagram/get-historic-coords/${hsmvNbr}`)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(
                        "Something went wrong retrieving the coordinates of the crash"
                    );
                }

                return res.json();
            })
            .then((json) => {
                if (json.latitude == 0) {
                    throw new Error(`Report ${hsmvNbr} has no location data.<br><br>
                    <i>You probably shouldn't use this record for testing</i>, but if you really want to you can geolocate it yourself.`);
                }

                return [json.latitude, json.longitude] as [number, number];
            })
            .catch(async (error) => {
                const choice = await promptBox(
                    "No Location Data",
                    `${error.message}`,
                    ["OK", "Geolocate"]
                );

                if (choice == "Geolocate") {
                    this.openS4Geolocation();
                } else {
                    this.clearForm();
                }
            });
    }

    private copyGeolocationData(parentId: string) {
        fetch(`${this.geoServiceBaseProd}/getbyid/json/KM2YJTkm4L/${parentId}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Parent record data retrieval failed");
                }

                return response.json();
            })
            .then((data) => {
                const uniqueId = this.createId();
                if (data.Artifact.ArtifactType != "Crash") {
                    for (const artifact of data.CaseArtifacts) {
                        if (artifact.ArtifactType == "Crash") {
                            data.Artifact = artifact;
                            break;
                        }
                    }
                }
                delete data.CaseArtifacts;
                data.Artifact.LocationId = 0;
                data.Artifact.StateReportNumber = uniqueId;
                data.Artifact.VendorReportNumber = uniqueId;
                data.Incident.AgencyCaseNumber = uniqueId;
                data.Artifact.IsTest = "Y";
                this.vendorId = uniqueId;
                this.latitude = data.Location.Latitude;
                this.longitude = data.Location.Longitude;
                this.insertCopyIntoDevDatabase(data);
            })
            .catch(async (error: Error) => {
                await promptBox("Uh-oh", error.message, ["OK"]);
            });
    }

    private createId() {
        return `S4DiagramTesting${getUniqueId()}`;
    }

    private insertCopyIntoDevDatabase(copy: any) {
        fetch(`${this.geoServiceBaseEnv}/newincident/json`, {
            method: "POST",
            body: JSON.stringify(copy)
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(
                        "Copying parent data to dev database failed."
                    );
                }

                return response.json();
            })
            .then((data) => {
                if (data.GeoServiceResult != 0) {
                    throw new Error(data.GeoServiceErrorMessage);
                }

                this.artId = parseInt(data.GeoServiceDataMessage.split(",")[2]);
                this.saveIds();
            })
            .catch(async (error: Error) => {
                await promptBox("Uh-oh", error.message, ["OK"], "full-width");
            });
    }

    private async saveIds() {
        if (
            window.sessionStorage.getItem("artifactId") == this.artId.toString()
        )
            return;

        window.sessionStorage.setItem("artifactId", this.artId.toString());
        window.sessionStorage.setItem("vendorId", this.vendorId);
        this.newRecordCreated = true;

        const testRecordUrl = this.getTestRecordUrl();
        await promptBox(
            "New Record Created",
            `A new test record was created. Please copy down the following URL
            so that you can revisit the diagram belonging to it later:<br><br>
            <span class="color-blue">${testRecordUrl}</span>`,
            ["Copy", "Skip"],
            "at-least-20",
            undefined,
            {
                Copy: () => {
                    navigator.clipboard.writeText(testRecordUrl);
                }
            }
        );
    }

    private loadThisCrashFormData(crashFormData: CrashFormData) {
        this.crashFormData.mannerOfCollision =
            crashFormData.mannerOfCollision == -1
                ? ""
                : surrogateToHSMVLookupTable.mannerOfCollision[
                      crashFormData.mannerOfCollision
                  ];

        this.crashFormData.firstHarmfulEvent =
            crashFormData.firstHarmfulEvent == -1
                ? ""
                : surrogateToHSMVLookupTable.firstHarmfulEvent[
                      crashFormData.firstHarmfulEvent
                  ];

        if (crashFormData.vehicles && crashFormData.vehicles.length) {
            this.numberOfVehicles = crashFormData.vehicles.length;

            for (let i = 0; i < crashFormData.vehicles.length; ++i) {
                if (i + 1 > this.crashFormData.vehicles.length) {
                    this.crashFormData.vehicles.push({
                        number: i + 1
                    });
                }
                for (const [prop, val] of Object.entries(
                    crashFormData.vehicles[i]
                )) {
                    if (val == -1) {
                        this.crashFormData.vehicles[i][prop] = "";
                    } else if (prop in surrogateToHSMVLookupTable) {
                        this.crashFormData.vehicles[i][prop] =
                            surrogateToHSMVLookupTable[prop][val];
                    } else if (prop === "colorCode") {
                        if (val in ncicColorCodes) {
                            this.crashFormData.vehicles[i][prop] = val;
                        } else {
                            const extractedCode = this.extractSingleCode(val);
                            if (extractedCode) {
                                this.crashFormData.vehicles[i][prop] =
                                    extractedCode;
                            } else {
                                this.crashFormData.vehicles[i][prop] = "";
                            }
                        }
                    } else {
                        this.crashFormData.vehicles[i][prop] = val.toString();
                    }
                }
            }
        } else {
            this.numberOfVehicles = this.crashFormData.vehicles.length = 1;
        }

        if (crashFormData.nonMotorists && crashFormData.nonMotorists.length) {
            this.numberOfNonMotorists = crashFormData.nonMotorists.length;

            for (let i = 0; i < crashFormData.nonMotorists.length; ++i) {
                if (i + 1 > this.crashFormData.nonMotorists.length) {
                    this.crashFormData.nonMotorists.push({
                        number: i + 1
                    });
                }
                for (const prop of Object.keys(crashFormData.nonMotorists[i])) {
                    if (crashFormData.nonMotorists[i][prop] == -1) {
                        this.crashFormData.nonMotorists[i][prop] = "";
                    } else if (prop in surrogateToHSMVLookupTable) {
                        this.crashFormData.nonMotorists[i][prop] =
                            surrogateToHSMVLookupTable[prop][
                                crashFormData.nonMotorists[i][prop]
                            ];
                    } else {
                        this.crashFormData.nonMotorists[i][prop] =
                            crashFormData.nonMotorists[i][prop].toString();
                    }
                }
            }
        } else {
            this.numberOfNonMotorists =
                this.crashFormData.nonMotorists.length = 0;
        }
    }

    private extractSingleCode(str: string) {
        const regex = /(?:^\s*([^/\s]+)\/|-\s*([^\s]+))/;
        const match = str.match(regex);
        return match ? match[1] || match[2] : null;
    }

    private tryConvertEntryValToNum(entry: any) {
        const asNum = Number(entry[1]);
        if (asNum) entry[1] = asNum;
        return entry;
    }

    putData(persist = false) {
        const payload = Object.fromEntries(
            Object.entries(this.crashFormData)
                .filter((entry) =>
                    Array.isArray(entry[1]) ? entry[1].length : entry[1]
                )
                .map((entry: any) => {
                    if (Array.isArray(entry[1])) {
                        entry[1] = entry[1].map((vehOrNM) => {
                            return Object.fromEntries(
                                Object.entries(vehOrNM)
                                    .filter((innerEntry) => innerEntry[1])
                                    .map((innerEntry) =>
                                        this.tryConvertEntryValToNum(innerEntry)
                                    )
                            );
                        });
                    } else {
                        entry[1] = Number(entry[1]);
                    }

                    return entry;
                })
        );

        fetch(`api/diagram/put-crash-data/${this.artId}/${this.vendorId}`, {
            method: "PUT",
            body: JSON.stringify(payload),
            headers: { "Content-Type": "application/json" },
            keepalive: persist
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to upload crash data to S4Diagram");
                }

                return response.json();
            })
            .then(async () => {
                await promptBox(
                    "Success!",
                    "Crash data successfully uploaded",
                    ["OK"],
                    "full-width"
                );

                this.dataPut = true;
            })
            .catch(async (error: Error) => {
                await promptBox("Uh-oh", error.message, ["OK"], "full-width");
            });
    }

    async openS4Geolocation() {
        let newIdCreated = false;

        if (!this.vendorId) {
            this.vendorId = this.createId();
            newIdCreated = true;
        }

        const urlBase = environment.production
            ? "https://s4.geoplan.ufl.edu/geolocation30"
            : "https://s4.geoplan.ufl.edu/geolocation301dev";
        const staticPart =
            urlBase +
            "/GeolocationService.htm?k=99AMUT9e4u&ai=FL9999999&ui=diagram_tester&t=1";
        const n = this.vendorId;
        const dynamicPart = `&n=${n}&ac=${n}&vi=${n}`;

        window.open(`${staticPart}${dynamicPart}`, "_blank")?.focus();
        const choice = await promptBox(
            "S4 Geolocation",
            "Click OK when you have finished locating the crash",
            ["OK", "CANCEL"],
            undefined
        );

        if (choice == "OK") {
            this.getLocation();
        } else if (newIdCreated) {
            this.vendorId = "";
        }
    }

    private getLocation() {
        fetch(
            `${this.geoServiceBaseEnv}/getlocation/json/99AMUT9e4u/${this.vendorId}/1`
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Call to getlocation failed");
                }

                return response.json();
            })
            .then((geocodedLoc) => {
                if (geocodedLoc.GeoServiceResult == 0) {
                    this.artId = geocodedLoc.GeoServiceRecordId;
                    this.latitude = geocodedLoc.Latitude;
                    this.longitude = geocodedLoc.Longitude;
                    this.dataPut = true;
                    this.saveIds();
                } else {
                    this.vendorId = "";
                }
            })
            .catch(async (error: Error) => {
                await promptBox("Uh-oh", error.message, ["OK"], "full-width");
            });
    }

    goToDiagram() {
        window.sessionStorage.setItem("userIsTesting", "true");
        window.location.assign(this.getTestRecordUrl());
    }

    private getTestRecordUrl() {
        const pathDirs = window.location.pathname.split("/");

        return `${window.location.origin}${
            pathDirs.length > 2 ? "/" + pathDirs[1] : ""
        }/diagram?id=${this.artId}&vi=${this.vendorId}`;
    }

    async clearForm() {
        if (this.newRecordCreated) {
            const choice = await promptBox(
                "Alert",
                "You created a new diagram test record, but never opened the diagram. " +
                    "Are you sure you want to clear this form and forget that record?",
                ["Yes", "Cancel"]
            );

            if (choice != "Yes") {
                return;
            }
        }

        this.artId = 0;
        this.vendorId = "";
        window.sessionStorage.clear();
        this.latitude = 0;
        this.longitude = 0;
        this.numberOfVehicles = 1;
        this.numberOfNonMotorists = 0;
        this.crashFormData = {
            mannerOfCollision: "",
            firstHarmfulEvent: "",
            vehicles: [
                {
                    number: 1
                }
            ],
            nonMotorists: []
        };
    }
}
