<kendo-grid
    #crGrid
    class="crash-report-grid"
    [data]="crashParticipants"
    (dataStateChange)="onDataStateChange()"
    [height]="height"
    [rowClass]="rowCallback"
>
    <ng-template kendoGridNoRecordsTemplate>
        No crash report data provided
    </ng-template>
    <kendo-grid-column
        field="isDiagrammed"
        title="Diagrammed"
        [headerClass]="{ 'cr-grid-header': true }"
        [style]="{ 'text-align': 'center', padding: '1px' }"
        [width]="50"
        [class]="{ 'highlight-if-flagged': true }"
    >
        <ng-template
            kendoGridCellTemplate
            let-crashReportItem
            let-rowIndex="rowIndex"
        >
            <input
                name="diagrammed"
                type="checkbox"
                class="large-checkbox"
                [disabled]="gridDisabled"
                [(checked)]="crashReportItem.isDiagrammed"
                (change)="checkBoxChangeHandler($event, rowIndex)"
            />
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="number"
        title="#"
        [headerClass]="{ 'cr-grid-header': true }"
        [style]="{ 'text-align': 'center' }"
        [width]="20"
    ></kendo-grid-column>
    <kendo-grid-column
        field="icon"
        title="Icon"
        [style]="{ 'text-align': 'center' }"
        [headerClass]="{ 'cr-grid-header': true }"
        [width]="45"
    >
        <ng-template kendoGridCellTemplate let-crashReportItem>
            <img
                *ngIf="crashReportItem.icon"
                class="grid-icon"
                [src]="'assets/SVG/Top View/' + crashReportItem.icon + '.svg'"
                [attr.data-participant-color]="crashReportItem.color"
                onload="SVGInject(this, { afterInject: (img, svg) => {
                    if (svg.dataset.participantColor) {
                        const colorables = svg.querySelectorAll('.colorable');
                        for (const colorable of colorables) {
                            for (const path of colorable.children) {
                                path.setAttribute('fill', svg.dataset.participantColor);
                            }
                        }
                    }
                }})"
            />
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="dir"
        title="Traveling"
        [width]="45"
        [style]="{ 'text-align': 'center' }"
        [headerClass]="{ 'cr-grid-header': true }"
    >
        <ng-template
            kendoGridCellTemplate
            let-crashReportItem
            let-rowIndex="rowIndex"
        >
            <div [attr.data-dir-index]="rowIndex" class="whole-cell">
                {{ crashReportItem.dir }}
            </div>
        </ng-template>
        >
    </kendo-grid-column>
    <kendo-grid-column
        field="initialImpact"
        title="Area of Initial Impact"
        [style]="{ 'text-align': 'center' }"
        [width]="80"
        [headerClass]="{ 'cr-grid-header': true }"
    >
        <ng-template kendoGridCellTemplate let-crashReportItem let-i="rowIndex">
            <img
                *ngIf="crashReportItem.initialImpact !== undefined"
                [src]="getAOIIImage(crashReportItem.initialImpact)"
                height="56"
            />
            <div *ngIf="crashReportItem.initialImpact === undefined">-</div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="maneuver"
        title="Maneuver"
        [width]="60"
        [style]="{ 'text-align': 'center' }"
        [headerClass]="{ 'cr-grid-header': true }"
    ></kendo-grid-column>
</kendo-grid>
