<div class="loctype-btns">
    <button
        *ngFor="let button of buttons"
        [disabled]="disabled"
        class="loctype-btn"
        [class.is-pressed]="button.value === value"
        (click)="onButtonPress(button.value)"
    >
        <img src="assets/{{ button.icon }}" />
    </button>
</div>
