import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild
} from "@angular/core";
import { UIService } from "../shared/services";
import {
    GeoServiceLocation,
    GeolocationService,
    RelToNwk
} from "../shared/services/geolocation.service";
// import {
//     locationFound,
//     newLocation,
//     popupPoint,
//     setChangeDetector,
//     setStreet1,
//     setStreet2,
//     swapStreets,
//     windowButtons
// } from "./crash-point-update";
import * as paper from "paper";
import Point from "../shared/S4Point";
import {
    DiagramLayer,
    getLayer,
    lockLayers,
    unlockLayers
} from "../shared/layer";
import { Cursor, clearCursor, setCursor } from "../shared/cursor";
import {
    disableMapDragging,
    enableMapDragging,
    leafletMap,
    viewPointToLatLng,
    setLatLng,
    projectPointToLatLng
} from "../shared/diagram-map";
import { DiagramService } from "../shared/services/diagram.service";
import { promptBox } from "../page-disabler/disable-enable-page";
import { repositionTextboxes } from "../shared/helpers";
import { DiagramObject } from "../classes/DiagramObject";
import { DiagramAction, RelocateAction } from "../shared/Action";

interface ButtonAttributes {
    text: string;
    icon?: string;
    action: Function;
}

@Component({
    selector: "geoloc-point-menu",
    templateUrl: "./geoloc-point-menu.component.html",
    styleUrls: ["./geoloc-point-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeolocPointMenuComponent implements OnInit {
    @ViewChild("geolocWindow")
    public geolocWindow: ElementRef;
    public newLocation: GeoServiceLocation | undefined = undefined;
    windowButtons: ButtonAttributes[];
    private popupPoint = new Point(0, 0);
    private crashPointGhost: paper.Item;
    private rejector: Function;
    private resolver: Function;
    private wholeDiagramMode = false;
    private repositionHandlers = {
        mousemove: this.moveCrashPoint.bind(this),
        click: this.setCrashPoint.bind(this)
    };
    locationFound = false;
    chosenRelToNwk: RelToNwk;
    isReady = false;
    geocodingIsInProgress = false;
    manualOverwriteEnabled = false;
    private locationCache = new Map<RelToNwk, [GeoServiceLocation, boolean]>();

    constructor(
        private ui: UIService,
        public geoService: GeolocationService,
        public changeDetector: ChangeDetectorRef
    ) {
        this.updateMenu = this.updateMenu.bind(this);
        this.removeGhost = this.removeGhost.bind(this);
        this.acceptRepositioning = this.acceptRepositioning.bind(this);
        this.cancelRepositioning = this.cancelRepositioning.bind(this);
    }

    get street1() {
        return this.newLocation?.StreetName || this.street1Names[0];
    }

    set street1(name: string) {
        if (!this.newLocation) return;
        this.newLocation.StreetName = name;
    }

    get street2() {
        return (
            this.newLocation?.NearestIntersectingStreetName ||
            this.street2Names[0]
        );
    }

    set street2(name: string) {
        if (!this.newLocation) return;
        this.newLocation.NearestIntersectingStreetName = name;
    }

    get streetAddress() {
        return this.newLocation?.StreetAddressNumber || "";
    }

    set streetAddress(address: string) {
        if (!this.newLocation) return;
        this.newLocation.StreetAddressNumber = address;
    }

    get shown() {
        return this.ui.showGeolocWindow;
    }

    get locationType() {
        if (this.newLocation) {
            if (this.newLocation.RelationshipToNetwork == "SEGMENT") {
                return "ROAD SEGMENT";
            }

            return this.newLocation?.RelationshipToNetwork;
        }

        return "";
    }

    get originalLocation() {
        return this.geoService.geoServiceData.Location as GeoServiceLocation;
    }

    get street1Names() {
        if (this.locationFound && this.newLocation) {
            if (this.newLocation.StreetNamesAlt.length) {
                return this.newLocation.StreetNamesAlt;
            } else if (this.newLocation.StreetName) {
                return [this.newLocation.StreetName];
            }
        }

        return [`No ${this.chosenRelToNwk?.toLowerCase()} found`];
    }

    set street1Names(names: string[]) {
        if (!this.newLocation) return;

        this.newLocation.StreetNamesAlt = names;
        this.changeDetector.detectChanges();
    }

    get street2Names() {
        if (this.locationFound) {
            if (this.newLocation!.StreetNamesAlt.length) {
                return this.newLocation!.NearestIntersectingStreetNamesAlt;
            } else {
                return [this.newLocation!.NearestIntersectingStreetName];
            }
        }

        return ["?"];
    }

    set street2Names(names: string[]) {
        if (!this.newLocation) return;

        this.newLocation.NearestIntersectingStreetNamesAlt = names;
        this.changeDetector.detectChanges();
    }

    get preposition() {
        if (!this.newLocation) {
            return "";
        }

        switch (this.newLocation.RelationshipToNetwork) {
            case "SEGMENT":
            case "RAMP":
                return "On";
            case "INTERSECTION":
            case "OFFROADWAY":
                return "At";
        }

        return "";
    }

    get offsetFeet() {
        return this.newLocation?.NearestIntersectionOffsetFeet ?? 0;
    }

    set offsetFeet(n: number) {
        if (!this.newLocation) return;
        this.newLocation.NearestIntersectionOffsetFeet = n;
    }

    get offsetDir() {
        return this.newLocation?.NearestIntersectionOffsetDir || "?";
    }

    set offsetDir(dir: string) {
        if (!this.newLocation) return;
        this.newLocation.NearestIntersectionOffsetDir = dir;
    }

    get originalRelation() {
        switch (this.originalLocation.RelationshipToNetwork) {
            case "SEGMENT":
            case "RAMP":
                return `${this.originalLocation.NearestIntersectionOffsetFeet} feet ${this.originalLocation.NearestIntersectionOffsetDir} of`;
            case "INTERSECTION":
                return "&";
        }

        return "";
    }

    get placeAndPoint() {
        if (!this.newLocation) {
            return "";
        }

        return (
            `${this.newLocation.CityName}, ${this.newLocation.CountyName} County, ${this.newLocation.StateName} ` +
            `(${this.newLocation.Latitude.toFixed(6)}, ` +
            `${this.newLocation.Longitude.toFixed(6)})`
        );
    }

    get windowLeft() {
        return `${this.popupPoint.x}px`;
    }

    get windowBottom() {
        return `-${this.popupPoint.y}px`;
    }

    swapStreets() {
        if (!this.newLocation) return;
        [
            this.newLocation.StreetNamesAlt,
            this.newLocation.NearestIntersectingStreetNamesAlt
        ] = [
            this.newLocation.NearestIntersectingStreetNamesAlt,
            this.newLocation.StreetNamesAlt
        ];
        [this.street1, this.street2] = [this.street2, this.street1];

        this.changeDetector.detectChanges();
    }

    ngOnInit(): void {
        this.geoService.geoDataIsLoaded.when(true, () => {
            this.isReady = true;
            this.chosenRelToNwk = this.originalLocation.RelationshipToNetwork;
        });
    }

    private updateMenuTimeout: number;

    moveCrashPoint(e: paper.MouseEvent) {
        this.updateLayersCrashPointAndLatLng(e.point);
    }

    updateLayersCrashPointAndLatLng(point: paper.Point) {
        DiagramService.crashPointObject.position = point;

        if (this.wholeDiagramMode) {
            setLatLng(projectPointToLatLng(point));

            for (const layer of paper.projects[0].layers) {
                if (layer.name != DiagramLayer[DiagramLayer.crashPoint]) {
                    layer.position = point;
                }

                layer.pivot = point;
            }

            repositionTextboxes(point);
        }
    }

    async setCrashPoint(e: paper.MouseEvent) {
        this.locationCache.clear();
        paper.projects[0].view.off(this.repositionHandlers);
        setCursor(Cursor.Arrow);

        this.updateLayersCrashPointAndLatLng(e.point);
        const viewPoint = paper.projects[0].view.projectToView(
            DiagramService.crashPointObject.position
        );

        paper.projects[0].view.element.style.cursor = Cursor.Wait;
        const lockedRelToNwk = this.chosenRelToNwk;

        this.geocodingIsInProgress = true;
        const candidate = await this.geoService.getGeolocationCandidate(
            lockedRelToNwk,
            viewPointToLatLng(viewPoint)
        );
        this.geocodingIsInProgress = false;

        paper.projects[0].view.element.style.removeProperty("cursor");

        if (candidate) {
            if (candidate.ServiceResult.GeoServiceResult === 0) {
                this.locationFound = true;
            } else {
                this.locationFound = false;
            }

            this.newLocation = candidate.Location;
            this.locationCache.set(lockedRelToNwk, [
                this.newLocation,
                this.locationFound
            ]);
            this.ui.showGeolocWindow = true;
            this.updateMenu();
            leafletMap.on("moveend", this.updateMenu);
        } else {
            await promptBox(
                "Geolocation Failure",
                "Sorry, you will need return to S4 Geolocation (location tool) to edit the point of impact. " +
                    "Close this page and come back when finished accepting the new point."
            );
            this.cancelRepositioning();
        }
    }

    async onChangeRelToNwt(newRelToNwk: RelToNwk) {
        this.chosenRelToNwk = newRelToNwk;

        let [location, wasFound] = this.locationCache.get(newRelToNwk) ?? [
            undefined,
            false
        ];

        if (!location) {
            const viewPoint = paper.projects[0].view.projectToView(
                DiagramService.crashPointObject.position
            );

            this.geocodingIsInProgress = true;

            const candidate = await this.geoService.getGeolocationCandidate(
                newRelToNwk,
                viewPointToLatLng(viewPoint)
            );

            this.geocodingIsInProgress = false;

            if (candidate) {
                if (candidate.ServiceResult.GeoServiceResult == 0) {
                    wasFound = true;
                }

                location = candidate.Location;
                this.locationCache.set(newRelToNwk, [location, wasFound]);
            } else {
                await promptBox(
                    "Geolocation Failure",
                    "Sorry, you will need return to S4 Geolocation (location tool) to edit the point of impact. " +
                        "Close this page and come back when finished accepting the new point."
                );
                this.cancelRepositioning();
            }
        }

        this.newLocation = location;
        this.locationFound = wasFound;
        this.changeDetector.detectChanges();
    }

    updateMenu() {
        const popupTarget = paper.projects[0].view.projectToView(
            DiagramService.crashPointObject.entireObject.bounds.topCenter
        );
        this.popupPoint = new Point(popupTarget.x, popupTarget.y);
        this.changeDetector.detectChanges();

        window.clearTimeout(this.updateMenuTimeout);

        this.updateMenuTimeout = window.setTimeout(() => {
            const wrapper = document.querySelector(
                "#geoloc-window"
            ) as HTMLElement;
            const menu = wrapper.firstElementChild as HTMLElement;

            const menuRect = menu.getBoundingClientRect();
            const paddedMenuRect = new DOMRect(
                menuRect.x - 20,
                menuRect.y - 20,
                menuRect.width + 40,
                menuRect.height + 70
            );

            const diagramRect = document
                .querySelector("#diagram")
                ?.getBoundingClientRect() as DOMRect;

            let xOffset = 0,
                yOffset = 0;

            if (paddedMenuRect.left < diagramRect.left) {
                xOffset = paddedMenuRect.left - diagramRect.left;
            } else if (paddedMenuRect.right > diagramRect.right) {
                xOffset = paddedMenuRect.right - diagramRect.right;
            }

            if (paddedMenuRect.top < diagramRect.top) {
                yOffset = paddedMenuRect.top - diagramRect.top;
            } else if (paddedMenuRect.bottom > diagramRect.bottom) {
                yOffset = paddedMenuRect.bottom - diagramRect.bottom;
            }

            if (xOffset || yOffset) {
                leafletMap.panBy([xOffset * 1.1, yOffset * 1.1]);
            }
        }, 300);
    }

    // resizeInput(e: Event, offset = 0) {
    //     const input = e.currentTarget as HTMLInputElement;
    //     this._resizeInput(input, offset);
    // }

    // private _resizeInput(input: HTMLInputElement, offset = 0) {
    //     console.log("resizing", input);
    //     input.style.width = `calc(${input.value.length + 0.5}ch + ${offset}px)`;
    // }

    private measurementCanvas = document.createElement(
        "canvas"
    ) as HTMLCanvasElement;

    inputLength(elId: string, text: string, pxOffset = 0) {
        const context = this.measurementCanvas.getContext("2d")!;
        const el = document.querySelector(`#${elId}`)!;
        context.font = window.getComputedStyle(el).font;
        return `${Math.round(context.measureText(text).width) + pxOffset}px`;
    }

    removeRepositioningUI() {
        leafletMap.off("moveend", this.updateMenu);
        window.removeEventListener("beforeunload", this.removeGhost);
        this.ui.showGeolocWindow = false;
        this.crashPointGhost.remove();
        clearCursor();
        this.ui.isRepositioning = false;
        this.ui.gridDisabled = false;
        this.ui.controlButtonsVisible = true;
        enableMapDragging();
        unlockLayers();
    }

    cancelRepositioning() {
        this.removeRepositioningUI();
        DiagramAction.currentAction?.cancel();
        this.rejector("Position update cancelled");
    }

    async acceptRepositioning() {
        this.ui.showGeolocWindow = false;
        leafletMap.off("moveend", this.updateMenu);
        const choice = await promptBox(
            "Notice",
            `Updating the crash position may cause some or all the address information
            on the crash report to be overwritten with the new data.<br><br>Note: Using "Reset" will re-automate
            the diagram around the new point. Use "Undo" if you need to restore the previous point.`,
            ["OK", "Cancel"]
        );
        if (choice == "OK") {
            if (this.newLocation) {
                this.geoService
                    .updateLocationRecord(this.newLocation)
                    .then((locationWasUpdated) => {
                        if (!locationWasUpdated) {
                            this.cancelRepositioning();
                        } else {
                            this.removeRepositioningUI();

                            if (!this.wholeDiagramMode) {
                                setLatLng(
                                    projectPointToLatLng(
                                        DiagramService.crashPointObject.position
                                    )
                                );

                                for (const layer of paper.projects[0].layers) {
                                    layer.pivot =
                                        DiagramService.crashPointObject.position;
                                }

                                repositionTextboxes(
                                    DiagramService.crashPointObject.position
                                );
                            }

                            DiagramAction.currentAction?.stopRecording();
                            this.resolver(true);
                        }
                    });
            }
        } else {
            this.cancelRepositioning();
        }
    }

    removeGhost() {
        this.crashPointGhost?.remove();
    }

    enterMenuOnlyMode() {
        this.windowButtons = [
            { text: "Accept", action: this.acceptRepositioning.bind(this) }
        ];

        return new Promise<boolean>((res, rej) => {
            this.resolver = res;
            this.rejector = rej;
        });
    }

    async repositionCrashPoint(wholeDiagram: boolean): Promise<boolean> {
        new RelocateAction({
            name: wholeDiagram ? "relocate entire diagram" : "relocate POI only"
        }).startRecording();

        this.windowButtons = [
            { text: "Revert", action: this.cancelRepositioning },
            { text: "Accept", action: this.acceptRepositioning }
        ];

        this.wholeDiagramMode = wholeDiagram;
        this.ui.isDataGridCollapsed = true;
        this.ui.sidePanelOpen = false;
        this.ui.gridDisabled = true;
        this.ui.controlButtonsVisible = false;
        this.ui.isRepositioning = true;
        disableMapDragging();
        setCursor("none");
        DiagramObject.deselect(DiagramService.crashPointObject); // clear the selection border for clearer placement
        lockLayers();

        window.addEventListener("beforeunload", this.removeGhost);

        this.crashPointGhost = DiagramService.crashPointObject.core.clone();
        this.crashPointGhost.addTo(getLayer(DiagramLayer.crashPoint));
        this.crashPointGhost.data = {};
        this.crashPointGhost.opacity = 0.5;
        this.crashPointGhost.position =
            DiagramService.crashPointObject.position;

        paper.projects[0].view.on(this.repositionHandlers);

        return new Promise<boolean>((res, rej) => {
            this.resolver = res;
            this.rejector = rej;
        });
    }
}
