import { CircleSelectedObject } from "./CircleSelectedObject";
import {
    getInverseScaling,
    loadSVGFromFile,
    loadSVGFromPage
} from "../shared/helpers";
import { getDiagramZoomScale } from "../shared/diagram-map";
import { Icon, getIconScale, labelColorForCode } from "../shared/icon";
import { getEllipsisSymbol } from "../shared/ellipsis-button";
import * as paper from "paper";
import { NUM_OF_ANCHORS, Participant } from "./Participant";
import { NonMotorist } from "./NonMotorist";
import { CrashPointObject } from "./CrashPointObject";
import { getLayer, DiagramLayer } from "../shared/layer";

export abstract class DiagramObjectBuilder {
    abstract build(...args: any[]): any;

    protected addEllipsisButton(entireObject: paper.Item) {
        entireObject.addChild(getEllipsisSymbol(entireObject.bounds.topRight));
    }
}

export abstract class CircleSelectedObjectBuilder extends DiagramObjectBuilder {
    icon: string = "";
    iconFolder: string = "";
    rotatable: boolean;
    scalable: boolean;
    isMovable: boolean;
    hasBorder: boolean;
    pivotLocked: boolean;
    hasQuickPosBtns = false;

    constructor(
        rotatable = true,
        scalable = true,
        isMovable = true,
        hasBorder = false,
        pivotLocked = false
    ) {
        super();
        this.rotatable = rotatable;
        this.scalable = scalable;
        this.isMovable = isMovable;
        this.hasBorder = hasBorder;
        this.pivotLocked = pivotLocked;
    }

    protected async loadIcon(point: paper.Point, icon: string) {
        this.icon = icon;
        const iconUrl = "assets/SVG/" + this.iconFolder + "/" + icon + ".svg";
        const core = await loadSVGFromFile(iconUrl, true);
        if (!core) throw new Error("SVG load failed for: " + iconUrl);

        return core;
    }

    protected finishCore(
        core: paper.Item,
        point: paper.Point,
        icon: string,
        scale?: number
    ) {
        core.scale(scale ? scale : getIconScale(icon));
        core.position = point;
        core.name = "object core";
        core.data.pivotLocked = this.pivotLocked;
    }

    static createSelectionCircle(core: paper.Item, zoomScale?: number) {
        if (!zoomScale) zoomScale = getDiagramZoomScale();

        const sizeRect = core.bounds;
        const size =
            Math.sqrt(
                Math.pow(sizeRect.width, 2) + Math.pow(sizeRect.height, 2)
            ) * 1.05;

        return new paper.Path.Circle({
            center: core.position,
            radius: size * 0.6,
            strokeColor: "#fefefe",
            strokeWidth: 3 / zoomScale,
            fillColor: new paper.Color(0, 0, 255, 0.001),
            name: "selection circle",
            data: {
                passEvents: true
            }
        });
    }

    private groupWithSelectionControls(core: paper.Item): paper.Group {
        const sizeRect = core.bounds;
        const zoomScale = getDiagramZoomScale();
        const circle = CircleSelectedObjectBuilder.createSelectionCircle(
            core,
            zoomScale
        );

        // the groups: innerGroup rotates. this.entireObject doesn't, but moves everything
        const controls = new paper.Group([circle]);
        controls.data.thing = "selection controls";
        controls.name = "selection controls";
        controls.visible = false;
        const innerGroup = new paper.Group([controls, core]);
        innerGroup.name = "inner group";
        const entireObject = new paper.Group([innerGroup]);
        entireObject.name = "outer group";

        if (this.hasBorder) {
            // anchor points for arrows =====================================
            const border = new paper.Path({
                segments: [
                    sizeRect.topCenter,
                    sizeRect.topRight,
                    sizeRect.bottomRight,
                    sizeRect.bottomLeft,
                    sizeRect.topLeft
                ],
                visible: false,
                closed: true,
                name: "border"
            });
            innerGroup.addChild(border);
            // ==============================================================
        }

        if (this.scalable) {
            controls.addChild(
                CircleSelectedObjectBuilder.createScaleHandle(circle, zoomScale)
            );
            core.data.isScalable = true;
        }

        if (this.rotatable) {
            controls.addChild(
                CircleSelectedObjectBuilder.createRotationHandle(
                    circle,
                    zoomScale
                )
            );
            core.data.isRotatable = true;
        }

        if (this.hasQuickPosBtns) {
            controls.addChildren(
                ParticipantBuilder.createQuickPosButtons(circle, zoomScale)
            );
        }

        innerGroup.applyMatrix = false;
        innerGroup.pivot = core.bounds.center;
        entireObject.data.movingIsDisabled = !this.isMovable;
        entireObject.pivot = innerGroup.localToGlobal(core.bounds.center);
        core.data.baseScaling = innerGroup.scaling;

        return entireObject;
    }

    static createRotationHandle(circle: paper.Path, zoomScale?: number) {
        if (!zoomScale) zoomScale = getDiagramZoomScale();
        return new paper.Path.Circle({
            center: circle.getPointAt(circle.length),
            radius: 7,
            strokeWidth: 1,
            strokeColor: "#fefefe",
            fillColor: "#3d8aff",
            data: { thing: "rotation handle" },
            applyMatrix: false,
            name: "rotation handle",
            scaling: getInverseScaling(zoomScale).divide(
                circle.parent.parent.scaling
            )
        });
    }

    static createScaleHandle(
        circle: paper.Path,
        zoomScale?: number
    ): paper.Group {
        const r = 6;
        const p = circle.localToGlobal(circle.getPointAt(circle.length / 2));
        const tinyShift = r / 5;

        // triangles 1 and 2:
        const t1 = new paper.Path({
            segments: [
                [p.x - r, p.y - tinyShift],
                [p.x + r, p.y - tinyShift],
                [p.x, p.y - r * 2]
            ],
            strokeColor: "#fefefe",
            fillColor: "#3d8aff",
            strokeWidth: 1,
            closed: true
        });

        const t2 = new paper.Path({
            segments: [
                [p.x - r, p.y + tinyShift],
                [p.x + r, p.y + tinyShift],
                [p.x, p.y + r * 2]
            ],
            strokeColor: "#fefefe",
            fillColor: "#3d8aff",
            strokeWidth: 1,
            closed: true
        });

        // line in between the arrows to provide more clickable area
        const line = new paper.Path({
            segments: [
                [p.x + r, p.y],
                [p.x - r, p.y]
            ],
            strokeColor: "#3d8aff",
            strokeWidth: circle.strokeWidth * 1.1,
            opacity: 0.001
        });

        const scaleHandle = new paper.Group([line, t1, t2]);
        scaleHandle.strokeWidth = 1;
        scaleHandle.rotate(90); // the icons used to be facing East by default -- this is easier than changing all the points
        scaleHandle.name = "scale handle";
        scaleHandle.applyMatrix = false;
        scaleHandle.scaling = getInverseScaling(zoomScale).divide(
            circle.parent.parent.scaling
        );

        return scaleHandle;
    }

    protected commonBuildSteps(core: paper.Item): paper.Group {
        const entireObject = this.groupWithSelectionControls(core);
        this.addEllipsisButton(entireObject);
        entireObject.position = core.position;
        return entireObject;
    }
}

export class ParticipantBuilder<
    T extends Participant
> extends CircleSelectedObjectBuilder {
    hasQuickPosBtns = true;
    label: string = "";
    subtype: string = "";
    childClassConstructor?: new (entireObject: paper.Item) => T | any;

    constructor(
        childClassConstructor?: new (entireObject: paper.Item) => T,
        public colorCode = "GRY",
        public orientation = "Top",
        public isHitAndRun = false
    ) {
        super(true, false, true, true);
        this.childClassConstructor = childClassConstructor;
    }

    static createQuickPosButtons(circle: paper.Path, zoomScale?: number) {
        if (!zoomScale) zoomScale = getDiagramZoomScale();
        const center = new paper.Point(0, 0);
        const lineLen = 4;
        const l1 = new paper.Path.Line({
            from: new paper.Point(center.x - lineLen, center.y),
            to: new paper.Point(center.x + lineLen, center.y),
            strokeColor: "#fefefe",
            strokeWidth: 1.5
        });
        const l2 = new paper.Path.Line({
            from: new paper.Point(center.x, center.y - lineLen),
            to: new paper.Point(center.x, lineLen + center.y),
            strokeColor: "#fefefe",
            strokeWidth: 1.5
        });
        const plusSign = new paper.Group([l1, l2]);
        plusSign.applyMatrix = false;
        plusSign.name = "plus sign";
        const border = new paper.Path.Circle({
            center: center,
            radius: 7,
            fillColor: "#3d8aff",
            strokeColor: "#fefefe"
        });
        const quickPos1 = new paper.Group({
            children: [border, plusSign],
            applyMatrix: false,
            scaling: getInverseScaling(zoomScale).divide(
                circle.parent.parent.scaling
            )
        });
        const quickPos2 = quickPos1.clone();
        quickPos2.children[1].name = "plus sign";
        quickPos1.name = "quick add front";
        quickPos1.position = circle.getPointAt(circle.length / 4);
        quickPos2.name = "quick add back";
        quickPos2.position = circle.getPointAt((circle.length / 4) * 3);
        return [quickPos1, quickPos2];
    }

    protected async loadCore(
        point: paper.Point,
        icon: string = this.icon,
        label: string = this.label,
        colorCode = this.colorCode,
        orientation = this.orientation,
        isHitAndRun = this.isHitAndRun
    ): Promise<paper.SymbolItem> {
        this.icon = icon;
        this.label = label;
        this.colorCode = colorCode;
        this.orientation = orientation;
        this.isHitAndRun = isHitAndRun;

        const iconItem =
            this.subtype === "animal"
                ? await this.loadIcon(point, icon)
                : loadSVGFromPage(icon, colorCode, orientation, isHitAndRun);

        iconItem.name = "object core";
        iconItem.scale(getIconScale(icon));
        const coreDefinition = new paper.SymbolDefinition(iconItem);
        const core = coreDefinition.place();
        core.data.inAnchor = NUM_OF_ANCHORS / 2;
        core.data.outAnchor = 0;

        this.finishCore(core, point, icon, 1);

        return core;
    }

    clone(p: T): T {
        this.childClassConstructor = p.constructor as new (
            obj: paper.Item
        ) => T;
        this.label = p.labelText;
        this.colorCode = p.color;
        this.icon = p.icon;
        this.orientation = p.orientation;

        const newCore = new paper.SymbolItem(p.symbolDefinition);
        newCore.name = "object core";
        const clone = this.buildAround(newCore);
        clone.rotation = p.rotation;
        clone.innerGroup.scaling = p.innerGroup.scaling;
        clone.normalizeSelectionUISize();
        return clone;
    }

    /** Builds with the current childClassConstructor. Args are saved for subsequent builds. */
    async build(...args: Parameters<typeof this.loadCore>): Promise<T>;
    /** Builds the Participant subtype passed into first parameter. Args are saved for subsequent builds. */
    async build<T>(
        childClassConstructor: new (entireObject: paper.Item) => T,
        ...args: Parameters<typeof this.loadCore>
    ): Promise<T>;
    async build<T>(
        childClassConstructorOrArgs: any,
        ...args: any[]
    ): Promise<T> {
        let childConstr: new (entireObject: paper.Item) => T;
        let params: Parameters<typeof this.loadCore>;
        if (typeof childClassConstructorOrArgs !== "function") {
            if (!this.childClassConstructor) {
                throw new Error(
                    "Must pass a Participant subtype to the builder's constructor, or this method"
                );
            }
            childConstr = this.childClassConstructor as new (
                entireObject: paper.Item
            ) => T;
            params = [childClassConstructorOrArgs, ...args] as Parameters<
                typeof this.loadCore
            >;
        } else {
            childConstr = childClassConstructorOrArgs;
            params = args as Parameters<typeof this.loadCore>;
        }

        const core = await this.loadCore(...params);
        return this.buildAround(core, childConstr);
    }

    private buildAround(
        core: paper.Item,
        childConstr = this.childClassConstructor!
    ) {
        const entireObject = this.commonBuildSteps(core);

        const label = new paper.PointText({
            content: this.label,
            fillColor: labelColorForCode[this.colorCode],
            strokeColor:
                labelColorForCode[this.colorCode] == "white"
                    ? "black"
                    : "white",
            fontWeight: "bold",
            strokeWidth: 0.33,
            fontSize: 20
        });

        label.applyMatrix = false;
        label.name = "label";
        // insert the label below the ellipsis button
        entireObject.insertChild(entireObject.children.length - 1, label);

        const instance = new childConstr(entireObject);

        if (instance instanceof NonMotorist) {
            instance.subtype = this.subtype;
            instance.originalLayer = DiagramLayer.nonMotorist;
            instance.entireObject.addTo(getLayer(DiagramLayer.nonMotorist));
        }

        instance.color = this.colorCode;
        instance.icon = this.icon;
        instance.orientation = this.orientation;
        instance.isHitAndRun = this.isHitAndRun;
        instance.repositionLabel();

        return instance;
    }
}

export class SignAndObjectBuilder extends CircleSelectedObjectBuilder {
    async build(
        ...args: Parameters<typeof this.loadIcon>
    ): Promise<CircleSelectedObject> {
        const core = await this.loadIcon(...args);
        const iconFile = this.icon.match(/[^/]+(?=$|\?)/);
        this.finishCore(core, args[0], iconFile ? iconFile[0] : args[1]);
        const entireObject = this.commonBuildSteps(core);
        return new CircleSelectedObject(entireObject);
    }
}

export class CrashPointObjectBuilder extends CircleSelectedObjectBuilder {
    iconFolder = "Signage";

    constructor() {
        super(false, false, false, false, true);
    }

    async build(point: paper.Point): Promise<CrashPointObject> {
        const core = await this.loadIcon(point, Icon.Sign_Collision);
        this.finishCore(core, point, Icon.Sign_Collision, 0.7);
        const entireObject = this.commonBuildSteps(core);
        return new CrashPointObject(entireObject);
    }
}
