import { Annotation } from './Annotation';
import { GeometricShape, GeometricShapeNode } from './GeometricShape';
import * as paper from 'paper';
import { GeometricShapeBuilder } from './AnnotationBuilders';
import { DiagramLayer } from 'src/app/shared/layer';
import { wrapIndex } from 'src/app/shared/helpers';

export class EllipseToolBuilder extends GeometricShapeBuilder {
    protected initialLayer: DiagramLayer =
        DiagramLayer.backgroundAnnotations;

    protected createResizeNodes(): paper.Path.Circle[] {
        return this.makeNodesAlongShape(EllipseNode, false);
    }

    protected startShape(point: paper.Point): void {
        const ellipse = new paper.Path.Ellipse({
            center: point,
            radius: 1,
            name: 'shape',
            strokeColor: Annotation.defaultStrokeColor,
            strokeWidth: Annotation.defaultStrokeWidth,
            fillColor: Annotation.defaultFillColor,
            dashArray: Annotation.defaultDashArray,
            strokeCap: 'round',
            data: {
                thing: 'annotation ellipse',
                category: 'annotation',
            },
        });
        const unfinishedAnno = this.initAnno(ellipse);
        unfinishedAnno.data.startPoint = point;
    }

    protected toolMove = (e: paper.MouseEvent) => {
        if (!this.unfinishedAnno) return;

        const rect = new paper.Rectangle(
            this.unfinishedAnno.data.startPoint,
            e.point
        );

        // must prevent width or height of bounds being set to 0 or the matrix gets messed up
        if (rect.width == 0) {
            rect.width = 1;
        }
        if (rect.height == 0) {
            rect.height = 1;
        }

        this.unfinishedAnno.bounds = rect;
    };

    public build(): GeometricShape {
        const ellipse = new GeometricShape(this.unfinishedAnno!);
        ellipse.repositionRotationNode();
        return ellipse;
    }
}

/* -------- Ellipse Node Class --------- */
export class EllipseNode extends GeometricShapeNode {
    protected applyClassName() {
        this.className = "EllipseNode";
    }

    get parentElement(): GeometricShape {
        return super.parentElement as GeometricShape;
    }

    onMouseDrag = (e: paper.MouseEvent) => {
        const node = this.entireObject;
        const shape = this.parentElement.shape;

        const localEPoint = shape.globalToLocal(e.point);
        const i = node.data.segment;
        const pre_i = wrapIndex(i - 1, shape.segments.length);
        const post_i = wrapIndex(i + 1, shape.segments.length);
        const opposite_i = (i + shape.segments.length / 2) % shape.segments.length;
        const axis = i % 2 == 0 ? 'x' : 'y';

        shape.segments[i].point[axis] = localEPoint[axis];
        const midpoint = shape.segments[i].point
            .add(shape.segments[opposite_i].point)
            .divide(2);
        shape.segments[pre_i].point[axis] = midpoint[axis];
        shape.segments[post_i].point[axis] = midpoint[axis];
        shape.smooth({ type: 'continuous' }); // makes the curves between the segments elliptical

        this.parentElement.repositionNodes();
        this.parentElement.repositionRotationNode();
        this.parentElement.positionEllipsis();
    };
}
