// Classes
import { GeometricShape, GeometricShapeNode } from "./GeometricShape";
import * as paper from "paper";
import { GeometricShapeBuilder } from "./AnnotationBuilders";
import { DiagramLayer } from "src/app/shared/layer";

export class RectangleToolBuilder extends GeometricShapeBuilder {
    protected initialLayer: DiagramLayer = DiagramLayer.backgroundAnnotations;

    protected startShape(point: paper.Point): void {
        const rectangle = new paper.Path.Rectangle({
            from: point,
            to: point.add(1),
            name: "shape",
            strokeCap: "round"
        });
        const unfinishedAnno = this.initAnno(rectangle);
        unfinishedAnno.data.startPoint = point;
    }

    protected toolMove = (e: paper.MouseEvent) => {
        if (!this.unfinishedAnno) return;

        const rect = new paper.Rectangle(
            this.unfinishedAnno.data.startPoint,
            e.point
        );

        // must prevent width or height of bounds being set to 0 or the matrix gets messed up
        if (rect.width == 0) {
            rect.width = 1;
        }
        if (rect.height == 0) {
            rect.height = 1;
        }

        this.unfinishedAnno.bounds = rect;
    };

    protected createResizeNodes(): paper.Path.Circle[] {
        return this.makeNodesAlongShape(RectangleNode);
    }

    build(): GeometricShape {
        const rectangle = new GeometricShape(this.unfinishedAnno!);
        rectangle.repositionRotationNode();
        return rectangle;
    }
}

export class RectangleNode extends GeometricShapeNode {
    protected applyClassName() {
        this.className = "RectangleNode";
    }

    get parentElement(): GeometricShape {
        return super.parentElement as GeometricShape;
    }

    onMouseDrag(e: paper.MouseEvent) {
        const node = e.currentTarget;
        const shape = this.parentElement.shape;

        const localEPoint = shape.globalToLocal(e.point);
        const i = node.data.segment;
        //console.log(node.data);
        const pre_i = i == 0 ? shape.segments.length - 1 : i - 1;
        const post_i = i == shape.segments.length - 1 ? 0 : i + 1;

        if (node.data.position == "corner") {
            shape.segments[i].point = localEPoint;

            if (i == 0 || i == 2) {
                shape.segments[pre_i].point.y = localEPoint.y;
                shape.segments[post_i].point.x = localEPoint.x;
            } else {
                shape.segments[pre_i].point.x = localEPoint.x;
                shape.segments[post_i].point.y = localEPoint.y;
            }
        } else {
            if (i == 0 || i == 2) {
                shape.segments[i].point.x = localEPoint.x;
                shape.segments[post_i].point.x = localEPoint.x;
            } else {
                shape.segments[i].point.y = localEPoint.y;
                shape.segments[post_i].point.y = localEPoint.y;
            }
        }

        this.parentElement.repositionNodes(true);
        this.parentElement.repositionRotationNode();
        this.parentElement.positionEllipsis();
    }
}
