import { Cursor } from '../shared/cursor';
import { DiagramService } from '../shared/services/diagram.service';
import { DiagramElement } from './DiagramElement';
import * as paper from "paper";

export abstract class ControlNode extends DiagramElement {
    get nodeGroup(): paper.Group | undefined {
        if (this.entireObject.parent.name === 'nodes')
            return this.entireObject.parent as paper.Group;
        return undefined;
    }

    /**
     * @returns DiagramObject
     */
    get parentElement(): DiagramElement {
        let objectGroup = this.entireObject.parent; // the node's parent;
        while (!objectGroup.data.isDiagramObject) {
            objectGroup = objectGroup.parent;
        }
        return DiagramService.getById(objectGroup.data.referenceId);
    }

    set visible(state: boolean) {
        this.entireObject.visible = state;
    }

    constructor(entireObject: paper.Item);
    constructor(point: paper.Point, additionalAttributes?: any);
    constructor(pointOrEntireObject: any, additionalAttributes?: any) {
        if (pointOrEntireObject instanceof paper.Point) {
            pointOrEntireObject = drawNode(
                pointOrEntireObject,
                additionalAttributes
            );
        }

        super(pointOrEntireObject);
    }

    /** Adds this (its underlying Paper.js drawing [entireObject]) to the given paper.Group.
     * @param index If provided, inserts at index rather than pushing
     */
    addTo(group: paper.Group | paper.Item, index?: number) {
        if (index) {
            group.insertChild(index, this.entireObject);
        } else {
            group.addChild(this.entireObject);
        }
    }
}

export function drawNode(point: paper.Point, additionalAttributes?: any) {
    const node = new paper.Path.Circle({
        center: point,
        radius: 7,
        fillColor: new paper.Color('#3D8AFF'),
        strokeWidth: 2,
        strokeColor: new paper.Color('#FEFEFE'),
        name: 'node circle',
        data: {
            cursor: Cursor.NWSE_Resize,
        },
    });

    if (additionalAttributes) {
        node.set(additionalAttributes);
    }

    return node;
}
