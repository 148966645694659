import * as paper from "paper";
import { getCrashLatLngAsPaperPoint } from "./diagram-map";
import { CrashPointObjectBuilder } from "../classes/ObjectBuilders";

export enum DiagramLayer {
    backgroundAnnotations = "backgroundAnnotations",
    upperBackgroundAnnotations = "upperBackgroundAnnotations",
    main = "main",
    foregroundAnnotations = "foregroundAnnotations",
    crashPoint = "crashPoint",
    nonMotorist = "nonMotorist",
    groupSelection = "groupSelection",
    cropping = "cropping"
}

export enum ProjectLayer {
    "main",
    "textboxes"
}

export function createMapTetheredLayer(
    name: DiagramLayer,
    center?: paper.Point,
    fromActiveLayer = false
): paper.Layer {
    if (!center) center = getCrashLatLngAsPaperPoint();
    const layer =
        fromActiveLayer && paper.projects[0].layers.length
            ? paper.projects[0].activeLayer
            : new paper.Layer();
    layer.pivot = center;
    layer.name = DiagramLayer[name];
    return layer;
}

export function activateProjectLayer(project: ProjectLayer) {
    paper.project.view.element.style.pointerEvents = "none";
    paper.projects[project].activate();
    paper.projects[project].view.element.style.removeProperty("pointer-events");
}

export function getLayer(layerName: DiagramLayer): paper.Layer {
    let layer: paper.Layer = paper.projects[0].layers[layerName as any];

    if (!layer) {
        return paper.projects[0].activeLayer;
    }

    return layer;
}

export function lockLayers(except?: DiagramLayer | DiagramLayer[]) {
    if (except && !Array.isArray(except)) {
        except = [except];
    }

    for (let i = 0; i < paper.projects[0].layers.length; ++i) {
        if (
            except &&
            except.includes(paper.projects[0].layers[i].name as DiagramLayer)
        )
            continue;

        paper.projects[0].layers[i].locked = true;
    }
}

export function unlockLayers() {
    for (const layer of paper.projects[0].layers) {
        layer.locked = false;
    }
}

export async function createCrashIconAndLayers() {
    const center = getCrashLatLngAsPaperPoint();
    const layerList = Object.values(DiagramLayer);
    const bottomLayer = layerList.splice(0, 1)[0];

    createMapTetheredLayer(bottomLayer, center, true);

    for (const layer of layerList) {
        createMapTetheredLayer(layer, center);
    }

    getLayer(DiagramLayer.crashPoint).activate();

    const crashIcon = await new CrashPointObjectBuilder().build(center);

    // activate the main diagramming layer
    getLayer(DiagramLayer.main).activate();
    paper.projects[0].activeLayer.data.hiddenParticipants = {};

    return crashIcon;
}
