// This file mostly contains interfaces that correspond to the backend classes

/** The attributes that get applied to the diagram object and establish what it is */
// export interface ParticipantAttributes {
//     icon: string;
//     iconScale?: number;
//     color?: string;
//     orientation?: string;
//     label?: string;
// }

export enum MannerOfCollision {
    FrontToRear = 120,
    FrontToFront,
    Angle,
    SideswipeSameDir,
    SideswipeOppDir,
    RearToSide,
    RearToRear,
    Other,
    Unknown,
    NotProvided = -1
}

export const unknownDirs = ["UNKNOWN", "U", "O", "OFFROAD", "OFF-ROAD"];

export enum FirstHarmfulEvent {
    Rollover = 1,
    FireOrExplosion,
    Immersion,
    Jackknife,
    CargoLossOrShift,
    FellOrJumpedFromVehicle,
    ThrownOrFallingObject,
    RanIntoWater,
    OtherNonCollision,
    Pedestrian = 20,
    Pedalcycle,
    RailwayVehicle,
    Animal,
    MotorVehicleInTransport,
    ParkedMotorVehicle,
    WorkZoneObject,
    StruckByFallingOrShiftingCargo,
    OtherNonFixedObject,
    ImpactAttenuator = 40,
    BridgeOverheadStructure,
    BridgePierOrSupport,
    BridgeRail,
    Culvert,
    Curb,
    Ditch,
    Embankment,
    GuardrailFace,
    GuardrailEnd,
    CableBarrier,
    ConcreteTrafficBarrier,
    OtherTrafficBarrier,
    StandingTree,
    UtilityPole,
    TrafficSignSupport,
    TrafficSignalSupport,
    OtherPostPoleOrSupport,
    Fence,
    Mailbox,
    OtherFixedObject,
    NotProvided = -1
}

export enum VehicleImpactArea {
    Front = 1,
    FrontRight,
    RightQ1,
    RightQ2,
    RightQ3,
    RightQ4,
    BackRight,
    Back,
    BackLeft,
    LeftQ4,
    LeftQ3,
    LeftQ2,
    LeftQ1,
    FrontLeft,
    Hood,
    TopFrontHalf,
    TopBackHalf,
    Undercarriage,
    Overturn,
    Windshield,
    Trailer,
    NotProvided = -1
}

export enum VehicleBodyType {
    PassengerCar = 30,
    PassengerVan,
    Pickup,
    MotorHome,
    Bus,
    Motorcycle,
    Moped,
    ATV,
    LowSpeedVehicle,
    SUV,
    CargoVan,
    MotorCoach,
    OtherLightTrucks,
    MediumToHeavyTrucks,
    FarmLaborVehicle,
    Other,
    Unknown,
    NotProvided = -1
}

export enum VehicleManeuver {
    StraightAhead = 130,
    TurningLeft,
    Backing,
    TurningRight,
    ChangingLanes,
    Parked,
    UTurn,
    Passing,
    StoppedInTraffic,
    Slowing,
    NegotiatingCurve,
    LeavingTrafficLane,
    EnterTrafficLane,
    Other,
    Unknown,
    NotProvided = -1
}

export enum VehicleSpecialFunction {
    None = 170,
    FarmVehicle,
    Police,
    Taxi,
    Military,
    Ambulance,
    FireTruck,
    FarmLaborTransport,
    SchoolBus,
    CommuterBus,
    IntercityBus,
    CharterBus,
    ShuttleBus,
    FarmLaborBus,
    Unknown,
    NotProvided = -1
}

export enum CMVConfig {
    HazardousMaterials = 70,
    SingleUnitTruck2Axle,
    SingleUnitTruckGTE3Axle,
    TruckPullingTrailer,
    TruckTractorBobtail,
    TruckTractorSemi,
    TruckTractorDouble,
    TractorTriple,
    TruckGT5TonCannotClassify,
    BusOrLargeVan,
    BusSeatingOver15,
    Other,
    Unknown,
    NotProvided = -1
}

export enum CargoBodyType {
    NoCargo = 50,
    Bus,
    VanOrEnclosedBox,
    Hopper,
    PoleTrailer,
    CargoTank,
    Flatbed,
    Dump,
    ConcreteMixer,
    AutoTransport,
    Garbage,
    Log,
    IntermodalContainerChassis,
    VehicleTowingAnother,
    NotApplicable,
    Other,
    Unknown,
    NotProvided = -1
}

export enum NonMotoristDescription {
    Pedestrian = 230,
    OtherPedestrian,
    Bicyclist,
    OtherCyclist,
    OccupantOfVehicleNotInTransport,
    OccupantOfNonMotorVehicleTransportationDevice,
    Unknown,
    NotProvided = -1
}

export interface StringCodeMap {
    [code: string]: string;
}
export const ncicColorCodes = {
    SIL: "Aluminum/Silver",
    AME: "Amethyst",
    BGE: "Beige",
    BLK: "Black",
    BLU: "Blue",
    DBL: "Dark Blue",
    LBL: "Light Blue",
    BRZ: "Bronze",
    BRO: "Brown",
    MAR: "Burgundy/Maroon",
    CAM: "Camouflage",
    COM: "Chrome/Steel",
    CPR: "Copper",
    CRM: "Cream/Ivory",
    GLD: "Gold",
    GRY: "Gray",
    GRN: "Green",
    DGR: "Dark Green",
    LGR: "Light Green",
    LAV: "Lavender",
    MVE: "Mauve",
    "MUL/COL": "Multicolored",
    ONG: "Orange",
    PNK: "Pink",
    PLE: "Purple",
    RED: "Red",
    TAN: "Tan",
    TPE: "Taupe",
    TEA: "Teal",
    TRQ: "Turquoise",
    WHI: "White",
    YEL: "Yellow",
    OTH: "Other"
} as StringCodeMap;

export interface VehicleData {
    number: number;
    bodyType?: VehicleBodyType;
    specialFunction?: VehicleSpecialFunction;
    cmvConfig?: CMVConfig;
    cargoType?: CargoBodyType;
    colorCode?: string;
    travelDirection?: string;
    impactArea?: VehicleImpactArea;
    maneuver?: VehicleManeuver;
    isHitAndRun?: string;
}

export interface NonMotoristData {
    number: number;
    desc?: NonMotoristDescription;
}

export interface CrashFormData {
    mannerOfCollision: MannerOfCollision;
    firstHarmfulEvent: FirstHarmfulEvent;
    vehicles?: Array<VehicleData>;
    nonMotorists?: Array<NonMotoristData>;
}

export interface LatLngResult {
    latitude: number;
    longitude: number;
    errorMessage: string;
}

export interface ParticipantData {
    number: string;
    icon: string;
    dir?: string;
    initialImpact?: VehicleImpactArea;
    maneuver?: string;
    color?: string;
    colorCode?: string;
    isDiagrammed: boolean;
    isHitAndRun?: boolean;
}

export const surrogateToHSMVLookupTable = {
    mannerOfCollision: {
        "120": "1",
        "121": "2",
        "122": "3",
        "123": "4",
        "124": "5",
        "125": "6",
        "126": "7",
        "127": "77",
        "128": "88"
    },
    firstHarmfulEvent: {
        "1": "1",
        "2": "2",
        "3": "3",
        "4": "4",
        "5": "5",
        "6": "6",
        "7": "7",
        "8": "8",
        "9": "9",
        "20": "10",
        "21": "11",
        "22": "12",
        "23": "13",
        "24": "14",
        "25": "15",
        "26": "16",
        "27": "17",
        "28": "18",
        "40": "19",
        "41": "20",
        "42": "21",
        "43": "22",
        "44": "23",
        "45": "24",
        "46": "25",
        "47": "26",
        "48": "27",
        "49": "28",
        "50": "29",
        "51": "30",
        "52": "31",
        "53": "32",
        "54": "33",
        "55": "34",
        "56": "35",
        "57": "36",
        "58": "37",
        "59": "38",
        "60": "39"
    },
    bodyType: {
        "30": "1",
        "31": "2",
        "32": "3",
        "33": "7",
        "34": "8",
        "35": "11",
        "36": "12",
        "37": "13",
        "38": "15",
        "39": "16",
        "40": "17",
        "41": "18",
        "42": "19",
        "43": "20",
        "44": "21",
        "45": "77",
        "46": "88"
    },
    specialFunction: {
        "170": "1",
        "171": "2",
        "172": "3",
        "173": "7",
        "174": "8",
        "175": "9",
        "176": "10",
        "177": "11",
        "178": "12",
        "179": "13",
        "180": "14",
        "181": "15",
        "182": "16",
        "183": "17",
        "184": "88"
    },
    cmvConfig: {
        "70": "1",
        "71": "2",
        "72": "3",
        "73": "4",
        "74": "5",
        "75": "6",
        "76": "7",
        "77": "8",
        "78": "9",
        "79": "10",
        "80": "11",
        "81": "77",
        "82": "88"
    },
    cargoType: {
        "50": "1",
        "51": "2",
        "52": "3",
        "53": "4",
        "54": "5",
        "55": "6",
        "56": "7",
        "57": "8",
        "58": "9",
        "59": "10",
        "60": "11",
        "61": "12",
        "62": "13",
        "63": "14",
        "64": "15",
        "65": "77",
        "66": "88"
    },
    maneuver: {
        "130": "1",
        "131": "3",
        "132": "4",
        "133": "5",
        "134": "6",
        "135": "8",
        "136": "10",
        "137": "11",
        "138": "13",
        "139": "14",
        "140": "15",
        "141": "16",
        "142": "17",
        "143": "77",
        "144": "88"
    },
    desc: {
        "230": "1",
        "231": "2",
        "232": "3",
        "233": "4",
        "234": "5",
        "235": "6",
        "236": "7"
    }
};

export const DIAGRAM_STYLES = ["Color", "B&W Map", "All B&W"];

export interface LatLng {
    latitude: number,
    longitude: number
}

export interface CenterlineReq {
    latitude: number,
    longitude: number,
    linkId?: number
}

export interface CenterlinePairReq {
    latLng1: CenterlineReq,
    latLng2: CenterlineReq
}

export interface CenterlinePair {
    centerline1: LatLng,
    centerline2: LatLng
}
