import { isAtMaxZoom, isAtMinZoom, leafletMap } from "src/app/shared/diagram-map";
import { Component } from "@angular/core";

@Component({
    selector: "zoom-control",
    templateUrl: "./zoom-control.component.html",
    styleUrls: ["../map-controls.component.scss"]
})
export class ZoomControlComponent {
    onZoomIn() {
        leafletMap.zoomIn();
    }

    onZoomOut() {
        leafletMap.zoomOut();
    }

    get isAtMaxZoom(): boolean {
        return isAtMaxZoom();
    }
    get isAtMinZoom(): boolean {
        return isAtMinZoom();
    }
}
