import { UIService } from "src/app/shared/services/ui.service";
import {
    FirstHarmfulEvent,
    surrogateToHSMVLookupTable,
    MannerOfCollision,
    VehicleData,
    NonMotoristData,
    VehicleBodyType,
    VehicleSpecialFunction,
    CMVConfig,
    CargoBodyType,
    VehicleImpactArea,
    VehicleManeuver,
    NonMotoristDescription
} from "./../cdt-diagram/cdt-diagram-models";
import { Component, OnInit } from "@angular/core";
import { DiagramService } from "../shared/services/diagram.service";
import { addSpacesBetweenWords } from "../shared/helpers";
import { GeolocationService } from "../shared/services/geolocation.service";
import { CRUDService } from "../shared/services/crud.service";
import { CrashReportService } from "../shared/services/crash-report.service";

enum AOII {
    "Front Center Bumper" = 1,
    "Front Right Bumper",
    "Right Front Fender",
    "Right Front Door",
    "Right Rear Door",
    "Right Rear Fender",
    "Rear Right Bumper",
    "Rear Center Bumper",
    "Rear Left Bumper",
    "Left Rear Fender",
    "Left Rear Door",
    "Left Front Door",
    "Left Front Fender",
    "Front Left Bumper",
    Hood,
    Roof,
    Trunk,
    Undercarriage,
    Overturn,
    Windshield,
    Trailer
}

@Component({
    selector: "crash-report-info",
    templateUrl: "./crash-report-info.component.html",
    styleUrls: ["./crash-report-info.component.scss"]
})
export class CrashReportInfoComponent implements OnInit {
    isReady = false;

    constructor(
        private diagram: DiagramService,
        private geo: GeolocationService,
        private report: CrashReportService
    ) {
        this.diagram.isReady.when(true, () => {
            this.isReady = true;
        });
    }

    get loc() {
        return this.geo.geoServiceData.Location;
    }

    get street1() {
        return this.loc.StreetName;
    }
    get street2() {
        return this.loc.NearestIntersectingStreetName;
    }
    get locationType() {
        if (this.loc.RelationshipToNetwork == "SEGMENT") {
            return "ROAD SEGMENT";
        }

        return this.loc?.RelationshipToNetwork;
    }
    get preposition() {
        switch (this.loc.RelationshipToNetwork) {
            case "SEGMENT":
            case "RAMP":
                return "On";
            case "INTERSECTION":
            case "OFFROADWAY":
                return "At";
        }

        return "";
    }
    get relationToStreet2() {
        switch (this.loc.RelationshipToNetwork) {
            case "SEGMENT":
            case "RAMP":
                return `${this.loc.NearestIntersectionOffsetFeet} feet ${this.loc.NearestIntersectionOffsetDir} of`;
            case "INTERSECTION":
                return "&";
        }

        return "";
    }
    get placeAndPoint() {
        return `${this.loc.CityName}, ${this.loc.CountyName} County, ${this.loc.StateName} `; // +
        // `(${this.loc.Latitude.toFixed(6)}, ` +
        // `${this.loc.Longitude.toFixed(6)})`
    }

    get lat() {
        return this.loc.Latitude.toFixed(6);
    }

    get lng() {
        return this.loc.Longitude.toFixed(6);
    }

    get globalFields() {
        return [
            [
                "First Harmful Event",
                this.report.crashReportData.firstHarmfulEvent == -1
                    ? "Not provided"
                    : `${
                          this.report.crashReportData.firstHarmfulEvent
                      } ${addSpacesBetweenWords(
                          FirstHarmfulEvent[
                              this.report.crashReportData.firstHarmfulEvent
                          ]
                      )}`
            ],
            [
                "Manner of Collision",
                this.formatCrashData(
                    MannerOfCollision,
                    this.report.crashReportData,
                    "mannerOfCollision"
                )
            ]
        ];
    }

    get vehicles() {
        return this.report.crashReportData.vehicles;
    }

    get nonMotorists() {
        return this.report.crashReportData.nonMotorists;
    }

    propToEnumMap = {
        bodyType: VehicleBodyType,
        specialFunction: VehicleSpecialFunction,
        cmvConfig: CMVConfig,
        cargoType: CargoBodyType,
        impactArea: AOII,
        maneuver: VehicleManeuver,
        desc: NonMotoristDescription
    };

    formatCrashData(type: any, obj: any, prop: string): string {
        if (obj[prop] == -1) {
            return "Not provided";
        }

        if (prop in surrogateToHSMVLookupTable) {
            return `${
                surrogateToHSMVLookupTable[prop][obj[prop]]
            }: ${addSpacesBetweenWords(type[obj[prop]])}`;
        }

        if (type) {
            return `${obj[prop]}: ${addSpacesBetweenWords(type[obj[prop]])}`;
        }

        return obj[prop];
    }

    getParticipantData(participant: VehicleData | NonMotoristData) {
        return Object.entries(participant)
            .slice(1)
            .map((v) => {
                return [
                    addSpacesBetweenWords(v[0]),
                    this.formatCrashData(
                        this.propToEnumMap[v[0]],
                        participant,
                        v[0]
                    )
                ];
            });
    }

    ngOnInit(): void {}
}
