<div class="bottom-bar dont-save" [class.is-hidden]="isHidden">
    <!-- <ng-template [ngIf]="isMobileOrTablet">
        <span
            class="justify-content-start ml-2 small-font cursor-pointer"
            [title]="disclaimerTxt"
        >
            Disclaimer
        </span>
    </ng-template> -->
    <!-- <ng-template [ngIf]="!isMobileOrTablet">
        <div *ngIf="!isMobileOrTablet" class="spacer"></div>
        <span class="disclaimer-txt justify-content-start ml-2"
            >Disclaimer: {{ disclaimerTxt }}
        </span>
    </ng-template> -->

    <scale-bar class="w25" #scaleBar id="scale-bar"></scale-bar>
</div>
