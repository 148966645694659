import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "icon-grid",
    templateUrl: "./icon-grid.component.html",
    styleUrls: ["./icon-grid.component.scss"]
})
export class IconGridComponent implements OnInit {
    @Input() icons: Array<string>;
    @Input() directory: string;
    @Output() selectEvent = new EventEmitter<string>();

    constructor() {}

    choose(icon: string) {
        this.selectEvent.emit(icon);
    }

    ngOnInit(): void {}
}
