<div class="top-panel">
    <div class="panel-item">
        <img (click)="openAbout()" *ngIf="hasLoaded" class="s4-logo" src="assets/SVG/Misc Icons/s4DiagrammingBanner.svg" />
    </div>
    <control-bar
        *ngIf="hasLoaded"
        class="flex-grow"
    ></control-bar>
    <div class="panel-item may-vanish"></div>
</div>
<popup-menu #about>
    <div class="p-2">
        <div style="font-weight: bold">Signal Four Diagram v{{version}}</div>
        <div>Copyright © 2022-2024
        </div>
    </div>
</popup-menu>
