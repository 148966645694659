import { UIService } from "src/app/shared/services/ui.service";
import { Component, OnInit } from "@angular/core";
import {
    animalsIcons,
    Icon,
    idToIconNameMap,
    pedestrianIcons
} from "../shared/icon";
import { ANNOTATION_TOOL_LIST } from "../classes/AnnotationTools/Annotation";
import { AnnotationBuilder } from "../classes/AnnotationTools/AnnotationBuilders";
import { EllipseToolBuilder } from "../classes/AnnotationTools/EllipseTool";
import { FreeHandToolBuilder } from "../classes/AnnotationTools/FreeHandTool";
import { LineToolBuilder } from "../classes/AnnotationTools/LineTool";
import { ParkingStallToolBuilder } from "../classes/AnnotationTools/ParkingStallTool";
import { PolygonToolBuilder } from "../classes/AnnotationTools/PolygonTool";
import { PolyLineToolBuilder } from "../classes/AnnotationTools/PolyLineTool";
import { RectangleToolBuilder } from "../classes/AnnotationTools/RectangleTool";
import { TextboxToolBuilder } from "../classes/AnnotationTools/TextboxTool";
import { DiagramElementAction } from "../shared/Action";
import { CurveToolBuilder } from "../classes/AnnotationTools/CurveTool";

@Component({
    selector: "side-panel",
    templateUrl: "./side-panel.component.html",
    styleUrls: ["./side-panel.component.scss"]
})
export class SidePanelComponent implements OnInit {
    private annoBuilder: AnnotationBuilder | undefined;

    constructor(private ui: UIService) {}

    ngOnInit() {}

    iconList = [
        "1",
        "14",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "17"
    ];

    trailers = ["16"];

    get signageList() {
        return Object.values(Icon).filter((x) =>
            (x as string).match("Sign|Arrow")
        );
    }

    objectsList = [
        "Object_bush_sideview",
        "Object_bush_topdown",
        "Object_fire hydrant",
        "Object_light pole",
        "Object_orange barricade",
        "Object_orange barricade2",
        "Object_power pole",
        "Object_tree_sideview",
        "Object_tree_topdown",
        "Object_fence"
    ];

    selectedAnimalIcon = "Alligator";
    animalIcons = animalsIcons.map((animal) => {
        return { text: animal };
    });

    selectedPedestrianIcon = "15";
    pedestrianIcons = pedestrianIcons.map((ped) => {
        return { text: ped };
    });

    get annotationToolList() {
        return ANNOTATION_TOOL_LIST;
    }

    getIconName(iconString: string) {
        return idToIconNameMap[iconString];
    }

    showHelp(msg: string, dur?: number) {
        this.ui.helpBar.show(msg, dur);
    }

    /** Initiates drag and drop of icon into the diagram */
    startDrag(e: DragEvent, icon: string) {
        if (DiagramElementAction.currentAction?.name === "add object") {
            DiagramElementAction.currentAction?.cancel();
        } else if (DiagramElementAction.isRecording) {
            return false;
        }

        new DiagramElementAction({ name: "add object" }).startRecording();

        this.ui.dropSucceeded = false;

        if (!(icon.startsWith("Sign_") || icon.startsWith("Arrow_"))) {
            icon = icon.replace("Left", "Top");
        }

        e.dataTransfer?.setData("application/icon", icon);

        if (icon == "16") {
            e.dataTransfer?.setData("icon/trailer", icon);
        }

        // hide without cancelling drag phantom image (which opacity = "0" seems to do across browsers)
        const img = (e.target as HTMLElement).parentElement as HTMLElement;
        img.style.opacity = "0";
        return true;
    }

    stopDrag(e: DragEvent) {
        const img = (e.target as HTMLElement).parentElement as HTMLElement;
        img.style.opacity = "";
        if (
            !this.ui.dropSucceeded &&
            DiagramElementAction.currentAction?.name === "add object"
        ) {
            DiagramElementAction.currentAction.cancel();
        }
    }

    darkBgIfNeeded(icon: string) {
        let classes = "icon-image sign-icon";
        if (icon.startsWith("Arrow_")) {
            classes += " arrow-sign-icon";
        }

        return classes;
    }

    annotationButtonClick(toolTitle: string, event: MouseEvent) {
        if (this.annoBuilder && this.annoBuilder.isToggled) {
            this.annoBuilder.toggle();
            return;
        }

        const button = event.target as HTMLButtonElement;

        switch (toolTitle) {
            case "Ellipse":
                this.annoBuilder = new EllipseToolBuilder(button);
                break;
            case "Freehand":
                this.annoBuilder = new FreeHandToolBuilder(button);
                break;
            case "Line":
                this.annoBuilder = new LineToolBuilder(button, "basic");
                break;
            case "Parking-Spaces":
                this.annoBuilder = new ParkingStallToolBuilder(button);
                break;
            case "Polygon":
                this.annoBuilder = new PolygonToolBuilder(button);
                break;
            case "Polyline":
                this.annoBuilder = new PolyLineToolBuilder(button);
                break;
            case "Rectangle":
                this.annoBuilder = new RectangleToolBuilder(button);
                break;
            case "Textbox":
                this.annoBuilder = new TextboxToolBuilder(button);
                break;
            case "Measure":
                this.annoBuilder = new LineToolBuilder(button, "measure");
                break;
            case "Arrow":
                this.annoBuilder = new LineToolBuilder(button, "arrow");
                break;
            case "Curve":
                this.annoBuilder = new CurveToolBuilder(button, "curve");
                break;
        }

        if (this.annoBuilder) this.annoBuilder.toggle();
        // if (selectedAnnotationTool) {
        //     if (selectedAnnotationTool == toolTitle) {
        //         // this block allows the user to push the same button on and off
        //         toggleAnnotation();
        //         return;
        //     }

        //     // otherwise a different annotation tool was active. Turn it off prior to toggling the clicked on button.
        //     toggleAnnotation();
        // }

        // deselect(); // clear any selections to improve visibility of placement
        // toggleAnnotation(toolTitle);
    }

    selectAnimal(animalItem: any, iconString: string = "") {
        if (iconString) {
            this.selectedAnimalIcon = iconString;
        } else if (animalItem) {
            this.selectedAnimalIcon = animalItem.text;
        }
    }

    selectPedestrian(pedItem: any) {
        if (pedItem.text) this.selectedPedestrianIcon = pedItem.text;
    }
}
