import { DiagramLayer } from "src/app/shared/layer";
import {
    degreesToRadians,
    getDiagramZoomScale,
    getLineAngle,
    getMapDistanceInFeet
} from "src/app/shared/helpers";
import { Annotation } from "./Annotation";
import { AnnotationBuilder } from "./AnnotationBuilders";
import { HandlerSet } from "./AnnotationBuilders";
import * as paper from "paper";
import { MenuItem } from "@progress/kendo-angular-menu";
import { AnnotationNode } from "./AnnotationNode";
import {
    colorMenu,
    lineStyleOptions,
    lineWeightOptions,
    removeOption
} from "../ellipsis-menu-options";
import { ParkingStallNode } from "./ParkingStallTool";
import { DiagramElementAction } from "src/app/shared/Action";

/**
 * Define the subtype of the line
 */
export type LineType = "basic" | "arrow" | "measure" | "straight" | "curve";

export class LineToolBuilder extends AnnotationBuilder {
    protected initialLayer: DiagramLayer =
        DiagramLayer.foregroundAnnotations;

    public constructor(btn: HTMLButtonElement, lineType: LineType);
    public constructor(
        btn: HTMLButtonElement,
        lineType: LineType,
        unfinishedAnno: paper.Group,
        activeHandlers: HandlerSet,
        point: paper.Point
    );
    public constructor(
        protected btn: HTMLButtonElement,
        private lineType: LineType,
        protected unfinishedAnno?: paper.Group,
        activeHandlers?: HandlerSet,
        point?: paper.Point
    ) {
        super(btn);

        if (unfinishedAnno && activeHandlers && point) {
            this.isToggled = true;
            this.activeHandlers = activeHandlers;
            this.subtype = this.lineType;
            this.setUpArrows();
            this.continueShape(point);
        }
    }

    set subtype(value: LineType) {
        this.shape.data.subtype = value;
    }

    set decimalPlaces(value: number) {
        this.shape.data.decimalPlaces = value;
    }

    get decimalPlaces(): number {
        return this.shape.data.decimalPlaces;
    }

    set labelSide(value: number) {
        this.shape.data.labelSide = value;
    }

    set labelOffset(value: number) {
        this.shape.data.labelOffset = value;
    }

    get labelOffset(): number {
        return this.shape.data.labelOffset;
    }

    get measurementLabel() {
        return this.shape.parent.children["measurement"] as paper.PointText;
    }

    get labelOverride(): string {
        return this.shape.data.labelOverride;
    }

    set label(value: string) {
        this.shape.data.label = value;
    }

    protected preventInvisibleShape(shape: paper.Path): boolean {
        if (!Annotation.defaultStrokeColor) {
            shape.strokeColor = new paper.Color("black");
            return true;
        }

        return false;
    }

    protected startShape(point: paper.Point): void {
        const line = new paper.Path.Line({
            from: point,
            to: point,
            name: "shape",
            strokeColor: new paper.Color("black"),
            strokeWidth: Annotation.defaultStrokeWidth,
            dashArray: Annotation.defaultDashArray,
            strokeCap: "round",
            data: {
                subtype: this.lineType
            }
        });

        this.unfinishedAnno = this.initAnno(line);

        switch (this.lineType) {
            case "arrow":
                Annotation.defaultArrowVisibility = {
                    Start: false,
                    End: true
                };
                break;
            case "measure":
                Annotation.defaultArrowVisibility = {
                    Start: true,
                    End: true
                };
                this.decimalPlaces = Annotation.defaultDecimalPlaces;
                this.labelSide = 1;
                this.labelOffset = 0;
                this.setUpMeasurementLabel();
                break;
            default:
                Annotation.defaultArrowVisibility = {
                    Start: false,
                    End: false
                };
        }

        this.setUpArrows();
    }

    protected toolMove = (e: paper.MouseEvent) => {
        if (this.unfinishedAnno) {
            this.shape.lastSegment.point = e.point;
            // if (this.lineType == 'arrow' || this.lineType == 'measure')
            //     this.updateLineArrows();
            if (this.lineType == "measure") {
                this.updateMeasurementLabel();
            }
            this.updateLineArrows();
        }
    };

    protected continueShape(point: paper.Point): void {
        if (!this.unfinishedAnno) return;
        this.updateLineArrows();
        this.completeShape();
    }

    protected createResizeNodes(): paper.Path.Circle[] {
        const nodes = new Array<paper.Item>();

        for (const seg of this.shape.segments) {
            nodes.push(new LineNode(seg.point).entireObject);
        }

        return nodes as paper.Path.Circle[];
    }

    triangleWave(x: number, a: number, p: number) {
        return (
            ((4 * a) / p) * Math.abs(((((x - p / 4) % p) + p) % p) - p / 2) - a
        );
    }

    /**
     * Create arrows for line
     * @returns Don't execute code if unfinishedAnno is undefined
     */
    setUpArrows() {
        if (!this.unfinishedAnno) return;

        const arrows = new paper.Group({
            children: [
                new paper.Path({
                    segments: [
                        new paper.Segment(this.shape.firstSegment.point),
                        new paper.Segment(this.shape.firstSegment.point),
                        new paper.Segment(this.shape.firstSegment.point)
                    ],
                    visible: Annotation.defaultArrowVisibility.Start,
                    name: "Start"
                }),
                new paper.Path({
                    segments: [
                        new paper.Segment(this.shape.lastSegment.point),
                        new paper.Segment(this.shape.lastSegment.point),
                        new paper.Segment(this.shape.lastSegment.point)
                    ],
                    visible: Annotation.defaultArrowVisibility.End,
                    name: "End"
                })
            ],
            strokeColor: Annotation.defaultStrokeColor
                ? Annotation.defaultStrokeColor
                : new paper.Color("black"),
            strokeWidth: Annotation.defaultStrokeWidth,
            visible:
                Annotation.defaultArrowVisibility.Start ||
                Annotation.defaultArrowVisibility.End
        });

        arrows.name = "arrows";
        this.unfinishedAnno.addChild(arrows);
        arrows.sendToBack();
        this.updateLineArrows();
    }

    /**
     * Update properties of arrows when some properties of line is changed
     */
    updateLineArrows() {
        updateLineArrows(this.shape);
    }

    /**
     * Create measurement label for measure tool
     * @returns Don't execute code if unfinishedAnno is undefined
     */
    setUpMeasurementLabel() {
        if (!this.unfinishedAnno) return;

        this.unfinishedAnno.addChild(
            new paper.PointText({
                point: this.unfinishedAnno.position,
                content: "0'",
                fillColor: Annotation.defaultStrokeColor,
                fontWeight: "bold",
                fontSize: 16,
                name: "measurement"
            })
        );
    }

    /**
     * Update measurement label properties when some properties of line have changed
     */
    updateMeasurementLabel() {
        updateMeasurementLabel(
            this.shape,
            this.labelOverride,
            this.labelOffset,
            this.measurementLabel,
            this.decimalPlaces
        );
    }

    build(): Annotation {
        const lineTool = new LineTool(this.unfinishedAnno!);
        if (this.lineType == "measure") lineTool.updateMeasurementLabel();
        return lineTool;
    }
}

export class LineTool extends Annotation {
    protected applyClassName() {
        this.className = "LineTool";
    }

    get ellipsisOptions(): MenuItem[] {
        const annotationLineOptions: MenuItem[] = [
            lineWeightOptions,
            lineStyleOptions,
            colorMenu,
            {
                text: "Arrows",
                items: [
                    { data: "arrows", text: "Start Arrow" },
                    { data: "arrows", text: "End Arrow" }
                ]
            },
            {
                text: "Add Node",
                data: "add node"
            },
            removeOption
        ];

        if (
            this.subtype == "curve" ||
            this.subtype == "basic" ||
            this.subtype == "arrow"
        ) {
            return annotationLineOptions;
        } else if (this.subtype == "measure") {
            return (
                [
                    {
                        text: "Override Measurement",
                        items: [
                            {
                                data: "label input",
                                cssClass: "fix-right-margin"
                            }
                        ]
                    }
                ] as MenuItem[]
            ).concat(
                annotationLineOptions.filter(
                    (menuItem) => menuItem.data != "add node"
                )
            );
        } else {
            return annotationLineOptions.filter(
                (menuItem) => menuItem.data != "add node"
            );
        }
    }

    get arrows() {
        return this.shape.parent.children["arrows"];
    }

    get measurementLabel() {
        return this.shape.parent.children["measurement"] as paper.PointText;
    }

    get subtype(): LineType {
        return this.shape.data.subtype;
    }

    set subtype(value: LineType) {
        this.shape.data.subtype = value;
    }

    get decimalPlaces(): number {
        return this.shape.data.decimalPlaces;
    }

    get labelOverride(): string {
        return this.shape.data.labelOverride;
    }

    set labelOverride(value: string) {
        this.shape.data.labelOverride = value;
    }

    get labelOffset(): number {
        return this.shape.data.labelOffset;
    }

    set label(value: string) {
        this.shape.data.label = value;
    }

    positionEllipsis(): void {
        const zoomScale = getDiagramZoomScale();

        this.ellipsisButton.position = new paper.Point(
            this.shape.firstSegment.point.add(
                this.shape
                    .getTangentAt(0)
                    .rotate(180, new paper.Point(0, 0))
                    .multiply(35 / zoomScale)
            )
        );
    }

    changeStrokeColor(color: paper.Color | null): void {
        const changeStrokeColorAction = new DiagramElementAction({
            name: "change stroke color",
            elementRef: this
        });
        changeStrokeColorAction.startRecording();
        Annotation.defaultStrokeColor = color;
        this.entireObject.strokeColor = color;
        changeStrokeColorAction.stopRecording();

    }

    /**
     * Hide or show arrows
     * @param end
     */
    toggleArrow(end: string) {
        const toggleArrowAction = new DiagramElementAction({
            name: `toggle ${end.toLowerCase()} arrow`,
            elementRef: this
        });
        toggleArrowAction.startRecording();

        this.arrows.children[end].visible = LineTool.defaultArrowVisibility[
            end
        ] = !this.arrows.children[end].visible;

        if (
            this.arrows.children["Start"].visible == false &&
            this.arrows.children["End"].visible == false
        ) {
            this.arrows.visible = false;
        } else {
            this.arrows.visible = true;
        }

        this.updateLineArrows();
        toggleArrowAction.stopRecording();
    }

    /**
     * Update arrows' properties when some properties of line have changed
     */
    updateLineArrows() {
        updateLineArrows(this.shape);
    }

    /**
     * Update measurement label properties when some properties of line have changed
     */
    updateMeasurementLabel() {
        updateMeasurementLabel(
            this.shape,
            this.labelOverride,
            this.labelOffset,
            this.measurementLabel,
            this.decimalPlaces
        );
    }

    /**
     * Override the value of the measurement label
     * @param value The new measure value
     */
    overrideMeasurement(value: string) {
        // Store new value, and add ' if missing
        this.labelOverride = this.measurementLabel.content = value;
    }

    addNode() {
        const addNodeAction = new DiagramElementAction({
            name: "add annotation node",
            elementRef: this
        });
        addNodeAction.startRecording();
        addNode(this.shape, this.nodeGroup);
        this.subtype = "curve";
        addNodeAction.stopRecording();

    }

    setUpEventHandlers(): void {
        super.setUpEventHandlers();
    }
}

export class LineNode extends AnnotationNode {
    protected applyClassName() {
        this.className = "LineNode";
    }

    get parentElement(): LineTool {
        return super.parentElement as LineTool;
    }

    onMouseDrag(e: paper.MouseEvent) {
        const shape = this.parentElement.shape;
        const node = e.currentTarget;

        node.position = e.point;
        shape.segments[node.index].point = e.point;

        this.parentElement.updateLineArrows();

        if (
            shape.segments.length > 2 &&
            this.parentElement.subtype == "curve"
        ) {
            shape.smooth({ type: "continuous" });
        } else if (this.parentElement.subtype == "measure") {
            this.parentElement.updateMeasurementLabel();
        }

        this.parentElement.positionEllipsis();
    }
}

/**
 * Helper function to update properties of arrows of the line
 * @param line The line that has changed properties
 */
function updateLineArrows(line: paper.Path) {
    const arrows = line.parent.children["arrows"];
    arrows.strokeWidth = line.strokeWidth;
    arrows.strokeColor = line.strokeColor;

    let arrowAngle = 145;
    let arrowLength = 8 + line.strokeWidth * 1.5;

    if (line.data.subtype == "measurement") {
        // make arrows smaller and flatter, and scale with a logistic function based on line length
        // because who wants large arrows on a super short line?
        arrowLength = (arrowLength * 0.8) / (1 + Math.exp(-0.03 * line.length));
        arrowAngle -= 15;
    }

    // start arrow ==============================================
    let arrowheadVector = line
        .getTangentAt(0)
        .rotate(180, new paper.Point(0, 0));

    let arrowheadEdge = arrowheadVector.normalize(arrowLength);

    let arrowHead = arrows.children["Start"] as paper.Path;
    arrowHead.segments[0] = new paper.Segment(
        line.firstSegment.point
            .add(arrowheadEdge)
            .rotate(arrowAngle, line.firstSegment.point)
    );

    arrowHead.segments[1] = new paper.Segment(line.firstSegment.point);

    arrowHead.segments[2] = new paper.Segment(
        line.firstSegment.point
            .add(arrowheadEdge)
            .rotate(-arrowAngle, line.firstSegment.point)
    );

    // end arrow ===============================================
    arrowheadVector = line.getTangentAt(line.length);

    arrowheadEdge = arrowheadVector.normalize(arrowLength);

    arrowHead = arrows.children["End"] as paper.Path;
    arrowHead.segments[0] = new paper.Segment(
        line.lastSegment.point
            .add(arrowheadEdge)
            .rotate(arrowAngle, line.lastSegment.point)
    );

    arrowHead.segments[1] = new paper.Segment(line.lastSegment.point);

    arrowHead.segments[2] = new paper.Segment(
        line.lastSegment.point
            .add(arrowheadEdge)
            .rotate(-arrowAngle, line.lastSegment.point)
    );
}

/**
 * Update measurement label properties when some properties of line have changed
 */
function updateMeasurementLabel(
    shape: paper.Path,
    labelOverride: string,
    labelOffset: number,
    measurementLabel: paper.PointText,
    decimalPlaces: number
) {
    if (!labelOverride) {
        measurementLabel.content = `${getMapDistanceInFeet(
            shape.firstSegment.point,
            shape.lastSegment.point
        ).toFixed(decimalPlaces)}'`;
    }

    const offset = shape.length / 2 + labelOffset; // labelOffset is from center
    const normal = shape.getNormalAt(offset);
    const angle =
        getLineAngle(shape) + degreesToRadians(measurementLabel.rotation);
    /* move the label off the line based using sin/cos(angle) times the text width/height so that no matter the angle
       it appears equidistance from the line. */

    const width = Math.abs(Math.sin(angle)) * measurementLabel.bounds.width;
    const height = Math.abs(Math.cos(angle)) * measurementLabel.bounds.height;
    const r = (Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2)) / 2) * 1.5;

    measurementLabel.position = shape
        .getPointAt(offset)
        .add(normal.multiply(r));
}

/**
 * Add a node in a line
 * @param line The line to add the node to
 * @param nodes The already existed group of nodes
 * @param isParkingStall Pass to specify if we are adding a node to the line of ParkingStallTool
 */
export function addNode(
    line: paper.Path,
    nodes: paper.Group,
    isParkingStall: boolean = false
) {
    const divisor = line.segments.length;
    line.add(line.lastSegment.point);

    let newNode;

    if (isParkingStall) {
        newNode = new ParkingStallNode(line.lastSegment.point);
    } else {
        newNode = new LineNode(line.lastSegment.point);
    }

    newNode.addTo(nodes);

    line.firstSegment.point = nodes.firstChild.position = line.getPointAt(0);
    line.lastSegment.point = nodes.lastChild.position = line.getPointAt(
        line.length
    );

    let points = new Array<paper.Point>();

    for (let i = 1; i < line.segments.length - 1; ++i) {
        points.push(line.getPointAt((line.length / divisor) * i));
    }

    for (let i = 1; i < line.segments.length - 1; ++i) {
        line.segments[i].point = nodes.children[i].position = points[i - 1];
    }

    line.smooth({ type: "continuous" });
}
