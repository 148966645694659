import { Movable, Selectable } from "../models/implementables";
import { DiagramElement } from "./DiagramElement";
import { setUpEllipsisButtonHandlers } from "../shared/ellipsis-button";
import { projects } from "paper";
import { MenuItem } from "@progress/kendo-angular-menu";
import { GroupSelectionBuilder } from "./GroupSelection";
import { GroupSelection } from "./GroupSelection";

const eventNames: Array<string> = [
    "mousedown",
    "mouseup",
    "mousedrag",
    "click",
    "mouseenter",
    "mouseleave"
];

export abstract class DiagramObject
    extends DiagramElement
    implements Movable, Selectable
{
    static selectedObject?: DiagramObject;
    static select(obj: DiagramObject) {
        if (this.selectedObject === obj) return;
        if (
            this.selectedObject instanceof GroupSelection &&
            this.selectedObject.contained.includes(obj)
        ) {
            return;
        }
        if (this.selectedObject?.id !== obj.id) {
            this.selectedObject?.deselect();
        }
        this.selectedObject = obj;
        obj.select();
    }

    static selectAll() {
        const groupBuilder = new GroupSelectionBuilder();
        const diagramObjects = projects[0].getItems({
            data: { isDiagramObject: true }
        });
        groupBuilder.build(diagramObjects);
    }

    /** Deselects the currently selected diagram object, or the specific diagram object passed (if it was selected) */
    static deselect(specifically?: DiagramObject) {
        if (
            !this.selectedObject ||
            (specifically && this.selectedObject !== specifically)
        ) {
            return;
        }
        if (this.selectedObject) {
            this.selectedObject.deselect();
        }
        this.selectedObject = undefined;
    }

    constructor(entireObject: paper.Item) {
        super(entireObject);
        if (this.isDiagramObject === undefined) {
            this.isDiagramObject = true;
        }
        this.positionEllipsis();
    }

    get isSelected() {
        return DiagramObject.selectedObject === this;
    }

    get isDiagramObject(): boolean | undefined {
        return this.entireObject.data.isDiagramObject as boolean | undefined;
    }

    set isDiagramObject(bool: boolean | undefined) {
        this.entireObject.data.isDiagramObject = bool;
    }

    get ellipsisButton(): paper.SymbolItem {
        return this.entireObject.children["ellipsis button"];
    }

    get movingIsDisabled() {
        return this.entireObject.data.movingIsDisabled;
    }

    set movingIsDisabled(value: boolean) {
        this.entireObject.data.movingIsDisabled = value;
    }

    get wasSelected() {
        return this.entireObject.data.wasSelected;
    }

    set wasSelected(state: Boolean) {
        this.entireObject.data.wasSelected = state;
    }

    select() {
        this.positionEllipsis();
        this.ellipsisButton.visible = true;
        this.entireObject.bringToFront();
        this.wasSelected = true;
    }

    deselect() {
        this.ellipsisButton.visible = false;
        this.wasSelected = false;
    }

    abstract positionEllipsis(): void;
    abstract ellipsisOptions: MenuItem[];

    scaleEllipsisButton(amt: number) {
        this.ellipsisButton.scale(amt);
        this.positionEllipsis();
    }

    private readonly menuUpdateEvent = new CustomEvent("menu-updated", {
        bubbles: true
    });

    requestMenuUpdate(): void {
        projects[0].view.element.dispatchEvent(this.menuUpdateEvent);
    }

    dragMove(e: paper.MouseEvent) {
        this.position = e.point;
    }

    /** Sets up ellipsis button handlers
     * @todo Must override in subclasses to add additional handlers.
     * Subclasses should always call super.setUpEventHandlers() to make sure ellipsis is set up.
     */
    setUpEventHandlers(): void {
        this.dragMove = this.dragMove.bind(this);
        setUpEllipsisButtonHandlers(this.ellipsisButton);
    }

    protected abstract passEvent(e: paper.MouseEvent): void;

    protected setUpEventPassing(item: paper.Item) {
        item.on(
            Object.fromEntries(
                eventNames.map((name) => [name, this.passEvent.bind(this)])
            )
        );
    }

    remove() {
        DiagramObject.deselect(this);
        super.remove();
    }
}
