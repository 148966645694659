<div #scaleBar class="scale-bar">
    <div class="scale-divs">
        <span class="div-label first">0</span>
    </div>
    <div *ngFor="let div of divisions; index as i" class="scale-divs">
        <span *ngIf="(i + 1) !== divisions.length" class="div-label">{{ div.measure }}</span>
        <span *ngIf="(i + 1) === divisions.length"class="div-label">{{div.measure}} {{unit}}</span>
        <div
            class="scale-div"
            [style.width]="div.width + 'px'"
        >
        <div *ngIf="i === 0" class="tick first"></div>
        <div *ngIf="(i + 1) === divisions.length" class="tick last"></div>
            <div class="tick"></div>
        </div>
    </div>
    <span class="unit-label"></span>
</div>
