import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { promptBox } from "src/app/page-disabler/disable-enable-page";

@Injectable({
    providedIn: "root"
})
export class QueryStringService {
    artifactId?: number;
    vendorId?: string;

    constructor(private route: ActivatedRoute) {
        // Get the artifact ID and the vendor ID
        const idQueryString = this.route.snapshot.queryParamMap.get("id");
        const viQueryString = this.route.snapshot.queryParamMap.get("vi");

        // Assign the id values
        if (idQueryString) {
            this.artifactId = parseInt(idQueryString);
            window.sessionStorage.setItem("artifactId", idQueryString);
        }

        if (viQueryString) {
            this.vendorId = viQueryString;
            window.sessionStorage.setItem("vendorId", viQueryString);
        }
    }

    async alertMissingIds(idType: string) {
        await promptBox(
            "Error",
            `${idType} not provided. Such an ID must be provided
by the electronic crash reporting program (vendor) in order for S4 Diagram to work.
Please contact your vendor for support.`
        );
        window.close();
        throw new Error("Bad query params");
    }
}
