import { MenuItem } from "@progress/kendo-angular-menu";
import { colorCodeRGB, IconId } from "../shared/icon";
import { ncicColorCodes } from "../cdt-diagram/cdt-diagram-models";

/** Chooses the correct ellipsis menu items to display based on item.data.thing */
// export function getOptions(item: paper.Item | undefined): MenuItem[] {
//     if (!item) {
//         return [];
//     }

//     switch (item.data.thing) {
//         case "vehicle":
//             if (
//                 item.data.icon == IconId.Motorcycle ||
//                 item.data.icon == IconId.LowSpeedVehicle
//             ) {
//                 if (item.data.participantIndex != undefined) {
//                     return participantCyclistOptions;
//                 } else {
//                     return cyclistMainOptions;
//                 }
//             }
//             return determineVehicleOptions(item);
//         case "ghost":
//             if (item.data.category != "non-motorist") {
//                 return item.data.icon == IconId.HitAndRun
//                     ? ghostVehicleOptions.filter((v) => v.text != "Orientation")
//                     : ghostVehicleOptions;
//             } else {
//                 switch (item.data.parentIs) {
//                     case "pedestrian":
//                         return ghostPedestrianOptions;
//                     case "bicycle":
//                     case "wheelchair":
//                         return ghostCyclistOptions;
//                     default:
//                         return ghostNonMotoristOptions;
//                 }
//             }
//         case "animal":
//             return animalOptions;
//         case "pedestrian":
//             if (item.data.participantIndex != undefined) {
//                 return participantPedestrianOptions;
//             } else {
//                 return pedestrianMainOptions;
//             }
//         case "bicycle":
//         case "wheelchair":
//             if (item.data.participantIndex != undefined) {
//                 return participantCyclistOptions;
//             } else {
//                 return cyclistMainOptions;
//             }
//         case "group selection":
//             return groupSelectionOptions;
//         case "annotation line":
//             if (item.data.subtype == "curvy") {
//                 return annotationLineOptions;
//             } else if (item.data.subtype == "measurement") {
//                 return measurementOptions;
//             } else {
//                 return annotationLineOptions.filter(
//                     (menuItem) => menuItem.data != "add node"
//                 );
//             }
//         case "annotation parking-spaces":
//             return annotationParkingSpacesOptions;
//         case "annotation rectangle":
//         case "annotation ellipse":
//         case "annotation freehand":
//         case "annotation polygon":
//             return annotationPolygonOptions;
//         case "textbox":
//             return textboxOptions.concat(annotationPolygonOptions);
//         case "crash point":
//             return crashPointOptions;
//         case "trailer":
//             if (item.data.isAttached) {
//                 return attachedTrailerOptions;
//             } else {
//                 return detachedTrailerOptions;
//             }
//         case "sign":
//             return signageOptions;
//         default:
//             return vehicleMainOptions;
//     }
// }

export const defaultOrientationOptions = {
    text: "Orientation",
    items: [
        { data: "orientation", text: "Top Up" },
        { data: "orientation", text: "Bottom Up" },
        { data: "orientation", text: "Left/Driver Side Up" },
        { data: "orientation", text: "Right/Passenger Side Up" }
    ]
};

// export const stallAngleOptions = {
//     text: "Stall Angle",
//     items: [
//         { data: "stall angle", text: -45 },
//         { data: "stall angle", text: -30 },
//         { data: "stall angle", text: 0 },
//         { data: "stall angle", text: 30 },
//         { data: "stall angle", text: 45 }
//     ]
// };

export const scalingOptions = {
    text: "Resize",
    items: [
        { data: "scaling", text: "Enable" },
        { data: "size reset", text: "Reset" }
    ]
};

export const resetScaleOption = {
    text: "Reset Scale",
    data: "size reset"
};

export const removeOption = {
    text: "Remove",
    data: "delete"
};

export const selectAllPositionsOption = {
    text: "Select All Positions",
    data: "select all positions"
};

export const fontSizeOptions = {
    text: "Font Size",
    items: [
        { data: "font size", text: "12" },
        { data: "font size", text: "14" },
        { data: "font size", text: "16" },
        { data: "font size", text: "18" },
        { data: "font size", text: "24" },
        { data: "font size", text: "32" },
        { data: "font size", text: "64" }
    ]
};

export const reverseDirectionOptions = {
    text: "Reverse Direction",
    data: "reverse"
};

export const resetArrowOptions = {
    text: "Reset Arrows",
    data: "reset arrow"
};

export const opacityOptions = {
    text: "Opacity",
    items: [
        { data: "opacity", text: "100%" },
        { data: "opacity", text: "75%" },
        { data: "opacity", text: "50% (default)" },
        { data: "opacity", text: "25%" }
    ]
};

export const addGhost = {
    text: "Add Position",
    data: "ghost"
};

export let colorOptions : any = [];

for (const entry of Object.entries(ncicColorCodes)) {
    colorOptions.push({
        data: "color",
        text: entry[1] + ` (${entry[0]})`,
        rgb: colorCodeRGB[entry[0]],
        colorCode: entry[0]
    });
}

export const borderColorOptions = {
    text: 'Border Color',
    items: [{ data: 'color', text: 'None', colorCode: '' }].concat(
        colorOptions
    ),
};

export const fillColorOptions = {
    text: 'Fill Color',
    items: [{ data: 'fill', text: 'None', rgb: '' }].concat(
        colorOptions.map((cItem: MenuItem) => {
            const copy = JSON.parse(JSON.stringify(cItem));
            copy.data = 'fill';
            return copy;
        })
    ),
};

export const colorMenu = {
    text: "Color",
    items: colorOptions
};

export const abbreviatedColorMenu = {
    text: "Color",
    items: colorOptions.filter(
        (v: MenuItem) =>
            v.text?.startsWith("Black") ||
            v.text?.startsWith("Gold") ||
            v.text?.startsWith("White")
    )
};

export const arrowColorMenu = {
    text: "Arrow Color",
    items: colorOptions.map((cItem: MenuItem) => {
        const copy = JSON.parse(JSON.stringify(cItem));
        copy.data = "arrow color";
        return copy;
    })
};

export const isPhantom = {
    text: "Is Phantom (Hit & Run)",
    data: "phantom"
};

export const lineWeightOptions = {
    text: "Line Width",
    items: [
        {
            data: "line weight",
            text: "1"
        },
        {
            data: "line weight",
            text: "2"
        },
        {
            data: "line weight",
            text: "3"
        },
        {
            data: "line weight",
            text: "5"
        },
        {
            data: "line weight",
            text: "8"
        },
        {
            data: "line weight",
            text: "13"
        }
    ]
};

export const addPriorPositionOption = {
    text: "Add Prior Position",
    data: "ghost",
    dir: "Prior"
};

export const addPostPositionOption = {
    text: "Add Post Position",
    data: "ghost",
    dir: "Post"
};

// export const ghostPositionOptions = {
//     text: "Add Prior/Post Positions",
//     items: [
//         { data: "ghost", text: "Prior" },
//         { data: "ghost", text: "Post" }
//     ]
// };

export const changeLabelMenu = {
    text: "Change Label",
    items: [
        {
            data: "label input",
            cssClass: "fix-right-margin"
        }
    ]
};

export const ghostPedestrianOptions: MenuItem[] = [
    addGhost,
    {
        text: "Orientation",
        items: [
            { data: "orientation", text: "Standing" },
            { data: "orientation", text: "Supine" },
            { data: "orientation", text: "Prostrate" }
        ]
    },
    arrowColorMenu,
    opacityOptions,
    // reverseDirectionOptions,
    resetArrowOptions
];

export const lineStyleOptions = {
    text: "Line Style",
    items: [
        {
            data: "line style",
            text: "Solid"
        },
        {
            data: "line style",
            text: "Dashed"
        },
        {
            data: "line style",
            text: "Dotted"
        }
    ]
};

// export const annotationLineOptions: MenuItem[] = [
//     lineWeightOptions,
//     lineStyleOptions,
//     colorMenu,
//     {
//         text: "Arrows",
//         items: [
//             { data: "arrows", text: "Start Arrow" },
//             { data: "arrows", text: "End Arrow" }
//         ]
//     },
//     {
//         text: "Add Node",
//         data: "add node"
//     },
//     removeOption
// ];

// export const annotationParkingSpacesOptions = [
//     {
//         text: "Add Node",
//         data: "add node"
//     },
//     abbreviatedColorMenu,
//     stallAngleOptions,
//     removeOption
// ];

// export const annotationPolygonOptions: MenuItem[] = [
//     lineWeightOptions,
//     lineStyleOptions,
//     {
//         text: "Border Color",
//         items: [{ data: "color", text: "None", colorCode: "" }].concat(
//             colorOptions
//         )
//     },
//     {
//         text: "Fill Color",
//         items: [{ data: "fill", text: "None", rgb: "" }].concat(
//             colorOptions.map((cItem) => {
//                 const copy = JSON.parse(JSON.stringify(cItem));
//                 copy.data = "fill";
//                 return copy;
//             })
//         )
//     },
//     removeOption
// ];

export const fontColorOptions = {
    text: "Font Color",
    items: colorOptions.map((cItem: MenuItem) => {
        const copy = JSON.parse(JSON.stringify(cItem));
        copy.data = "font color";
        return copy;
    })
};

export const textboxOptions: MenuItem[] = [
    fontSizeOptions,
    fontColorOptions,
    {
        text: "Bold",
        data: "bold"
    }
];

// export const measurementOptions: MenuItem[] = (
//     [
//         {
//             text: "Override Measurement",
//             items: [
//                 {
//                     data: "label input",
//                     cssClass: "fix-right-margin"
//                 }
//             ]
//         }
//     ] as MenuItem[]
// ).concat(annotationLineOptions.filter((menuItem) => menuItem.data != "add node"));
