<div *ngIf="isReady">
    <div class="report-field">
        <div class="loc-data" style="text-align: justify">
            <div style="font-weight: bold">{{ locationType }}</div>
            <div>{{ preposition }} {{ street1 }}</div>
            <div>{{ relationToStreet2.toLowerCase() }} {{ street2 }}</div>
            <div>{{ placeAndPoint }}</div>
        </div>
    </div>
</div>
