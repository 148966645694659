<div
    #inset
    class="inset-container"
    style="position: absolute; border: 2px solid black;"
    [style.width]="window.width + 'px'"
    [style.height]="window.height + 'px'"
    [style.left]="window.point.x + 'px'"
    [style.top]="window.point.y + 'px'"
>
    <canvas
        #diagram
        class="diagram"
        resize="true"
        [class.black-and-white]="diagramStyle === 'All B&W'"
    ></canvas>
    <div #textboxes class="textboxes"></div>
    <canvas class="textboxRects" resize="true"></canvas>
    <div class="inset-number">
        {{ insetNumber }}
    </div>
    <img
        class="x-btn dont-save"
        (mousedown)="remove()"
        src="assets/SVG/Misc Icons/xmark-solid-thin.svg"
    />
    <!-- <img class="move-corner dont-save" (mousedown)="startDragMove($event)" src="assets/SVG/Misc Icons/move-arrows.svg"/> -->
    <img
        class="resize-corner dont-save"
        (mousedown)="startDragResize($event)"
        src="assets/SVG/Misc Icons/resize-corner.svg"
    />
</div>
