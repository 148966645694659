import { actionManager } from "./../Action";
import { Injectable } from "@angular/core";
import { addSpacesBetweenWords, ungroup } from "../helpers";
import { DiagramElement } from "../../classes/DiagramElement";
import {
    latLngToProjectPoint,
    latLngToViewPoint,
    leafletMap
} from "../diagram-map";
import { DiagramLayer, getLayer } from "../layer";
import * as paper from "paper";
import { Participant } from "../../classes/Participant";
import { DiagramObject } from "../../classes/DiagramObject";
import * as Models from "../../cdt-diagram/cdt-diagram-models";
import { LatLng } from "leaflet";
import { CRUDService } from "./crud.service";
import {
    colorCodeRGB,
    determineNonMotoristIcon,
    determineVehicleIcon
} from "../icon";
import { promptBox } from "src/app/page-disabler/disable-enable-page";
import { QueryStringService } from "./query-string.service";
import Watchable from "@blakecanter/watchablejs";
import { DiagramService } from "./diagram.service";
import { DiagramAction, DiagramElementAction } from "../Action";
import { UIService } from "./ui.service";
import { S4Rectangle } from "src/app/classes/S4Rectangle";
import { GeolocationService } from "./geolocation.service";

@Injectable({
    providedIn: "root"
})
export class CrashReportService {
    participants = new Array<Models.ParticipantData>();
    participantsIndexMap = new Map<string, number>();
    diagrammedParticipants = new Array<Models.ParticipantData>();
    crashReportData: Models.CrashFormData;
    participantsAreLoaded = new Watchable(false);
    static globalInstance: CrashReportService;

    constructor(
        private crud: CRUDService,
        private ids: QueryStringService,
        private ui: UIService,
        private geolocService: GeolocationService
    ) {
        CrashReportService.globalInstance = this;
    }

    get hiddenParticipants() {
        return getLayer(DiagramLayer.main).data.hiddenParticipants;
    }

    /** If the participant (num) exists in storage, restores it and returns true. Otherwise returns false. */
    restoreParticipant(num: number) {
        const addBackAction = new DiagramElementAction({
            name: "restore participant"
        });
        addBackAction.startRecording();

        let participant: paper.Group;
        let participantLatLng: L.LatLng;
        let zoomLvl: number;

        const participantJSON = this.hiddenParticipants[num];

        if (!participantJSON) {
            addBackAction.cancel();
            return false;
        }
        [participant, participantLatLng, zoomLvl] = [
            new paper.Group().importJSON(participantJSON[0]) as paper.Group,
            participantJSON[1],
            participantJSON[2]
        ];

        if (!participant) {
            addBackAction.cancel();
            return false;
        }

        DiagramService.restoreSome(participant.children as paper.Group[]);

        participant.position = latLngToProjectPoint(participantLatLng);

        const objectCore = participant.getItem({
            data: { participantIndex: num }
        });
        if (!objectCore) {
            addBackAction.cancel();
            return false;
        }
        const primaryPos = DiagramService.getByItem(objectCore) as Participant;

        ungroup(participant);
        this.participants[num].isDiagrammed = true;
        this.diagrammedParticipants.push(objectCore.data.participantData);
        this.applyCrashReportUpdate(primaryPos);
        // mergeCrashReportUpdateWithDiagrammedParticipant(
        //     objectCore.data.participantData,
        //     objectCore
        // );
        for (const pos of primaryPos.allPositions) {
            pos.normalizeSelectionUISize();
        }

        DiagramObject.select(primaryPos);
        addBackAction.stopRecording(primaryPos);

        return true;
    }

    hideParticipant(num: number, objectCore?: paper.Item) {
        const hideParticipantAction = new DiagramElementAction({
            name: "hide participant"
        });
        hideParticipantAction.startRecording();
        if (!objectCore) {
            objectCore = paper.projects[0].getItem({
                data: { participantIndex: num }
            });
        }
        const participant = DiagramService.getByItem(objectCore) as Participant;
        if (!participant) {
            hideParticipantAction.cancel();
            return;
        }
        DiagramObject.deselect(participant);

        this.setParticipantHidden(num, participant.remove()!);
        hideParticipantAction.stopRecording(participant);
    }

    setParticipantHidden(index: number, data: [paper.Group, LatLng, number]) {
        this.hiddenParticipants[index] = data;
        const p = this.participants[index];
        p.isDiagrammed = false;
        const dpi = this.diagrammedParticipants.findIndex((element) => {
            return element.number == p.number;
        });

        if (index > -1) {
            this.diagrammedParticipants.splice(dpi, 1);
        }
    }

    static recheckDiagrammedParticipants() {
        this.globalInstance.recheckDiagrammedParticipants();
    }

    recheckDiagrammedParticipants() {
        this.populateDiagrammedParticipants();

        for (const p of this.participants) {
            if (p.isDiagrammed) {
                const dpIndex = this.diagrammedParticipants.findIndex((dp) => {
                    return dp.number == p.number;
                });

                if (dpIndex == -1) {
                    p.isDiagrammed = false;
                }
            }
        }
    }

    /** Searches the diagram for participants and populates the diagrammedParticipants array
     *  with their participantData so that it can be compared to the participants array (which comes from the crash form) */
    private populateDiagrammedParticipants() {
        this.diagrammedParticipants.length = 0; // clear the array in case it has already been populated

        for (let i = 0; i < this.participants.length; ++i) {
            const foundParticipant = paper.projects[0].getItem({
                data: { participantIndex: i }
            });

            if (foundParticipant) {
                this.diagrammedParticipants.push(
                    foundParticipant.data.participantData
                );

                const participantIndex = this.participants.findIndex(
                    (element) => {
                        return (
                            element.number ==
                            foundParticipant.data.participantData.number
                        );
                    }
                );

                if (participantIndex > -1) {
                    this.participants[participantIndex].isDiagrammed = true;
                }
            }
        }
    }

    /** If a participant's icon body/color has changed on the crash report data, this updates the corresponding object on the diagram */
    mergeCrashReportUpdatesWithDiagram() {
        for (let n = 0; n < this.diagrammedParticipants.length; ++n) {
            this.mergeCrashReportUpdateWithDiagrammedParticipant(
                this.diagrammedParticipants[n]
            );
        }

        this.crud.saveDiagram();
    }

    private mergeCrashReportUpdateWithDiagrammedParticipant(
        diagrammedParticipant: Models.ParticipantData,
        objectCore?: paper.Item
    ) {
        const i = this.participants.findIndex((element) => {
            return element.number == diagrammedParticipant.number;
        });

        const iconHasChanged =
            this.participants[i].icon != diagrammedParticipant.icon;

        if (
            iconHasChanged ||
            (this.participants[i].colorCode &&
                diagrammedParticipant.colorCode !=
                    this.participants[i].colorCode)
        ) {
            const p = objectCore
                ? objectCore
                : paper.projects[0].getItem({ data: { participantIndex: i } });

            const participant = DiagramService.getByItem(p) as Participant;

            participant.icon = this.participants[i].icon;
            participant.participantData!.icon = this.participants[i].icon;
            participant.participantData!.isHitAndRun =
                this.participants[i].isHitAndRun;

            participant.forAllGhosts((ghost) => {
                ghost.icon = this.participants[i].icon;
                ghost.participantData!.isHitAndRun =
                    this.participants[i].isHitAndRun;
            });

            participant.participantData!.colorCode =
                this.participants[i].colorCode;
            participant.changeColor(
                this.participants[i].colorCode!,
                iconHasChanged
            );
        }
    }

    async validateDiagram() {
        if (
            actionManager.diagramWasJustReset ||
            (!this.crud.savedDiagramWasLoaded && !actionManager.canUndo)
        ) {
            const choice = await promptBox(
                "Validation Error",
                "No changes were made to the automated starter-diagram. While we are very pleased you are satisfied with the " +
                    "automation algorithm's results, please take a few moments to improve the diagram's quality.",
                ["Make improvements", "Finish anyways"]
            );

            if (choice !== "Finish anyways") {
                return false;
            }
        }

        const notAllDiagrammed = this.participants.filter(
            (p) => !p.isDiagrammed
        );

        if (notAllDiagrammed.length) {
            const notDiagrammedIndexes = notAllDiagrammed.map((_v, i) => i);

            const openAndFlashRows = () => {
                this.ui.legendIsOpen = true;
                this.ui.legend.flashRows(notDiagrammedIndexes);
            };

            const decision = await promptBox(
                "Heads Up",
                "Not all reported participants have been diagrammed. " +
                    "Add them by checking their row in the 'Reported Participants' legend.",
                ["Open Legend", "Continue with missing participants"]
            );

            if (decision === "Open Legend") {
                openAndFlashRows();
                return false;
            } else {
                const followUp = await promptBox(
                    "Are You Sure?",
                    "The diagram is missing participants that were listed on the crash report.",
                    ["Go back", "Finish anyways"]
                );

                if (followUp == "Go back") {
                    openAndFlashRows();
                    return false;
                }
            }
        }

        const keyParticipant = this.participants[0];

        if (keyParticipant) {
            let kpLongName = keyParticipant.number.startsWith("V")
                ? "Vehicle "
                : "Non-Motorist ";
            kpLongName += keyParticipant.number.slice(1);

            if (keyParticipant?.isDiagrammed) {
                const poi = DiagramService.crashPointObject;
                let kp = paper.projects[0].getItem({
                    data: { participantIndex: 0 }
                });

                let isTrailer = false;

                if (
                    keyParticipant.initialImpact &&
                    keyParticipant.initialImpact ===
                        Models.VehicleImpactArea.Trailer
                ) {
                    isTrailer = true;
                    kp = paper.projects[0].getItem({
                        data: { referenceId: kp.data.trailerId }
                    });
                }

                const poiPoint = poi.globalPointFor("position");
                const poiCircleArgs: [{ x: number; y: number }, number] = [
                    { x: poiPoint.x, y: poiPoint.y },
                    poi.core.bounds.width - 19
                ];
                const kpRect = new S4Rectangle(kp, kp.parent.rotation);

                if (!kpRect.intersectsCircle(...poiCircleArgs)) {
                    const choice = await promptBox(
                        "Validation Error",
                        `${kpLongName}${
                            isTrailer ? "'s trailer" : ""
                        } is not placed within a sensible distance from the point of initial impact (POI) icon. ` +
                            "Make sure its icon is touching the POI icon. " +
                            "If repositioning the POI is necessary, do so via the options in its ellipsis menu.",
                        ["Fix it", "Finish anyways"]
                    );

                    if (choice !== "Finish anyways") {
                        return false;
                    }
                }
            } else {
                const choice = await promptBox(
                    "Validation Error",
                    `${kpLongName} is not diagrammed. ` +
                        `Add it to the diagram with the checkbox in the Reported Participants Legend (bottom left of map).`,
                    ["Fix it", "Finish anyways"]
                );

                if (choice !== "Finish anyways") {
                    this.ui.legendIsOpen = true;
                    this.ui.legend.flashRows([0]);
                    return false;
                }
            }
        }

        return true;
    }

    demoImage: string = "";

    async getDiagramImage(artId: number = this.ids.artifactId || 0) {
        const url = `api/diagram/get-diagram-img/${artId}/${this.ids.vendorId}`;

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error("Problem getting diagram image from database");
            }
            const imgData = await response.blob();
            if (this.demoImage) {
                URL.revokeObjectURL(this.demoImage);
            }

            this.demoImage = URL.createObjectURL(imgData);
        } catch (error) {
            console.error("Error retrieving diagram:", error);
        }
    }

    /** Loads the crash report data pertaining to the participants into the participant arrays */
    async loadParticipants() {
        const reportData = await this.getCrashFormData();

        if (reportData) {
            await this.populateParticipantsArray(reportData);
            this.populateDiagrammedParticipants();
        }

        this.participantsAreLoaded.value = true;
    }

    setParticipants(update: Models.ParticipantData[]) {
        this.participants = update;
    }

    private async populateParticipantsArray(reportData: Models.CrashFormData) {
        this.crashReportData = reportData;
        this.participants.length = 0;
        let incompleteDataMessage = "";

        function wasSupplied(e: any) {
            if (typeof e != "string") {
                return e && e != -1;
            }

            return Boolean(e);
        }

        const defaultValues = {
            "Color Code": "gray",
            "Body Type": "passenger car",
            "Manuever Action": "straight ahead",
            "Area of Initial Impact": "1 - front center",
            "Travel Direction": "north"
        };

        if (reportData && reportData.vehicles) {
            let i = 0;

            reportData.vehicles.sort((a, b) => a.number - b.number);

            for (const veh of reportData.vehicles) {
                const toCheck = {
                    "Color Code": veh.colorCode,
                    "Body Type": veh.bodyType,
                    "Manuever Action": veh.maneuver,
                    "Area of Initial Impact": veh.impactArea,
                    "Travel Direction": veh.travelDirection
                };

                if (!Object.values(toCheck).every(wasSupplied)) {
                    incompleteDataMessage += `Vehicle ${veh.number} is missing: <ul>`;
                    for (const entry of Object.entries(toCheck)) {
                        if (!entry[1] || entry[1] == -1) {
                            incompleteDataMessage += `<li>${
                                entry[0]
                            } → defaults to: <span class="red-text">${
                                defaultValues[entry[0]]
                            }</span></li>`;
                        }
                    }
                    incompleteDataMessage += "</ul>";
                }

                const iconId = determineVehicleIcon(veh);

                this.participants.push({
                    number: "V" + veh.number,
                    icon: iconId,
                    dir: veh.travelDirection,
                    initialImpact: veh.impactArea,
                    maneuver: addSpacesBetweenWords(
                        Models.VehicleManeuver[veh.maneuver ? veh.maneuver : -1]
                    ),
                    color: veh.colorCode
                        ? colorCodeRGB[veh.colorCode.split(" ")[0]] // just use the first code if multiple space delimitated
                        : undefined,
                    colorCode: veh.colorCode,
                    isDiagrammed: false,
                    isHitAndRun: veh.isHitAndRun === "Y"
                });

                this.participantsIndexMap.set(this.participants[i].number, i);
                ++i;
            }

            if (reportData.nonMotorists) {
                let j = 0;

                reportData.nonMotorists.sort((a, b) => a.number - b.number);

                for (const nm of reportData.nonMotorists) {
                    if (!nm.desc || nm.desc == -1) {
                        incompleteDataMessage += `Non-motorist ${nm.number} is missing <ul><li>Description</li></ul>`;
                    }

                    this.participants.push({
                        number: "NM" + nm.number,
                        icon: determineNonMotoristIcon(nm),
                        dir: "-",
                        initialImpact: undefined,
                        maneuver: "-",
                        isDiagrammed: false
                    });

                    this.participantsIndexMap.set(
                        this.participants[i].number,
                        j
                    );
                    ++i;
                    ++j;
                }
            }
        }

        if (incompleteDataMessage) {
            incompleteDataMessage =
                "Your crash report is missing the fields below. " +
                "This could affect the initial, automated placement of participants. " +
                "You may continue diagramming with the default values, or return to the crash report " +
                "to fill out the missing fields if practicable.<br><br>" +
                incompleteDataMessage;
            const choice = await promptBox(
                "Alert - Missing Report Data",
                incompleteDataMessage,
                ["Proceed to Diagram", "Return to Report"]
            );

            if (choice == "Return to Report") {
                window.close();
            }
        }
    }

    private async getCrashFormData(): Promise<
        Models.CrashFormData | undefined
    > {
        const url = `api/diagram/get-crash-data/${this.ids.artifactId}/${this.ids.vendorId}`;

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(
                    "Problem getting crash form data from database"
                );
            }
            const crashData = await response.json();
            return crashData;
        } catch (error) {
            console.error("Error retrieving crash report data:", error);
            return undefined;
        }
    }

    applyCrashReportUpdate(participant: Participant) {
        if (!participant.isPrimary || !participant.participantData) return;

        const i = this.participants.findIndex((element) => {
            return element.number == participant.participantData!.number;
        });

        const iconHasChanged =
            this.participants[i].icon != participant.participantData.icon;

        if (
            iconHasChanged ||
            (this.participants[i].colorCode &&
                participant.participantData.colorCode !=
                    this.participants[i].colorCode)
        ) {
            participant.icon = this.participants[i].icon;
            participant.participantData.icon = this.participants[i].icon;
            participant.participantData.isHitAndRun =
                this.participants[i].isHitAndRun;

            participant.forAllGhosts((ghost) => {
                ghost.icon = this.participants[i].icon;
                ghost.participantData!.isHitAndRun =
                    this.participants[i].isHitAndRun;
            });

            participant.participantData.colorCode =
                this.participants[i].colorCode;
            participant.changeColor(
                this.participants[i].colorCode as string,
                iconHasChanged
            );
        }
    }
}
