<div class="scroll-flex">
    <div
        *ngFor="let icon of icons"
        class="img-wrapper"
        (mousedown)="$event.stopPropagation()"
        (click)="choose(icon)"
    >
        <img [src]="directory + icon + '.svg'" draggable="false" />
        <div class="icon-title">{{ icon }}</div>
    </div>
</div>
