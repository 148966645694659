import { MenuItem } from "@progress/kendo-angular-menu";
import { Participant } from "./Participant";
import {
    addGhost,
    addPostPositionOption,
    addPriorPositionOption,
    arrowColorMenu,
    changeLabelMenu,
    colorMenu,
    defaultOrientationOptions,
    opacityOptions,
    removeOption,
    resetArrowOptions,
    resetScaleOption,
    reverseDirectionOptions,
    scalingOptions,
    selectAllPositionsOption
} from "./ellipsis-menu-options";
import { IconId } from "../shared/icon";

export class Vehicle extends Participant {
    protected applyClassName() {
        this.className = "Vehicle";
    }

    get ellipsisOptions(): MenuItem[] {
        const [commonFirst, commonLast] = this.getCommonEllipsisOptions();

        const orientationOptions =
            this.icon === IconId.Motorcycle ||
            this.icon === IconId.LowSpeedVehicle
                ? {
                      text: "Orientation",
                      items: [
                          { data: "orientation", text: "Top Up" },
                          {
                              data: "orientation",
                              text: "Left/Driver Side Up"
                          },
                          {
                              data: "orientation",
                              text: "Right/Passenger Side Up"
                          }
                      ]
                  }
                : defaultOrientationOptions;

        commonFirst.push(orientationOptions);

        if (!this.isGhost) {
            let scalingMenu = structuredClone(scalingOptions);

            if (this.scaleHandle) {
                scalingMenu.items[0].text = "Disable";
            } else {
                scalingMenu.items[0].text = "Enable";
            }

            commonLast.unshift(scalingMenu);
        }

        if (this.isFromCrashReport) {
            return [...commonFirst, ...commonLast];
        }

        return [changeLabelMenu, ...commonFirst, colorMenu, ...commonLast];
    }

    set isScalable(state: boolean) {
        super.isScalable = state;
        this.requestMenuUpdate();
    }

    get isScalable() {
        return this.core.data.isScalable;
    }

    repositionLabel(): void {
        if (this.label) {
            this.label.position = this.globalPointFor("bounds.center");
        }
    }
}
