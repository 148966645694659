<!-- <button id="legend-toggle" class="map-control-btn box-shadow-flat pointer-events-auto" [class.display-none]="isOpen" (click)="isOpen = !isOpen">
    <i class="fal fa-map-marked-alt fa-fw fa-lg"></i>
</button> -->
<icon-btn
    *ngIf="!isOpen"
    [customIcon]="true"
    icon="map-data"
    cssClass="bg-base std cursor-pointer dont-save pointer-events-auto"
    title="Reported Participants"
    (trigger)="isOpen = !isOpen"
></icon-btn>

<div class="crash-legend pointer-events-auto" [class.display-none]="!isOpen">
    <div class="legend-title">
        <span>Reported Participants</span>
        <button (click)="isOpen = !isOpen"><i class="fal fa-times"></i></button>
    </div>
    <div class="legend-content">
        <crash-report-grid id="crash-report-grid"></crash-report-grid>
    </div>
</div>
