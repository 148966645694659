import { Rotatable, Scalable } from "../models/implementables";
import {
    disableMapDragging,
    enableMapDragging,
    getDiagramZoomScale
} from "../shared/diagram-map";
import {
    Cursor,
    clearCursor,
    lockCursor,
    setCursor,
    unlockCursor
} from "../shared/cursor";
import { controlKeyPressed } from "../shared/global-event-states";
// import { Participant } from "./Participant";
import { DiagramObject } from "./DiagramObject";
import { MenuItem } from "@progress/kendo-angular-menu";
import { CircleSelectedObjectBuilder } from "./ObjectBuilders";
import { removeOption, resetScaleOption } from "./ellipsis-menu-options";
import * as paper from "paper";
import {
    DiagramAction,
    DiagramElementAction,
    PropTrackingAction
} from "../shared/Action";
import {
    getInverseScaling,
    globalizeBounds,
    roundToPrecision
} from "../shared/helpers";
import {
    finishMajorDraw,
    startMajorDraw
} from "../page-disabler/disable-enable-page";

// SUPER IMPORTANT FOR UNDERSTANDING ALL THE HIERARCHY STUFF:
// CircleSelectedObjects are grouped with their selection controls
// like so: [[selectionControls, objectCore] ellipsisBtn, label]
// The outermost group is the whole object with all of its pieces which can be moved
// The inner group can be rotated and scaled, so only includes the core and the selection controls (circle w/ optional rotation and scale handles)
// The ellipsis button and label exist outside of the inner group as children of the outer group and therefore don't rotate or scale
// note: "selection" is always the object core

export class CircleSelectedObject
    extends DiagramObject
    implements Rotatable, Scalable
{
    protected applyClassName() {
        this.className = "CircleSelectedObject";
    }

    get innerGroup(): paper.Group {
        return this.entireObject.children["inner group"];
    }

    get core(): paper.Item {
        return this.innerGroup.children["object core"];
    }

    get selectionControls(): paper.Group {
        return this.innerGroup.children["selection controls"];
    }

    get selectionCircle(): paper.Path {
        return this.selectionControls.children["selection circle"];
    }

    get rotationHandle(): paper.Path {
        return this.selectionControls.children["rotation handle"];
    }

    get scaleHandle(): paper.Path {
        return this.selectionControls.children["scale handle"];
    }

    get rotation() {
        return this.innerGroup.rotation;
    }

    set rotation(degrees: number) {
        this.innerGroup.rotation = degrees;
    }

    get isScalable() {
        return this.core.data.isScalable;
    }

    set isScalable(value: boolean) {
        this.core.data.isScalable = value;
    }

    get isRotatable() {
        return this.core.data.isRotatable;
    }

    get icon() {
        return this.core.data.icon;
    }

    set icon(icon: string) {
        this.core.data.icon = icon;
    }

    get baseScaling(): paper.Point {
        return this.core.data.baseScaling;
    }

    get isScaled(): boolean {
        return this.innerGroup.scaling.x != this.baseScaling.x;
    }

    set baseScaling(scale: paper.Point) {
        this.core.data.baseScaling = scale;
    }

    get pivotLocked(): boolean {
        return this.core.data.pivotLocked;
    }

    set pivotLocked(state: boolean) {
        this.core.data.pivotLocked = state;
    }

    get ellipsisOptions(): MenuItem[] {
        return [resetScaleOption, removeOption];
    }

    toggleScaling() {
        if (this.isScalable) {
            this.scaleHandle.remove();
            this.isScalable = false;
        } else {
            this.selectionControls.addChild(
                CircleSelectedObjectBuilder.createScaleHandle(
                    this.selectionCircle
                )
            );
            const p = this.selectionCircle.getPointAt(
                this.selectionCircle.length / 2
            );
            this.scaleHandle.position = p;
            this.isScalable = true;
            this.setUpScaleHandleEvents();
        }
    }

    rotate(deg: number) {
        this.innerGroup.rotate(deg);
    }

    globalPointFor(propOrDotNotation: string) {
        let prop: any = this.core;
        let props = propOrDotNotation.split(".");
        for (const subProp of props) {
            prop = prop[subProp];
        }
        return this.innerGroup.localToGlobal(prop);
    }

    protected passEvent(e: paper.MouseEvent) {
        let target = this.core;
        if (!target) return;
        e.currentTarget = target;
        e.target = target;
        target.emit(e.type, e);
    }

    setUpEventHandlers(): void {
        // check for rotation/scale handle
        if (this.rotationHandle) {
            this.setUpRotationHandleEvents();
        }

        if (this.isScalable) {
            this.setUpScaleHandleEvents();
        }

        this.setUpCoreEvents();
        this.setUpEventPassing(this.selectionCircle);

        super.setUpEventHandlers();
    }

    protected setUpCoreEvents(): void {
        this.core.onClick = (e: paper.MouseEvent) => {
            DiagramObject.select(this);
        };

        this.core.onMouseEnter = (e: paper.MouseEvent) => {
            if (this.isSelected && !this.movingIsDisabled) {
                setCursor(Cursor.Move);
            } else {
                setCursor(Cursor.Pointer);
            }
        };

        this.core.onMouseLeave = (e: paper.MouseEvent) => {
            clearCursor();
        };

        this.core.onMouseDown = this.onMouseDown.bind(this);
        this.core.onMouseUp = this.onMouseUp.bind(this);
    }

    onMouseDown(e: paper.MouseEvent) {
        if (this.isSelected) {
            if (!DiagramAction.isRecording && !this.movingIsDisabled) {
                new PropTrackingAction<paper.Point>({
                    name: "translate",
                    elementRef: this,
                    propKey: "position",
                    propComparator: (a, b) => a.equals(b)
                }).startRecording();
            }
            lockCursor();
            disableMapDragging();

            if (!this.pivotLocked) {
                this.entireObject.pivot = e.point;
            }

            if (!this.movingIsDisabled) {
                this.core.onMouseDrag = this.dragMove;
            }
        }
    }

    onMouseUp(e: paper.MouseEvent) {
        unlockCursor();
        this.core.off("mousedrag", this.dragMove);

        if (!this.pivotLocked) {
            this.entireObject.pivot = this.globalPointFor("position");
        }

        if (!controlKeyPressed) enableMapDragging();

        if (this.core.contains(e.point) && !this.movingIsDisabled) {
            setCursor(Cursor.Move);
        }

        const mutation = DiagramAction.currentAction;

        if (mutation && mutation instanceof PropTrackingAction) {
            if (mutation.propHasChanged) {
                mutation.stopRecording();
            } else {
                mutation.cancel();
            }
        }
    }

    dragRotate(e: paper.MouseEvent) {
        const newAngle = this.innerGroup.position.subtract(e.point).angle;
        this.rotation = newAngle;
    }

    dragScale(e: paper.MouseEvent) {
        const scaleAmount = this.getScaleToPoint(e.point);
        this.scaleBy(scaleAmount);
    }

    resetScale() {
        let resetScaleOption: DiagramElementAction | undefined = undefined;

        if (!DiagramAction.isRecording) {
            resetScaleOption = new DiagramElementAction({
                name: "reset scale",
                elementRef: this
            });

            resetScaleOption.startRecording();
        }

        startMajorDraw();

        this.innerGroup.scaling = this.baseScaling;
        this.normalizeSelectionUISize();

        finishMajorDraw();
        this.requestMenuUpdate();

        if (resetScaleOption) resetScaleOption.stopRecording();
    }

    setUpScaleHandleEvents(): void {
        this.dragScale = this.dragScale.bind(this);

        this.scaleHandle.onMouseEnter = (e: paper.MouseEvent) => {
            setCursor(Cursor.NWSE_Resize);
        };

        this.scaleHandle.onMouseLeave = (e: paper.MouseEvent) => {
            clearCursor();
        };

        this.scaleHandle.onMouseDown = (e: paper.MouseEvent) => {
            lockCursor();
            new PropTrackingAction<paper.Point>({
                name: "scale",
                elementRef: this,
                propKey: "innerGroup.scaling",
                propComparator(a, b) {
                    return a.equals(b);
                }
            }).startRecording();
            this.scaleHandle.onMouseDrag = this.dragScale;
            disableMapDragging();
        };

        this.scaleHandle.onMouseUp = (e: paper.MouseEvent) => {
            unlockCursor();
            this.scaleHandle.off("mousedrag", this.dragScale);
            if (!controlKeyPressed) enableMapDragging();
            if (!e.point.isInside(this.scaleHandle.bounds)) {
                clearCursor();
            }

            const scaleAction = DiagramAction.currentAction as
                | PropTrackingAction<paper.Point>
                | undefined;

            if (scaleAction && scaleAction.propHasChanged) {
                this.requestMenuUpdate();
                scaleAction.stopRecording();
            }

            DiagramAction.currentAction?.cancel();
        };
    }

    setUpRotationHandleEvents(): void {
        this.dragRotate = this.dragRotate.bind(this);

        this.rotationHandle.onMouseEnter = () => {
            setCursor(Cursor.Spin);
        };

        this.rotationHandle.onMouseLeave = (e: paper.MouseEvent) => {
            clearCursor();
        };

        this.rotationHandle.onMouseDown = (e: paper.MouseEvent) => {
            new PropTrackingAction<number>({
                name: "rotate",
                elementRef: this,
                propKey: "rotation"
            }).startRecording();
            lockCursor();
            this.rotationHandle.onMouseDrag = this.dragRotate;
            disableMapDragging();
        };

        this.rotationHandle.onMouseUp = (e: paper.MouseEvent) => {
            unlockCursor();
            this.dragRotate(e);
            this.rotationHandle.off("mousedrag", this.dragRotate);

            const rotation = DiagramAction.currentAction as
                | PropTrackingAction<number>
                | undefined;

            if (rotation && rotation.propHasChanged) {
                rotation.stopRecording();
            } else {
                rotation?.cancel();
            }

            if (!controlKeyPressed) enableMapDragging();
            if (!e.point.isInside(this.rotationHandle.bounds)) {
                clearCursor();
            }
        };
    }

    protected getNormalizedScaling(zoomScale?: number) {
        if (!zoomScale) zoomScale = getDiagramZoomScale();
        return getInverseScaling(zoomScale).divide(this.innerGroup.scaling);
    }

    normalizeSelectionUISize(scaling?: paper.Point, zoomScale?: number) {
        if (!zoomScale) zoomScale = getDiagramZoomScale();
        if (!scaling) scaling = this.getNormalizedScaling(zoomScale);

        this.selectionCircle.strokeWidth =
            3 / zoomScale / this.innerGroup.scaling.x;

        if (this.rotationHandle) {
            this.rotationHandle.scaling = scaling;
        }

        if (this.scaleHandle) {
            this.scaleHandle.scaling = scaling;
            const p = this.selectionCircle.getPointAt(
                this.selectionCircle.length / 2
            );
            this.scaleHandle.position = p;
        }

        this.positionEllipsis();
    }

    select(): void {
        this.selectionControls.visible = true;
        super.select();
    }

    deselect(): void {
        super.deselect();
        this.selectionControls.visible = false;
    }

    protected getScaleToPoint(p: paper.Point) {
        const oldRad = this.innerGroup.bounds.width / 2;
        const neededRad = this.innerGroup.position.getDistance(p);
        return neededRad / oldRad;
    }

    protected scaleBy(amount: number) {
        // prevent shrinking too small or growing too large
        const size = this.innerGroup.bounds.width * this.innerGroup.scaling.x;

        if (size * amount < 24) {
            amount = roundToPrecision(
                24 / this.innerGroup.bounds.width / this.innerGroup.scaling.x,
                1
            );
        } else if (size * amount > 2400) {
            amount = roundToPrecision(
                2400 / this.innerGroup.bounds.width / this.innerGroup.scaling.x,
                1
            );
        }

        if (amount === 1) {
            return false;
        }

        this.innerGroup.scale(amount);
        this.normalizeSelectionUISize();
        this.positionEllipsis();
        return true;
    }

    positionEllipsis() {
        const offset = this.innerGroup.bounds.width * 0.12;
        this.ellipsisButton.rotation = 0;

        this.ellipsisButton.bounds.bottomLeft =
            this.innerGroup.bounds.topRight.subtract(
                new paper.Point(offset, -offset)
            );
    }

    getGlobalBounds(): paper.Rectangle {
        return globalizeBounds(this.core.bounds, this.innerGroup);
    }

    getOutline(){
        const outline = this._getOutline(this.core);
        outline.position = this.globalPointFor("position");
        return outline;
    }
}
