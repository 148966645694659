<div class="bar-container">
    <!-- *ngIf="!(btn.label == 'Done' && savingDisabled)" -->

    <fieldset *ngFor="let btnGroup of buttons" class="control-bar-group" [disabled]="isRepositioning">
        <ng-template ngFor let-btn [ngForOf]="btnGroup">
            <button
                *ngIf="!isMobileOrTablet"
                class="control-bar-btn"
                [id]="btn.label"
                (click)="buttonClick($event, btn.label)"
                [disabled]="btn.disabled"
                [class]="{ 'btn-toggled': btn.toggled }"
            >
                <div class="btn-icon">
                    <i [class]="btn.icon"></i>
                </div>
                <div class="btn-label">{{ btn.label }}</div>
            </button>
            <button
                *ngIf="isMobileOrTablet"
                class="control-bar-btn"
                [id]="btn.label"
                (click)="buttonClick($event, btn.label)"
                [disabled]="btn.disabled"
                [title]="btn.label"
                [class]="{ 'btn-toggled': btn.toggled }"
            >
                <div class="btn-icon">
                    <i [class]="btn.icon"></i>
                </div>
                <div class="btn-label">{{ btn.label }}</div>
            </button>
        </ng-template>
    </fieldset>
    <!-- <div
        *ngIf="!savingDisabled"
        id="done-button-group"
        class="control-bar-group"
    >
        <button
            [id]="doneButton.label"
            class="control-bar-btn mx-1"
            (click)="buttonClick(doneButton.label)"
            [disabled]="doneButton.disabled"
        >
            <div class="btn-icon">
                <i style="margin: auto" [class]="doneButton.icon"></i>
            </div>
            <div class="btn-label">{{ doneButton.label }}</div>
        </button>
    </div> -->
</div>
<popup-menu #infoPopup title="Crash Report Data">
    <crash-report-info></crash-report-info>
    <!-- <div class="p-2 vw50 vh50max overflow-y-auto">Crash data goes here</div> -->
</popup-menu>

<popup-menu #bugReport title="Report a Problem">
    <div class="p-2 vw50">
        <span
            >Please email us at
            <a
                class="information email font-main txt7"
                href="mailto:s4-support@ufl.edu"
            >
                s4-support&#64;ufl.edu
            </a>
            if you have encountered a problem.</span
        >
    </div>
</popup-menu>

<popup-menu #help title="Need Help???">
    <div class="p-2 vw50">
        <div
            class="d-flex flex-row align-items-center w100 justify-content-between"
        >
            <span><i class="fal fa-hard-hat fa-2x"></i></span>
            <span class="text-center flex-grow">
                This resource is under development and will be available
                soon.<br />For now email us at
                <a
                    class="information email font-main txt7"
                    href="mailto:s4-support@ufl.edu"
                >
                    s4-support&#64;ufl.edu
                </a>
                for assistance.
            </span>
            <span><i class="fal fa-hammer fa-2x"></i></span>
        </div>
    </div>
</popup-menu>
