import {
    AfterContentInit,
    Component,
    ElementRef,
    HostListener,
    Input
} from "@angular/core";
import * as paper from "paper";
import Point from "../shared/S4Point";

@Component({
    selector: "popup-menu",
    templateUrl: "./popup-menu.component.html",
    styleUrls: ["./popup-menu.component.scss"]
})
export class PopUpMenuComponent implements AfterContentInit {
    @Input() title = "";
    /** Whether or not it can be repositioned by dragging */
    @Input() moveable = false;
    /** Whether or not the popup closes on mousedown outside its bounds */
    @Input() autoClose = true;
    /** When set to true, will close when the mouse leaves it */
    @Input() hoverOnly = false;
    /** Whether or not the popup has an x button in the top right corner for closing it */
    @Input() hasXBtn = true;

    isShown = false;

    private popupPoint = new Point();
    private offset = new Point();
    private dragging = false;
    private get rect(): DOMRect {
        return this.eRef?.nativeElement.firstElementChild?.getBoundingClientRect();
    }
    private windowSize: Point;

    get windowLeft() {
        return `${this.popupPoint.x}px`;
    }

    get windowTop() {
        return `${this.popupPoint.y}px`;
    }

    constructor(private eRef: ElementRef) {}

    ngAfterContentInit(): void {
        this.windowSize = new Point(window.innerWidth, window.innerHeight);
    }

    toggle() {
        this.isShown = !this.isShown;
    }

    open(point?: Point | paper.Point, offset?: Point | string) {
        this.isShown = true;

        if (offset) {
            this.setOffset(offset);
        }

        if (point) {
            this.setPosition(point);
        }
    }

    close() {
        this.isShown = false;
    }

    setPosition(point: Point | paper.Point) {
        if (point instanceof paper.Point) {
            point = new Point(point.x, point.y);
        }

        this.popupPoint = point.subtract(this.offset);
    }

    /** The offset point is subtracted from the window's position. Useful for establishing a origin other than
     * the default top left corner. A string can be passed to use presets.
     *
     * Currently implemented presets are:
     ** "none" -- clears the offset
     ** "bottom left" -- sets the origin to the bottom left corner
     */
    setOffset(point: Point | string) {
        if (typeof point == "string") {
            switch (point) {
                case "none":
                    this.offset = new Point();
                    break;
                case "bottom left":
                    this.offset = new Point(0, this.rect.height);
                    break;
                case "center":
                    this.offset = new Point(
                        this.rect.width / 2,
                        this.rect.height / 2
                    );
                    break;
                case "top center":
                    this.offset = new Point(this.rect.width / 2, 0);
                    break;
                case "bottom center":
                    this.offset = new Point(
                        this.rect.width / 2,
                        this.rect.height
                    );
                    break;
            }
        } else {
            this.offset = point;
        }
    }

    mouseLeavesPopup() {
        if (this.hoverOnly) {
            this.close();
        }
    }

    contains(p: Point) {
        const rect = this.rect;

        return (
            p.x >= rect.left &&
            p.x <= rect.right &&
            p.y >= rect.top &&
            p.y <= rect.bottom
        );
    }

    @HostListener("document:mousedown", ["$event"])
    clickOut(event: MouseEvent) {
        if (this.autoClose && !this.contains(new Point(event.x, event.y)))
            this.close();
    }

    @HostListener("window:mouseup")
    stopDrag() {
        this.dragging = false;
    }

    @HostListener("mousedown", ["$event"])
    initDrag(e: MouseEvent) {
        if (this.moveable) {
            const rect =
                this.eRef.nativeElement.firstElementChild.getBoundingClientRect() as DOMRect;
            this.setOffset(new Point(e.x - rect.x, e.y - rect.y + 66));
            this.dragging = true;
        }
    }

    @HostListener("window:mousemove", ["$event"])
    dragMove(e: MouseEvent) {
        if (this.dragging) {
            this.setPosition(new Point(e.x, e.y));
        }
    }

    @HostListener("window:resize")
    reposition() {
        // if (this.isShown) {
        //     const xChange = window.innerWidth - this.windowSize.x;
        //     const yChange = window.innerHeight - this.windowSize.y;
        //     const vector = new Point(xChange, yChange);
        //     // this.open(
        //         this.popupPoint.add(vector);
        //         // this.offset.add(vector)
        //     // );
        // }
        // this.windowSize.x = window.innerWidth;
        // this.windowSize.y = window.innerHeight;
        if (this.isShown) this.close();
    }
}
