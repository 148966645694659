export function contactSupportMessage(app = "S4 Diagram", vendor?: string) {
    return (
        `There is an internal issue with our ${app} application` +
        `${vendor ? ` (${vendor} is not responsible)` : ""}. ` +
        `We are already aware of the problem and the team is working diligently to resolve it. ` +
        `No action is required, but if you must contact us, reach out to our support: s4-support@ufl.edu, (352) 294-1496, ` +
        `Lance Barbour, (352) 448-1331, lbarbour@ufl.edu or Dr. Ilir Bejleri, (352) 294-1496, ilir@ufl.edu. ` +
        `We are sorry for the inconvenience.`
    );
}
