<div class="map-controls pointer-events-none" id="mapControls">
    <div *ngIf="hasWarning" class="map-warning" [class.is-showing]="isShowingWarning" role="alert">
        <span class="warning-text">{{ warningMessage | uppercase}}</span>
    </div>
    <div class="half top-half">
        <div class="quadrant top-left-quadrant" kendoTooltip position="right">
            <icon-btn *ngIf="!isCropping && !hasSidePanel && !isRepositioning" icon="plus-circle" cssClass="bg-base std cursor-pointer dont-save pointer-events-auto" title="Diagram Tools" (trigger)="toggleSidePanel($event)"></icon-btn>
            <icon-btn *ngIf="!isCropping && hasSidePanel && !isRepositioning" icon="times" cssClass="bg-base std cursor-pointer dont-save pointer-events-auto" title="Diagram Tools" (trigger)="toggleSidePanel($event)"></icon-btn>

            <!-- <button *ngIf="!isCropping"
                id="side-panel-toggle"
                class="map-control-btn bg-base dont-save pointer-events-auto box-shadow-flat"
                [class.open]="hasSidePanel"
                (click)="toggleSidePanel($event)"
                title="Diagram Tools"
            >
                <span class="toggle-icon">
                    <i class="fal fa-chevron-double-right fa-fw fa-lg"></i>
                </span>
            </button> -->
        </div>
    </div>
    <div class="half bottom-half">
        <div class="quadrant bottom-left-quadrant">
            <crash-legend class="dont-save" [class.display-none]="isCropping"></crash-legend>
        </div>
        <div class="quadrant bottom-right-quadrant" kendoTooltip position="left">
            <appearance-control class="mb-1 dont-save"></appearance-control>
            <!-- <base-map-control class="mb-1 dont-save"></base-map-control> -->
            <div id="opacity-slider" class="dont-save pointer-events-auto mb-1" >
                <kendo-slider
                    class="opacity-slider"
                    [vertical]="true"
                    [showButtons]="false"
                    [min]="0.2"
                    [max]="1"
                    [smallStep]="0.1"
                    [(ngModel)]="layerOpacity"
                ></kendo-slider>
            </div>
            <button
                id="home"
                title="Zoom to diagram extent"
                class="map-control-btn bg-base dont-save pointer-events-auto mb-1 box-shadow-flat"
                (click)="goHome()"
            >
                <i class="fal fa-home fa-fw fa-lg"></i>
            </button>
            <zoom-control></zoom-control>
        </div>
    </div>
</div>
