import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RelToNwk } from "../shared/services/geolocation.service";

@Component({
    selector: "little-loctype-buttons",
    templateUrl: "./little-loctype-buttons.component.html",
    styleUrls: ["./little-loctype-buttons.component.scss"]
})
export class LittleLoctypeButtonsComponent {
    @Output() newSelection = new EventEmitter<RelToNwk>();
    @Input() value: RelToNwk | undefined = undefined;
    @Input() disabled: boolean = false;

    buttons: { icon: string; value: RelToNwk }[] = [
        { icon: "loc_intersection.png", value: "INTERSECTION" },
        { icon: "loc_segment.png", value: "SEGMENT" },
        { icon: "loc_ramp.png", value: "RAMP" },
        { icon: "loc_offroadway.png", value: "OFFROADWAY" }
    ];

    constructor() {}

    onButtonPress(value: RelToNwk) {
        if (value !== this.value) {
            this.value = value;
            this.newSelection.emit(value);
        }
    }
}
