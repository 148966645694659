import {
    Cursor,
    clearCursor,
    getResizeCursorFor,
    lockCursor,
    setCursor,
    unlockCursor
} from "src/app/shared/cursor";
import { Annotation } from "./Annotation";
import {
    disableMapDragging,
    enableMapDragging
} from "src/app/shared/diagram-map";
import { ControlNode } from "../ControlNode";
import { DiagramAction, PropTrackingAction } from "src/app/shared/Action";

export class AnnotationNode extends ControlNode {
    protected applyClassName() {
        this.className = "AnnotationNode";
    }

    get parentElement(): Annotation {
        return super.parentElement as Annotation;
    }

    get segmentIndex(): number {
        return this.parentElement.controlNodes.indexOf(this);
    }

    get cursorIcon() {
        return this.entireObject.data.cursor;
    }

    protected onMouseDown(e?: paper.MouseEvent) {
        disableMapDragging();
        setCursor(Cursor.Crosshair);
        lockCursor();
        if (!DiagramAction.isRecording) {
            new PropTrackingAction<paper.Point>({
                name: "reshape annotation",
                elementRef: this.parentElement,
                propKey: `controlNodes[${this.segmentIndex}].position`,
                propComparator: (a, b) => a.equals(b)
            }).startRecording();
        }
        this.entireObject.on("mousedrag", this.onMouseDrag);
        if (this.nodeGroup) this.nodeGroup.visible = false;
        e?.stopPropagation();
    }

    protected onMouseDrag(e: paper.MouseEvent) {}

    protected onMouseUp(e: paper.MouseEvent) {
        unlockCursor();
        this.entireObject.off("mousedrag", this.onMouseDrag);
        enableMapDragging();
        // show nodes again, now that positioning is finished
        clearCursor();
        if (this.nodeGroup) this.nodeGroup.visible = true;
        const action = DiagramAction.currentAction;
        if (action && action instanceof PropTrackingAction) {
            if (action.propHasChanged) {
                action.stopRecording();
            } else {
                action.cancel();
            }
        }
    }

    public setUpEventHandlers() {
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseDrag = this.onMouseDrag.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);

        this.entireObject.onMouseDown = this.onMouseDown;
        this.entireObject.onMouseUp = this.onMouseUp;
        // ... assign other handlers to the Paper.js drawing

        this.entireObject.onMouseEnter = (e: paper.MouseEvent) => {
            if (this.cursorIcon == "dynamic") {
                setCursor(
                    getResizeCursorFor(
                        this.entireObject,
                        this.parentElement.shape
                    )
                );
            } else {
                setCursor(this.cursorIcon);
            }
        };

        this.entireObject.onMouseLeave = (e: paper.MouseEvent) => {
            clearCursor();
        };
    }
}
