// Classes
import { Annotation } from "./Annotation";
import * as paper from "paper";
import { MenuItem } from "@progress/kendo-angular-menu";
import { AnnotationBuilder } from "./AnnotationBuilders";
import { DiagramLayer } from "src/app/shared/layer";
import { degreesToRadians, getDiagramZoomScale } from "src/app/shared/helpers";
import { AnnotationNode } from "./AnnotationNode";
import { abbreviatedColorMenu, removeOption } from "../ellipsis-menu-options";
import { addNode } from "./LineTool";
import { DiagramElementAction } from "src/app/shared/Action";

export class ParkingStallToolBuilder extends AnnotationBuilder {
    protected initialLayer: DiagramLayer = DiagramLayer.backgroundAnnotations;

    get line() {
        return this.unfinishedAnno!.children["line"] as paper.Path;
    }

    get spaces() {
        if (!this.unfinishedAnno) return undefined;

        return this.unfinishedAnno.children["spaces"] as paper.Group;
    }

    get firstStallLine() {
        return this.shape.children[1] as paper.Path | undefined;
    }

    protected setDefaultStyles(shape: paper.Path): void {
        shape.strokeColor = Annotation.defaultParkingStallColor;
    }

    protected preventInvisibleShape(shape: paper.Path): boolean {
        return false;
    }

    protected startShape(point: paper.Point): void {
        const line = new paper.Path.Line({
            from: point,
            to: point,
            name: "line",
            strokeColor: Annotation.defaultParkingStallColor,
            data: {
                thing: "parking-spaces line",
                lastDir: 1,
                stallAngle: Annotation.defaultStallAngle
            }
        });

        const spaces = new paper.Group({
            name: "spaces",
            strokeColor: Annotation.defaultParkingStallColor
        });

        const unfinishedAnno = this.initAnno(line);
        unfinishedAnno.addChild(spaces);

        unfinishedAnno.strokeColor = Annotation.defaultParkingStallColor;
        unfinishedAnno.strokeWidth = 2;
    }

    protected continueShape(point: paper.Point): void {
        if (!this.unfinishedAnno || !this.line || !this.spaces) return;

        this.line.firstSegment.point = point;
        this.createSpaces();

        const parkingSpaces = new paper.CompoundPath({
            children: [this.line, ...this.spaces.children],
            strokeColor: Annotation.defaultParkingStallColor,
            strokeWidth: 2,
            name: "shape",
            data: {
                thing: "annotation parking-spaces",
                category: "annotation"
            }
        });

        this.unfinishedAnno.insertChild(0, parkingSpaces);
        this.unfinishedAnno.applyMatrix = false;

        if (!this.firstStallLine) {
            this.toggle();
            return;
        }

        this.completeShape();
    }

    protected toolMove = (e: paper.MouseEvent) => {
        if (!this.unfinishedAnno) return;

        if (this.line) {
            this.line.firstSegment.point = e.point;
            this.createSpaces();
        }
    };

    /**
     * Create additional spaces as the mouse is moving to a certain interval
     * @param nodeIndex
     * @returns Don't execute if line and spaces are undefined
     */
    createSpaces(nodeIndex = 0) {
        if (!this.line || !this.spaces) return;

        createSpaces(this.line, this.spaces, nodeIndex);
    }

    protected createResizeNodes(): paper.Path.Circle[] {
        return [
            new ParkingStallNode(this.shape.children["line"].firstSegment.point)
                .entireObject,
            new ParkingStallNode(this.shape.children["line"].lastSegment.point)
                .entireObject
        ] as paper.Path.Circle[];
    }

    build(): Annotation {
        const parkingStallTool = new ParkingStallTool(this.unfinishedAnno!);
        return parkingStallTool;
    }
}

export class ParkingStallTool extends Annotation {
    protected applyClassName() {
        this.className = "ParkingStallTool";
    }

    static readonly parkingSpaceWidth: number = 43;
    static readonly parkingSpaceLength: number = 80;

    get ellipsisOptions(): MenuItem[] {
        const stallAngleOptions = {
            text: "Stall Angle",
            items: [
                { data: "stall angle", text: -45 },
                { data: "stall angle", text: -30 },
                { data: "stall angle", text: 0 },
                { data: "stall angle", text: 30 },
                { data: "stall angle", text: 45 }
            ]
        };

        return [
            {
                text: "Add Node",
                data: "add node"
            },
            abbreviatedColorMenu,
            stallAngleOptions,
            removeOption
        ];
    }

    get line() {
        return this.shape.children["line"] as paper.Path;
    }

    get spaces() {
        if (!this.shape) return undefined;

        return this.shape.parent.children["spaces"] as paper.Group;
    }

    get firstStallLine() {
        return this.shape.children[1] as paper.Path | undefined;
    }

    get parkingSpaces(): paper.Item | undefined {
        if (!this.line) return undefined;
        return this.line.parent;
    }

    get stallAngle(): number {
        return this.line?.data.stallAngle;
    }

    set stallAngle(value: number) {
        if (this.line) {
            this.line.data.stallAngle = value;
        }
    }

    set lineEndPoint(value: paper.Point) {
        if (this.line) {
            this.line.data.endPoint = value;
        }
    }

    changeStrokeColor(color: paper.Color): void {
        const changeStrokeColorAction = new DiagramElementAction({
            name: "change stroke color",
            elementRef: this
        });
        changeStrokeColorAction.startRecording();
        this.shape.strokeColor = Annotation.defaultParkingStallColor =
            color;
        changeStrokeColorAction.stopRecording();

    }

    positionEllipsis(): void {
        if (!this.firstStallLine || !this.line) return;

        this.ellipsisButton.position = this.firstStallLine
            .getPointAt(this.firstStallLine.length / 2)
            .subtract(
                this.line
                    .getTangentAt(this.firstStallLine.length / 2)
                    .normalize(25)
            );
    }

    /**
     * Update spaces properties when shape properties have changed
     * @param nodeIndex
     * @returns Don't execute code if line, spaces, and parkingSpaces are undefined
     */
    updateSpaces(nodeIndex = 0) {
        if (!this.line || !this.spaces || !this.parkingSpaces) return;
        createSpaces(this.line, this.spaces, nodeIndex);
        this.parkingSpaces.removeChildren(1);
        this.parkingSpaces.addChildren(this.spaces.children);

        for (let i = 0; i < this.nodeGroup.children.length; ++i) {
            this.nodeGroup.children[i].position = this.line.segments[i].point;
        }

        this.positionEllipsis();
    }

    /**
     * Change the stall angle
     * @param angle The new angle
     * @returns Don't execute if line is undefined
     */
    changeStallAngle(angle: number) {
        if (!this.line) return;
        const stallAngleChangeAction = new DiagramElementAction({
            name: "change stall angle",
            elementRef: this
        });
        stallAngleChangeAction.startRecording();
        Annotation.defaultStallAngle = this.stallAngle = angle;
        this.updateSpaces();
        stallAngleChangeAction.stopRecording();

    }

    /**
     * Add node to parking stall line
     */
    addNode() {
        const addNodeAction = new DiagramElementAction({
            name: "add annotation node",
            elementRef: this
        });
        addNodeAction.startRecording();
        // Use add node helper function from LineTool file
        addNode(this.line, this.nodeGroup, true);

        // Update the spaces so that parking appears correctly after adding new node
        this.updateSpaces();
        addNodeAction.stopRecording();

    }
}

export class ParkingStallNode extends AnnotationNode {
    protected applyClassName() {
        this.className = "ParkingStallNode";
    }

    get parentElement(): ParkingStallTool {
        return super.parentElement as ParkingStallTool;
    }

    onMouseDown(e: paper.MouseEvent) {
        if (
            this.entireObject.index > 0 &&
            this.entireObject.index < this.entireObject.parent.lastChild.index
        ) {
            if (this.parentElement.line) {
                this.parentElement.lineEndPoint = new paper.Point(
                    this.parentElement.line.lastSegment.point
                );
            }
        }

        super.onMouseDown(e);
    }

    onMouseDrag = (e: paper.MouseEvent) => {
        const node = e.currentTarget;

        const localEPoint = this.parentElement.line.globalToLocal(e.point);
        this.parentElement.line.segments[node.index].point = localEPoint;
        this.parentElement.line.smooth({ type: "catmull-rom", factor: 0.5 });
        this.parentElement.updateSpaces(node.index);
    };
}

/**
 * Helper function to create spaces
 * @param line
 * @param spaces
 * @param nodeIndex
 * @returns Don't execute if line parameter is undefined
 */
export function createSpaces(
    line: paper.Path,
    spaces: paper.Group,
    nodeIndex = 0
) {
    if (!line) return;

    let dir = nodeIndex ? 1 : 0;

    if (nodeIndex && nodeIndex < line.lastSegment.index) {
        line.lastSegment.point = line.data.endPoint;
    }

    const width =
        ParkingStallTool.parkingSpaceWidth /
        Math.cos(degreesToRadians(line.data.stallAngle));

    spaces.removeChildren();
    const numSpaces = Math.floor(line.length / width);

    if (dir == 1) {
        line.lastSegment.point = line.getPointAt(numSpaces * width);
    } else if (dir == 0) {
        const remainder = line.length % width;
        line.firstSegment.point = line.getPointAt(remainder);
    }

    for (let i = 0; i < numSpaces; ++i) {
        addParkingSpaceLineAt(line, spaces, i * width);
    }

    addParkingSpaceLineAt(line, spaces, line.length);
}

/**
 * Helper function to add parking space line
 * @param line
 * @param spaces
 * @param offset
 */
function addParkingSpaceLineAt(
    line: paper.Path,
    spaces: paper.Group,
    offset: number
) {
    const p1 = line.getPointAt(offset);
    const p2 = p1
        .subtract(
            line
                .getNormalAt(offset)
                .normalize(
                    ParkingStallTool.parkingSpaceLength /
                        Math.cos(degreesToRadians(line.data.stallAngle))
                )
        )
        .rotate(-line.data.stallAngle, p1);
    spaces.addChild(
        new paper.Path.Line({
            from: p1,
            to: p2,
            strokeWidth: 2,
            strokeColor: ParkingStallTool.defaultParkingStallColor
        })
    );
}
