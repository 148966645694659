import * as paper from "paper";
import {
    clearCursor,
    Cursor,
    lockCursor,
    setCursor,
    unlockCursor
} from "../shared/cursor";
import { InsetReference, UIService } from "../shared/services/ui.service";
import {
    disablePage,
    enablePage,
    promptBox,
    startMajorDraw
} from "../page-disabler/disable-enable-page";
// import { loadSVGFromFile } from "./cdt-diagram";
import {
    disableMapDragging,
    enableMapDragging,
    getDiagramZoomScale,
    goHome,
    leafletMap,
    projectPointToLatLng
} from "../shared/diagram-map";
import { DiagramObject } from "../classes/DiagramObject";
import { DiagramLayer, getLayer, lockLayers } from "../shared/layer";
import {
    drawDebugCircle,
    getDiagramBounds,
    getDiagramElementBounds,
    getDiagramOutline,
    getInverseScaling,
    repositionNodes
} from "../shared/helpers";
import Point from "../shared/S4Point";
import { InsetToolComponent } from "../inset-tool/inset-tool.component";
import { LatLng } from "leaflet";
import { toggleButton } from "../control-bar/control-bar-buttons";
import { after } from "node:test";

export const defaultCropPadding = 250;
export const resizeNodeRadius = 8;

const PAD_SIDE = 10;
const PAD_BOTTOM = 47;
const INSET_GAP = 6;

let minCropBounds: paper.Rectangle;
let diagramBounds: paper.Rectangle;
let cropArea: paper.Path;
let grayWall: paper.Path;
let grayOut: paper.PathItem;
let resizeNodes: paper.Group;
let northArrow: paper.Group;
let notDrawnToScaleLegend: paper.Group;
let s4Watermark: paper.Item;
let ui: UIService;
let resolver: Function;
let rejector: Function;
let userSelection: paper.Path;
let isZooming = false;
let savedInsetsLoaded = false;
let _diagramOutline: paper.PathItem;
let diagramOutline: paper.PathItem;

const NODE_POSITIONS = [
    "topLeft",
    "topRight",
    "bottomLeft",
    "bottomRight",
    "leftCenter",
    "topCenter",
    "rightCenter",
    "bottomCenter"
];

function loadMiscIcon(name: string, insert = false) {
    const iconSet = document.querySelector(`#misc-ui`);
    const icon = iconSet?.querySelector(
        `[data-icon-id="${name}"]`
    ) as SVGElement;
    return paper.projects[0].importSVG(icon, { insert: insert });
}

export async function getCroppingRect(
    uiService: UIService
): Promise<paper.Rectangle> {
    // disable anything distracting
    disablePage();
    DiagramObject.deselect();
    lockLayers(DiagramLayer.cropping);

    ui = uiService;
    ui.isDataGridCollapsed = true;
    ui.sidePanelOpen = false;
    ui.gridDisabled = true;
    ui.controlButtonsVisible = false;

    // center the user on the diagram object
    leafletMap.once("moveend", continueSetup);
    goHome(uiService.padding, getDiagramBounds(defaultCropPadding));

    return new Promise<paper.Rectangle>((resolve, reject) => {
        resolver = resolve;
        rejector = reject;
    });
}

function continueSetup() {
    // create the cropping UI
    const northArrowShape = loadMiscIcon("north arrow");
    northArrowShape.bounds = new paper.Rectangle([0, 0], [25, 33]);
    const bgColor = new paper.Color(255, 255, 255, 0.65);
    let bg = new paper.Path.Rectangle({
        size: northArrowShape.bounds.expand(8),
        fillColor: bgColor,
        radius: new paper.Size(5, 5)
    });
    bg.position = northArrowShape.position;
    northArrow = new paper.Group([bg, northArrowShape]);
    northArrow.applyMatrix = false;
    northArrow.pivot = bg.bounds.topRight;
    northArrow.data.isCroppingUi = true;
    northArrow.remove();

    // "Not drawn to scale"
    const notDrawnToScaleText = new paper.PointText({
        content:
            "Disclaimer: background imagery does not\ndepict time of crash. Diagram not to scale.",
        fontWeight: "bold",
        justification: "center"
    });
    bg = new paper.Path.Rectangle({
        size: notDrawnToScaleText.bounds.size.add(5),
        fillColor: bgColor,
        radius: new paper.Size(5, 5)
    });
    bg.position = notDrawnToScaleText.position;
    notDrawnToScaleLegend = new paper.Group([bg, notDrawnToScaleText]);
    notDrawnToScaleLegend.data.isCroppingUi = true;
    notDrawnToScaleLegend.opacity = 0.85;
    notDrawnToScaleLegend.remove();

    // S4 Watermark
    s4Watermark = loadMiscIcon("s4icon");
    s4Watermark.opacity = 0.25;
    s4Watermark.data.isCroppingUi = true;

    northArrow.data.thing = "legend";
    notDrawnToScaleLegend.data.thing = "legend";
    s4Watermark.data.thing = "legend";

    // establish the bounds
    _diagramOutline = getDiagramOutline();
    _diagramOutline.addTo(getLayer(DiagramLayer.cropping));
    updateOutlineWithViewBoxes();
    calculateBounds();
    notDrawnToScaleLegend.bounds.bottomCenter = diagramBounds.bottomCenter;
    getLayer(DiagramLayer.cropping).activate();

    setUpCroppingUI();
    ui.isCropping = true;
    repositionInsets();

    // add necessary event listeners
    leafletMap.on("move", mapMovedUpdate);
    leafletMap.on("zoomend", zoomEndUpdate);
    leafletMap.on("zoomstart", onZoomStart);

    // now we're ready to allow the user to choose the cropping area
    window.addEventListener("keydown", cancelOnEscapeKey);
    enablePage();
}

function cancelOnEscapeKey(e: KeyboardEvent) {
    if (e.key == "Escape") {
        exitCropping(false);
    }
}

export async function exitCropping(accepted: boolean) {
    disablePage();

    if (accepted) {
        if (!paper.projects[0].view.bounds.contains(cropArea.bounds)) {
            // Try centering
            await new Promise<void>((res) => {
                leafletMap.panTo(projectPointToLatLng(cropArea.bounds.center), {
                    animate: false
                });

                setTimeout(() => {
                    res();
                }, 500);
            });

            if (!paper.projects[0].view.bounds.contains(cropArea.bounds)) {
                await helpUserFitBounds();

                return;
            }
        }
    }

    window.removeEventListener("keydown", cancelOnEscapeKey);
    removeCroppingUIAndListeners();

    // the legends must be added to a captured layered, and then removed in the image processing function
    getLayer(DiagramLayer.groupSelection).addChildren([
        northArrow,
        notDrawnToScaleLegend,
        s4Watermark
    ]);

    setTimeout(() => {
        getLayer(DiagramLayer.main).activate();
        if (accepted) {
            resolver(
                new paper.Rectangle(
                    paper.projects[0].view.projectToView(
                        cropArea.bounds.topLeft
                    ),
                    paper.projects[0].view.projectToView(
                        cropArea.bounds.bottomRight
                    )
                )
            );
        } else {
            rejector("canceled");
        }

        enablePage();
    }, 50);
}

async function helpUserFitBounds() {
    // let offerFullscreen = false;

    // if (
    //     document.fullscreenEnabled &&
    //     new paper.Rectangle(
    //         [0, 0],
    //         [screen.availWidth, screen.availHeight]
    //     ).contains(cropArea.bounds)
    // ) {
    //     const diagram = document.getElementById("map");
    //     await diagram?.requestFullscreen({ navigationUI: "hide" });
    //     await exitCropping(true);
    //     document.exitFullscreen();
    // }

    await promptBox(
        "Diagram Bounds Outside Window",
        `The diagram's bounds must be fully contained in the window.
        Please maximize the window, then click "Continue." S4 Diagram will attempt to fit the bounds.
        Afterwards, make any adjustments and click "Done" again to finish.`,
        ["Continue"]
    );

    goHome(ui.padding, getDiagramBounds());
    enablePage();
}

function removeCroppingUIAndListeners() {
    getLayer(DiagramLayer.cropping).removeChildren();
    leafletMap.off("move", mapMovedUpdate);
    leafletMap.off("zoomend", zoomEndUpdate);
    leafletMap.off("zoomstart", onZoomStart);
    ui.gridDisabled = false;
    if (ui.helpBar.isShown()) {
        ui.helpBar.close();
        setTimeout(() => {
            ui.controlButtonsVisible = true;
        }, 500);
    } else {
        ui.controlButtonsVisible = true;
    }
    getLayer(DiagramLayer.main).activate();
}

function addResizeNode(rect: paper.Path, pos: string): paper.Path {
    const rectBounds = rect.bounds;
    const node = new paper.Path.Circle({
        center: rectBounds[pos],
        radius: resizeNodeRadius,
        fillColor: "#fefefe",
        strokeWidth: 1,
        name: pos,
        data: { thing: "resize node", position: pos, isCroppingUi: true },
        applyMatrix: false,
        scaling: getInverseScaling()
    });

    switch (pos) {
        case "topLeft":
            node.data.controls = ["top", "left"];
            node.data.cursor = Cursor.NWSE_Resize;
            break;
        case "topRight":
            node.data.controls = ["top", "right"];
            node.data.cursor = Cursor.NESW_Resize;
            break;
        case "bottomLeft":
            node.data.controls = ["bottom", "left"];
            node.data.cursor = Cursor.NESW_Resize;
            break;
        case "bottomRight":
            node.data.controls = ["bottom", "right"];
            node.data.cursor = Cursor.NWSE_Resize;
            break;
        case "leftCenter":
            node.data.controls = ["left"];
            node.data.cursor = Cursor.EW_Resize;
            break;
        case "topCenter":
            node.data.controls = ["top"];
            node.data.cursor = Cursor.NS_Resize;
            break;
        case "rightCenter":
            node.data.controls = ["right"];
            node.data.cursor = Cursor.EW_Resize;
            break;
        case "bottomCenter":
            node.data.controls = ["bottom"];
            node.data.cursor = Cursor.NS_Resize;
            break;
    }
    node.onMouseEnter = () => {
        setCursor(node.data.cursor);
        node.scaling = getInverseScaling().multiply(1.5);
        node.strokeColor = new paper.Color("#fefefe");
        node.fillColor = new paper.Color("#3d8aff");
    };
    node.onMouseLeave = () => {
        clearCursor();
        node.scaling = getInverseScaling();
        node.strokeColor = null;
        node.fillColor = new paper.Color("#fefefe");
    };
    node.onMouseDrag = resizeNodeDrag;
    node.onMouseDown = () => {
        lockCursor();
        leafletMap.dragging.disable();
        calculateBounds();
    };
    node.onMouseUp = (e: paper.MouseEvent) => {
        unlockCursor();
        if (!node.contains(e.point)) clearCursor();
        leafletMap.dragging.enable();
    };

    return node;
}

function getMinSides() {
    const zoomScale = getDiagramZoomScale();

    const minBottom =
        diagramBounds.bottom + notDrawnToScaleLegend.bounds.height + 10;
    let minTop = diagramBounds.top - 5 / zoomScale;
    let minWidth = diagramBounds.width + 10 / zoomScale;
    const minHeight = minBottom - minTop;

    const minRect = new paper.Rectangle([0, 0], [minWidth, minHeight]);
    minRect.center = diagramBounds.center;

    return [minRect.left, minTop, minRect.right, minBottom];
}

function getMinBounds() {
    const [minLeft, minTop, minRight, minBottom] = getMinSides();
    return new paper.Rectangle(
        new paper.Point(minLeft, minTop),
        new paper.Point(minRight, minBottom)
    );
}

function resizeNodeDrag(e: paper.MouseEvent) {
    const oldRect = userSelection.bounds.clone();

    const [minLeft, minTop, minRight, minBottom] = [
        minCropBounds.left,
        minCropBounds.top,
        minCropBounds.right,
        minCropBounds.bottom
    ];

    switch (e.currentTarget.data.position) {
        case "topLeft":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(
                    Math.min(e.point.x, minLeft),
                    Math.min(e.point.y, minTop)
                ),
                oldRect.bottomRight
            );
            // userSelection.bounds.height = cropArea.bounds.height;
            // userSelection.bounds.width = cropArea.bounds.width;
            // userSelection.bounds.top = cropArea.bounds.top;
            // userSelection.bounds.left = cropArea.bounds.left;

            break;
        case "topRight":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(
                    Math.max(e.point.x, minRight),
                    Math.min(e.point.y, minTop)
                ),
                oldRect.bottomLeft
            );

            // userSelection.bounds.height = cropArea.bounds.height;
            // userSelection.bounds.width = cropArea.bounds.width;
            // userSelection.bounds.top = cropArea.bounds.top;

            break;
        case "bottomLeft":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(
                    Math.min(e.point.x, minLeft),
                    Math.max(e.point.y, minBottom)
                ),
                oldRect.topRight
            );

            // userSelection.bounds.height = cropArea.bounds.height;
            // userSelection.bounds.width = cropArea.bounds.width;
            // userSelection.bounds.left = cropArea.bounds.left;

            break;
        case "bottomRight":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(
                    Math.max(e.point.x, minRight),
                    Math.max(e.point.y, minBottom)
                ),
                oldRect.topLeft
            );

            // userSelection.bounds.height = cropArea.bounds.height;
            // userSelection.bounds.width = cropArea.bounds.width;

            break;
        case "leftCenter":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(Math.min(e.point.x, minLeft), oldRect.top),
                oldRect.bottomRight
            );

            // userSelection.bounds.width = cropArea.bounds.width;
            // userSelection.bounds.left = cropArea.bounds.left;

            break;
        case "topCenter":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(oldRect.left, Math.min(e.point.y, minTop)),
                oldRect.bottomRight
            );

            // userSelection.bounds.height = cropArea.bounds.height;
            // userSelection.bounds.top = cropArea.bounds.top;

            break;
        case "rightCenter":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(Math.max(e.point.x, minRight), oldRect.top),
                oldRect.bottomLeft
            );

            // userSelection.bounds.width = cropArea.bounds.width;

            break;
        case "bottomCenter":
            cropArea.bounds = new paper.Rectangle(
                new paper.Point(oldRect.left, Math.max(e.point.y, minBottom)),
                oldRect.topRight
            );

            // userSelection.bounds.height = cropArea.bounds.height;

            break;
    }

    if (!ui.insets.length) {
        if (!positionLegends()) afterResizeUpdate();
        userSelection.bounds = cropArea.bounds.clone();
    } else {
        userSelection.bounds = cropArea.bounds.clone();
        repositionInsets();
    }
}

function resizeGrayZone() {
    grayOut.remove();
    grayOut = grayWall.subtract(cropArea);
    grayOut.fillColor = new paper.Color(0, 0, 0, 0.75);
    grayOut.data.isCroppingUi = true;
}

async function addResizeNodeGroup() {
    resizeNodes = new paper.Group([
        ...NODE_POSITIONS.map((np) => addResizeNode(cropArea, np))
    ]);
    resizeNodes.data.isCroppingUi = true;
}

function calculateBounds() {
    diagramBounds = getDiagramElementBounds({ isViewBox: true });
    minCropBounds = getMinBounds();
}

async function setUpCroppingUI() {
    let boundsToUse = (minCropBounds =
        getDiagramElementBounds().expand(defaultCropPadding));

    cropArea = new paper.Path.Rectangle(minCropBounds);
    cropArea.strokeWidth = 3;
    cropArea.strokeColor = new paper.Color("white");
    cropArea.data.thing = "crop area";
    cropArea.data.isCroppingUi = true;

    grayWall = new paper.Path.Rectangle(paper.projects[0].view.bounds);
    grayOut = grayWall.subtract(cropArea);
    grayOut.fillColor = new paper.Color(0, 0, 0, 0.75);
    grayOut.data.isCroppingUi = true;

    const croppingLayer = getLayer(DiagramLayer.cropping);

    croppingLayer.addChild(northArrow);
    croppingLayer.addChild(notDrawnToScaleLegend);
    croppingLayer.addChild(s4Watermark);

    addResizeNodeGroup();
    fixUISize();

    const savedCropArea = getLayer(DiagramLayer.main).getItem({
        name: "user selection"
    });

    if (savedCropArea) {
        userSelection = savedCropArea as paper.Path;
        if (savedCropArea.bounds.contains(minCropBounds)) {
            boundsToUse = savedCropArea.bounds.clone();
        } else {
            savedCropArea.bounds = boundsToUse;
        }
    }

    cropArea.bounds = boundsToUse;
    if (!positionLegends()) afterResizeUpdate();

    if (!savedCropArea) {
        userSelection = cropArea.clone({ insert: false });
        userSelection.visible = false;
        userSelection.addTo(getLayer(DiagramLayer.main));
        userSelection.name = "user selection";
    }

    if (!savedInsetsLoaded) {
        const savedInsets = paper.projects[0].getItems({
            data: { isViewBox: true }
        });
        savedInsets.sort((a, b) => a.data.insetNumber - b.data.insetNumber);
        for (const inset of savedInsets) {
            ui.insets.push({ window: inset.data.window });
        }
        savedInsetsLoaded = true;
    }

    ui.helpBar.show(
        "Select the desired diagram bounds by resizing the cropping rectangle and click Done to finish.",
        5000
    );
}

function mapMovedUpdate() {
    if (!grayWall.bounds.contains(paper.projects[0].view.bounds)) {
        grayWall.bounds = grayWall.bounds.unite(paper.projects[0].view.bounds);
        resizeGrayZone();
    }

    if (!isZooming) {
        repositionInsets();
    }
}

function onZoomStart() {
    paper.view.autoUpdate = false;
    isZooming = true;
}

function zoomEndUpdate() {
    restoreSelectedBounds();
    fixUISize();
    repositionInsets();
    positionLegends();
    afterResizeUpdate();
    paper.view.autoUpdate = true;
    isZooming = false;
}

function fixUISize() {
    const zoomScale = getDiagramZoomScale();
    cropArea.strokeWidth = 3 / zoomScale;
    for (const node of resizeNodes.children) {
        node.scaling = getInverseScaling(zoomScale);
    }
    sizeLegends(zoomScale);
    updateOutlineWithViewBoxes();
    calculateBounds();
    if (!cropArea.bounds.contains(minCropBounds)) {
        cropArea.bounds = cropArea.bounds.unite(minCropBounds);
    }
    positionLegends(zoomScale);
}

function afterResizeUpdate(zoomScale = getDiagramZoomScale()) {
    northArrow.bounds.topRight = cropArea.bounds.topRight.add(
        new paper.Point(-5 / zoomScale, 5 / zoomScale)
    );
    s4Watermark.bounds.topLeft = cropArea.bounds.topLeft.add(
        new paper.Point(4 / zoomScale, 4 / zoomScale)
    );
    notDrawnToScaleLegend.bounds.bottomCenter =
        cropArea.bounds.bottomCenter.add(new paper.Point(0, -5));
    for (const node of resizeNodes.children) {
        node.position = cropArea.bounds[node.data.position];
    }
    resizeGrayZone();
}

function sizeLegends(scale?: number) {
    if (!scale) scale = getDiagramZoomScale();
    northArrow.scaling = getInverseScaling(scale);
    notDrawnToScaleLegend.fitBounds(
        new paper.Rectangle(0, 0, 275, 45).scale(1 / scale)
    );
    s4Watermark.fitBounds(new paper.Rectangle(0, 0, 40, 40).scale(1 / scale));
}

export function updateOutlineWithViewBoxes() {
    diagramOutline?.remove();
    diagramOutline = _diagramOutline.clone({ insert: false });

    for (const inset of ui.insets) {
        if (inset.component) {
            diagramOutline = diagramOutline.unite(
                inset.component.viewBox.firstChild.clone({
                    insert: false
                }) as paper.Path,
                { insert: false }
            );
            const numberBox = inset.component.viewBox.lastChild.firstChild;
            const numberBoxOutline = numberBox.clone({
                insert: false
            }) as paper.Path;
            numberBoxOutline.scale(1 / getDiagramZoomScale());
            numberBoxOutline.position = numberBox.parent.localToGlobal(
                numberBox.position
            );
            diagramOutline = diagramOutline.unite(numberBoxOutline, {
                insert: false
            });
        }
    }

    diagramOutline.addTo(getLayer(DiagramLayer.cropping));
}

function positionLegends(zoomScale?: number) {
    if (!zoomScale) zoomScale = getDiagramZoomScale();

    notDrawnToScaleLegend.bounds.bottomCenter =
        cropArea.bounds.bottomCenter.add(new paper.Point(0, -5));

    let resized = false;

    if (!cropArea.bounds.contains(notDrawnToScaleLegend.bounds)) {
        cropArea.bounds = cropArea.bounds.unite(notDrawnToScaleLegend.bounds);
        resized = true;
    }

    northArrow.position = cropArea.bounds.topRight.add(
        new paper.Point(-5 / zoomScale, 5 / zoomScale)
    );
    s4Watermark.bounds.topLeft = cropArea.bounds.topLeft.add(
        new paper.Point(4 / zoomScale, 4 / zoomScale)
    );

    const watermarkOverlap = s4Watermark.bounds.bottomRight.subtract(
        diagramOutline.getNearestPoint(diagramOutline.bounds.topLeft)
    );

    let yShift = 0;

    if (watermarkOverlap.x > 0 && watermarkOverlap.y > 0) {
        if (watermarkOverlap.x < watermarkOverlap.y) {
            const diff = watermarkOverlap.x + 2 / zoomScale;
            cropArea.bounds.left -= diff;
            cropArea.bounds.width += diff;
        } else {
            const diff = watermarkOverlap.y + 2 / zoomScale;
            cropArea.bounds.top -= diff;
            cropArea.bounds.height += diff;
            yShift = diff;
        }

        resized = true;
    }

    const northArrowOverlap = northArrow.bounds.bottomLeft.subtract(
        diagramOutline.getNearestPoint(diagramOutline.bounds.topRight)
    );

    if (northArrowOverlap.x < 0 && northArrowOverlap.y > 0) {
        if (Math.abs(northArrowOverlap.x) < northArrowOverlap.y) {
            cropArea.bounds.width -= northArrowOverlap.x - 4 / zoomScale;
        } else {
            const diff = northArrowOverlap.y + 4 / zoomScale - yShift;
            cropArea.bounds.top -= diff;
            cropArea.bounds.height += diff;
        }

        resized = true;
    }

    if (resized) {
        afterResizeUpdate();
    }

    return resized;
}

export function toggleAddInset() {
    const toggled = toggleButton("Inset");
    if (toggled) {
        ui.helpBar.show(
            "Click to start drawing inset's bounds. Click again to accept.",
            10000
        );
        paper.view.on("mousedown", startInset);
        paper.view.element.style.cursor = "crosshair";
        window.removeEventListener("keydown", cancelOnEscapeKey);
        window.addEventListener("keydown", escKeyCancelInset);
        lockLayers();
    } else {
        cancelInset();
    }
}

function escKeyCancelInset(e: KeyboardEvent) {
    if (e.key === "Escape") {
        toggleAddInset();
    }
}

let insetRect: paper.Item;
let insetStartPoint: paper.Point;

function startInset(e: paper.MouseEvent) {
    const zoomScale = getDiagramZoomScale();
    insetStartPoint = e.point; //.subtract(InsetToolComponent.MIN_DIM / 2);
    insetRect = new paper.Path.Rectangle(
        insetStartPoint,
        e.point.add(InsetToolComponent.MIN_DIM / 2)
    );
    insetRect.strokeColor = new paper.Color("black");
    insetRect.strokeWidth = 2 / zoomScale;
    paper.view.off("mousedown", startInset);
    window.addEventListener("mousemove", drawInset);
    window.addEventListener("mousedown", finishInset);
    e.stop();
    disableMapDragging();
}

function drawInset(e: MouseEvent) {
    e.stopPropagation();
    const point = paper.projects[0].view.viewToProject(
        new paper.Point(e.x, e.y - 66)
    );
    openInsetToPoint(point);
}

function openInsetToPoint(point: paper.Point) {
    const newBounds = new paper.Rectangle(insetStartPoint, point);
    const minDim = InsetToolComponent.MIN_DIM;
    if (newBounds.width <= minDim) newBounds.width = minDim;
    else if (newBounds.width > InsetToolComponent.MAX_DIM) {
        newBounds.width = InsetToolComponent.MAX_DIM;
    }
    if (newBounds.height <= minDim) newBounds.height = minDim;
    else if (newBounds.height > InsetToolComponent.MAX_DIM) {
        newBounds.height = InsetToolComponent.MAX_DIM;
    }
    if (point.x < insetStartPoint.x) {
        newBounds.point.x = insetStartPoint.x - newBounds.width;
    }
    if (point.y < insetStartPoint.y) {
        newBounds.point.y = insetStartPoint.y - newBounds.height;
    }
    insetRect.bounds = newBounds;
}

function finishInset(e: MouseEvent) {
    window.removeEventListener("mousemove", drawInset);
    openInsetToPoint(
        paper.projects[0].view.viewToProject(new paper.Point(e.x, e.y - 66))
    );
    addInset(
        projectPointToLatLng(insetRect.bounds.center),
        insetRect.bounds.width,
        insetRect.bounds.height
    );
    toggleAddInset();
}

function cancelInset() {
    insetRect?.remove();
    window.removeEventListener("keydown", escKeyCancelInset);
    paper.view.off("mousedown", startInset);
    window.removeEventListener("mousedown", finishInset);
    window.removeEventListener("mousemove", drawInset);
    paper.view.element.style.removeProperty("cursor");
    // add back the canceling of cropping mode
    window.addEventListener("keydown", cancelOnEscapeKey);
    getLayer(DiagramLayer.cropping).locked = false;
    enableMapDragging();
}

export function addInset(latLng: LatLng, width: number, height: number) {
    ui.insets.push({
        window: {
            latLng: latLng,
            point: new Point(0, 0),
            width: width,
            height: height,
            zoom: 21
        }
    });
}

function getInsetColumnsAndHeight(availHeight: number): {
    minHeight: number;
    col1: InsetReference[];
    col2: InsetReference[];
} {
    const insets = [...ui.insets].reverse();
    const paddedHeights = insets.map(
        (inset) => inset.window.height + INSET_GAP
    ); // Add padding to each rectangle's height
    const totalHeight = paddedHeights.reduce((acc, h) => acc + h, 0); // Total sum of all heights

    // Function to check if we can fit rectangles in two columns with height limit H
    function canFitInHeight(
        H: number
    ): { col1: InsetReference[]; col2: InsetReference[] } | null {
        let col1Height = 0;
        let col2Height = 0;
        const col1: InsetReference[] = [];
        const col2: InsetReference[] = [];
        let fillingCol2 = false;

        for (let i = 0; i < paddedHeights.length; i++) {
            const rectHeight = paddedHeights[i];
            if (!fillingCol2 && col1Height + rectHeight <= H) {
                col1.push(insets[i]); // Add to Column 1
                col1Height += rectHeight;
            } else {
                fillingCol2 = true;
                if (col2Height + rectHeight <= H) {
                    col2.push(insets[i]); // Add to Column 2
                    col2Height += rectHeight;
                } else {
                    return null; // We exceeded the height H in both columns
                }
            }
        }

        return { col1, col2 };
    }

    const noChangeColumns = canFitInHeight(availHeight);
    if (noChangeColumns) return { ...noChangeColumns, minHeight: availHeight };

    // Binary search to find the minimum possible height
    let low = Math.max(...paddedHeights); // Minimum possible height is the tallest rectangle
    let high = totalHeight; // Maximum possible height is the sum of all rectangles

    let result = {
        col1: [insets[0]] as InsetReference[],
        col2: [] as InsetReference[],
        minHeight: high
    };

    while (low <= high) {
        const mid = Math.floor((low + high) / 2);
        const columns = canFitInHeight(mid);

        if (columns) {
            result = { ...columns, minHeight: mid }; // Found a solution, try for smaller height
            high = mid - 1;
        } else {
            low = mid + 1; // Try larger height
        }
    }

    return result;
}

export function restoreSelectedBounds() {
    if (!userSelection) return;
    calculateBounds();
    cropArea.bounds = userSelection.bounds.clone().unite(minCropBounds);
    positionLegends();
    afterResizeUpdate();
}

function getMinNecessaryBounds() {
    calculateBounds();
    const zoomScale = getDiagramZoomScale();
    const minBounds = userSelection.bounds.unite(minCropBounds);
    if (!ui.insets.length) return [minBounds];

    const availHeight =
        (minBounds.height -
            notDrawnToScaleLegend.bounds.height -
            northArrow.bounds.height) *
            zoomScale -
        11;

    const insetColumns = getInsetColumnsAndHeight(availHeight);
    const [heightNeeded, rightInsets, leftInsets] = [
        insetColumns.minHeight,
        insetColumns.col1,
        insetColumns.col2
    ];

    if (availHeight < heightNeeded) {
        const diff = (heightNeeded - availHeight) / zoomScale;
        minBounds.height += diff;
        minBounds.top -= diff;
    }

    const widestRight = Math.max(...rightInsets.map((v) => v.window.width));
    const widestLeft = Math.max(...leftInsets.map((v) => v.window.width));
    const diagramBotRight = paper.projects[0].view.projectToView(
        diagramBounds.bottomRight
    );
    const diagramBotLeft = paper.projects[0].view.projectToView(
        diagramBounds.bottomLeft
    );
    let [boundsBotRight, boundsBotLeft] = getBotCornersInViewCoords(minBounds);

    const asCloseToDiagramAsPossibleOnRight =
        diagramBotRight.x + widestRight + 5;
    if (asCloseToDiagramAsPossibleOnRight > boundsBotRight.x - PAD_SIDE) {
        const diff =
            (asCloseToDiagramAsPossibleOnRight + PAD_SIDE - boundsBotRight.x) /
            zoomScale;
        minBounds.width += diff;
    }

    if (leftInsets.length) {
        const asCloseToDiagramAsPossibleOnLeft =
            diagramBotLeft.x - widestLeft - 5;
        if (asCloseToDiagramAsPossibleOnLeft < boundsBotLeft.x + PAD_SIDE) {
            const diff =
                (boundsBotLeft.x +
                    PAD_SIDE -
                    asCloseToDiagramAsPossibleOnLeft) /
                zoomScale;
            minBounds.width += diff;
            minBounds.left -= diff;
        }
    }

    return [minBounds, rightInsets, leftInsets];
}

export function repositionInsets() {
    if (!ui.insets.length) return;

    const [necessaryBounds, rightInsets, leftInsets] =
        getMinNecessaryBounds() as [
            paper.Rectangle,
            InsetReference[],
            InsetReference[]
        ];

    cropArea.bounds = necessaryBounds;
    if (!positionLegends()) afterResizeUpdate();

    const [botRight, botLeft] = getBotCornersInViewCoords(cropArea.bounds);

    const point = new paper.Point(
        botRight.x - PAD_SIDE,
        botRight.y - PAD_BOTTOM
    );

    for (const inset of rightInsets) {
        const window = inset.window;
        const insetPoint = new Point(
            point.x - window.width,
            point.y - window.height
        );
        window.point = insetPoint;
        point.y -= window.height + INSET_GAP;
        inset.component?.changeDetector.detectChanges();
    }

    if (leftInsets.length) {
        point.x = botLeft.x + PAD_SIDE - 4;
        point.y = botLeft.y - PAD_BOTTOM;

        for (const inset of leftInsets) {
            const window = inset.window;
            const insetPoint = new Point(point.x, point.y - window.height);
            window.point = insetPoint;
            point.y -= window.height + INSET_GAP;
            inset.component?.changeDetector.detectChanges();
        }
    }
}

function getBotCornersInViewCoords(bounds: paper.Rectangle) {
    return [
        paper.projects[0].view.projectToView(bounds.bottomRight),
        paper.projects[0].view.projectToView(bounds.bottomLeft)
    ];
}
