import { NUM_OF_ANCHORS, Participant } from "./Participant";
import * as paper from "paper";
import { DiagramElement } from "./DiagramElement";
import { colorCodeRGB } from "../shared/icon";
// import { ControlNode } from "./ControlNode";
import {
    Cursor,
    clearCursor,
    cursorIsLocked,
    lockCursor,
    setCursor,
    unlockCursor
} from "../shared/cursor";
import { disableMapDragging, enableMapDragging } from "../shared/diagram-map";
import { controlKeyPressed } from "../shared/global-event-states";
import { DiagramService } from "../shared/services/diagram.service";
import { ControlNode } from "./ControlNode";
import {
    DiagramAction,
    DiagramElementAction,
    PropTrackingAction
} from "../shared/Action";

export class ArrowNode extends ControlNode {
    protected applyClassName() {
        this.className = "ArrowNode";
    }
    static nodeBeneathCursor?: ArrowNode;

    constructor(entireObject: paper.Item);
    constructor(point: paper.Point, additionalAttributes?: any);
    constructor(pointOrEntireObject: any, additionalAttributes?: any) {
        super(
            pointOrEntireObject,
            additionalAttributes || {
                fillColor: null,
                strokeWidth: 3
            }
        );
    }

    get parentElement() {
        return DiagramService.getById(
            this.entireObject.parent.parent.data.referenceId
        ) as Arrow;
    }

    get arrow() {
        return this.parentElement;
    }

    get segment() {
        return this.arrow.line.segments[this.entireObject.index + 1];
    }

    addTo(group: paper.Group | paper.Item, index?: number | undefined): void {
        super.addTo(group, index);
        this.entireObject.fillColor = new paper.Color(
            colorCodeRGB[this.arrow.color]
        );
    }

    setUpEventHandlers(): void {
        const rightClickDelete = (e: MouseEvent) => {
            if (e.button == 2) {
                this.remove();
            }
        };

        this.entireObject.onMouseDown = (e: paper.MouseEvent) => {
            this.arrow.grabbedIndex = this.entireObject.index + 1;
            this.arrow.nodeWasClicked = true;
            window.addEventListener("mousedown", rightClickDelete, {
                once: true
            });
        };

        this.entireObject.onMouseEnter = () => {
            this.arrow.isOverNode = true;
            this.arrow.phantomNode.visible = false;
            ArrowNode.nodeBeneathCursor = this;
        };

        this.entireObject.onMouseLeave = () => {
            this.arrow.isOverNode = false;
            ArrowNode.nodeBeneathCursor = undefined;
        };
    }

    remove(): void {
        DiagramAction.currentAction?.cancel();
        this.entireObject.onMouseEnter = null;
        this.entireObject.onMouseLeave = null;

        const arrow = this.arrow;
        arrow.line.removeSegment(this.entireObject.index + 1);
        super.remove();
        arrow.resetStrokeWidth();
        arrow.isBending = false;
        arrow.isOverNode = false;
        arrow.nodeWasClicked = false;
        arrow.nodeGroup.visible = false;
        arrow.entireObject.onMouseDrag = null;
        arrow.update();

        unlockCursor();
        clearCursor();

        enableMapDragging();
    }
}

export class PhantomNode extends ArrowNode {
    protected applyClassName(): void {
        this.className = "PhantomNode";
    }

    constructor(entireObject: paper.Item);
    constructor(point: paper.Point);
    constructor(pointOrEntireObject: any) {
        if (pointOrEntireObject instanceof paper.Path.Circle) {
            super(pointOrEntireObject);
            return;
        }

        super(pointOrEntireObject, {
            fillColor: new paper.Color("white"),
            strokeColor: null,
            name: "phantom node",
            opacity: 0.5,
            visible: false,
            locked: true
        });
    }

    get parentElement() {
        return DiagramService.getById(
            this.entireObject.parent.data.referenceId
        ) as Arrow;
    }

    addTo(group: paper.Group | paper.Item, index?: number | undefined): void {
        super.addTo(group, index);
        this.entireObject.strokeColor = new paper.Color(
            colorCodeRGB[this.arrow.color]
        );
    }

    setUpEventHandlers(): void {} // purposely empty
}

export class Arrow extends DiagramElement {
    protected applyClassName() {
        this.className = "Arrow";
    }
    private static alreadyUpdated = new Set<string>();
    private static trackAlreadyUpdated = false;

    private readonly DEFAULT_ARROW_WIDTH = 3;
    private readonly HIGHLIGHTED_ARROW_WIDTH = 6;
    private readonly ARROW_FORCE_DIVISOR = 3;

    static trackUpdates(initialSet?: Arrow[]) {
        this.trackAlreadyUpdated = true;
        initialSet?.forEach((a) => this.alreadyUpdated.add(a.id));
    }

    static untrackUpdates() {
        this.trackAlreadyUpdated = false;
        this.alreadyUpdated.clear();
    }

    get line(): paper.Path {
        return this.entireObject.children["line"];
    }

    get arrowhead(): paper.Path {
        return this.entireObject.children["arrow head"];
    }

    get phantomNode(): PhantomNode {
        return DiagramService.getById(
            this.entireObject.children["phantom node"].data.referenceId
        ) as PhantomNode;
    }

    get nodeGroup() {
        return this.entireObject.children["nodes"] as paper.Group;
    }

    get origin() {
        return DiagramService.getById(
            this.entireObject.data.fromId
        ) as Participant;
    }

    set origin(p: Participant) {
        this.entireObject.data.fromId = p.id;
        p.outArrow = this;
    }

    get target() {
        return DiagramService.getById(
            this.entireObject.data.toId
        ) as Participant;
    }

    set target(p: Participant) {
        this.entireObject.data.toId = p.id;
        p.inArrow = this;
    }

    get color(): string {
        return this.origin.arrowColor ?? this.origin.color;
    }

    set color(colorCode: string | null) {
        const color = colorCode
            ? new paper.Color(colorCodeRGB[colorCode])
            : null;
        this.entireObject.strokeColor = color;
        this.nodeGroup.strokeColor = color;
        this.nodeGroup.fillColor = color;
    }

    get isOverNode() {
        return this.entireObject.data.isOverNode;
    }

    set isOverNode(state: boolean) {
        this.entireObject.data.isOverNode = state;
    }

    get isBending() {
        return this.entireObject.data.isBending;
    }

    set isBending(state: boolean) {
        this.entireObject.data.isBending = state;
    }

    get isMovingAnchor(): boolean {
        return this.entireObject.data.isMovingAnchor;
    }

    set isMovingAnchor(state: boolean) {
        this.entireObject.data.isMovingAnchor = state;
    }

    constructor(arrowGroup: paper.Item);
    constructor(from: Participant, to: Participant, dashed: boolean);
    constructor(
        fromParticipantOrArrowGroup: Participant | paper.Item,
        to?: Participant,
        dashed = false
    ) {
        let entireObject: paper.Group;
        let isNewArrow: boolean;

        if (fromParticipantOrArrowGroup instanceof paper.Group) {
            // in this case we're loading an existing arrow from database JSON
            isNewArrow = false;
            entireObject = fromParticipantOrArrowGroup;
        } else {
            isNewArrow = true;
            // new arrow between from and to
            const line = new paper.Path({
                segments: [new paper.Segment(), new paper.Segment()],
                name: "line"
            });
            const head = new paper.Path({
                segments: [
                    new paper.Segment(),
                    new paper.Segment(),
                    new paper.Segment()
                ],
                name: "arrow head"
            });

            entireObject = new paper.Group([
                line,
                head,
                new paper.Group({ name: "nodes" })
            ]);
        }

        super(entireObject);

        if (!isNewArrow) {
            return;
        } else if (isNewArrow && to) {
            this.origin = fromParticipantOrArrowGroup as Participant;
            this.target = to;

            this.color = this.origin.arrowColor ?? this.origin.color;

            if (dashed) {
                this.entireObject.firstChild.dashArray = [12, 5];
                this.entireObject.firstChild.data.dashable = true;
            }

            const phantomNode = new PhantomNode(this.entireObject.position);
            phantomNode.addTo(this.entireObject, 2);
            this.phantomNode.entireObject.strokeWidth =
                this.DEFAULT_ARROW_WIDTH;

            this.resetStrokeWidth();
            this.entireObject.sendToBack(); // don't want it covering up labels
            this.update();
        } else {
            throw new Error("Improper arguments for Arrow constructor");
        }
    }

    *controlNodes() {
        for (const node of this.nodeGroup.children) {
            yield DiagramService.getById(node.data.referenceId) as ArrowNode;
        }
    }

    resetStrokeWidth() {
        this.line.strokeWidth = this.arrowhead.strokeWidth =
            this.DEFAULT_ARROW_WIDTH;
    }

    setUpEventHandlers(): void {
        this.entireObject.onMouseEnter = (e: paper.MouseEvent) => {
            if (cursorIsLocked) return;

            this.entireObject.bringToFront();
            this.line.strokeWidth = this.arrowhead.strokeWidth =
                this.HIGHLIGHTED_ARROW_WIDTH;
            this.nodeGroup.visible = true;
            const nearAnchor =
                e.point.getDistance(this.line.firstSegment.point) < 10 ||
                e.point.getDistance(this.line.lastSegment.point) < 10;

            if (nearAnchor) setCursor(Cursor.Move);
            else if (!this.isMovingAnchor) {
                if (this.isOverNode) {
                    setCursor(Cursor.NESW_Resize);
                } else {
                    setCursor(Cursor.Copy);
                    if (!this.isBending) {
                        this.phantomNode.position = e.point;
                        this.phantomNode.position = this.line.getNearestPoint(
                            e.point
                        );
                        this.phantomNode.visible = true;
                    }
                }
            }
        };

        this.entireObject.onMouseMove = (e: paper.MouseEvent) => {
            if (cursorIsLocked) return;

            this.phantomNode.position = this.line.getNearestPoint(e.point);

            const nearAnchor =
                e.point.getDistance(this.line.firstSegment.point) < 10 ||
                e.point.getDistance(this.line.lastSegment.point) < 10;

            if (nearAnchor && !this.isBending) {
                setCursor(Cursor.Move);
                this.phantomNode.visible = false;
            } else if (!this.isMovingAnchor) {
                if (this.isOverNode) {
                    setCursor(Cursor.NESW_Resize);
                } else {
                    setCursor(Cursor.Copy);
                    this.phantomNode.visible = true;
                }
            }
        };

        this.entireObject.onMouseLeave = (e: paper.MouseEvent) => {
            if (!this.isBending && !this.isMovingAnchor) {
                clearCursor();
                this.resetStrokeWidth();
                this.nodeGroup.visible = false;
                this.entireObject.sendToBack();
            }

            this.phantomNode.visible = false;
        };

        this.entireObject.onMouseDown = (e: paper.MouseEvent) => {
            lockCursor();
            // Make sure undo doesn't save highlighted line ======
            paper.projects[0].view.autoUpdate = false;
            const priorWidth = this.line.strokeWidth;
            this.resetStrokeWidth();
            this.nodeGroup.visible = false;
            this.phantomNode.visible = false;
            this.nodeGroup.visible = true;
            this.line.strokeWidth = this.arrowhead.strokeWidth = priorWidth;
            paper.projects[0].view.autoUpdate = true;
            // ===================================================
            disableMapDragging();

            const bendAction = new PropTrackingAction<paper.Point>({
                name: "bend arrow",
                elementRef: this,
                propComparator(a, b) {
                    return a.equals(b);
                },
                propKey: "grabbedSegment.point"
            });

            const moveAnchorAction = new PropTrackingAction<number>({
                name: "move arrow anchor",
                elementRef: this,
                propKey: "draggedAnchorPoint"
            });

            let arrowAction: DiagramElementAction | undefined = undefined;

            if (!this.isOverNode) {
                if (e.point.getDistance(this.line.firstSegment.point) < 10) {
                    arrowAction = moveAnchorAction;
                    this.isMovingAnchor = true;
                    this.draggedAnchor = "outAnchor";
                    this.entireObject.on("mousedrag", this.dragAnchor);
                } else if (
                    e.point.getDistance(this.line.lastSegment.point) < 10
                ) {
                    arrowAction = moveAnchorAction;
                    this.isMovingAnchor = true;
                    this.draggedAnchor = "inAnchor";
                    this.entireObject.on("mousedrag", this.dragAnchor);
                } else {
                    this.isBending = true;
                    this.entireObject.onMouseDrag = this.dragBend;

                    if (!this.nodeWasClicked) {
                        const curveLoc = this.line.getNearestLocation(e.point);
                        this.grabbedIndex = this.line.divideAt(curveLoc).index;
                        this.update();
                        setCursor(Cursor.NESW_Resize, true);
                    }

                    arrowAction = bendAction;
                }
            } else {
                arrowAction = bendAction;
                this.isBending = true;
                this.entireObject.onMouseDrag = this.dragBend;
            }

            arrowAction?.startRecording();
        };

        this.entireObject.onMouseUp = (e: paper.MouseEvent) => {
            unlockCursor();
            this.entireObject.off("mousedrag", this.dragBend);
            this.entireObject.off("mousedrag", this.dragAnchor);
            if (!controlKeyPressed) enableMapDragging();

            clearCursor();
            this.resetStrokeWidth();
            this.nodeGroup.visible = false;
            this.phantomNode.visible = false;
            this.nodeWasClicked = false;

            this.isBending = false;
            this.isMovingAnchor = false;

            const arrowAction = DiagramAction.currentAction as
                | PropTrackingAction<any>
                | undefined;
            if (arrowAction && arrowAction.propHasChanged) {
                if (arrowAction.name === "move arrow anchor") {
                    this.anchorOwner.updateQuickAdds();
                }
                arrowAction.stopRecording();
            } else {
                arrowAction?.cancel();
            }

            if (this.entireObject.contains(e.point)) {
                this.line.strokeWidth = this.arrowhead.strokeWidth =
                    this.HIGHLIGHTED_ARROW_WIDTH;
                this.nodeGroup.visible = true;
            }

            this.entireObject.sendToBack();
        };
    }

    get nodeWasClicked(): boolean {
        return this.entireObject.data.nodeWasClicked;
    }

    set nodeWasClicked(state: boolean) {
        this.entireObject.data.nodeWasClicked = state;
    }

    get grabbedIndex(): number {
        return this.entireObject.data.grabbedIndex;
    }

    set grabbedIndex(index: number) {
        this.entireObject.data.grabbedIndex = index;
    }

    get draggedAnchor(): "inAnchor" | "outAnchor" {
        return this.entireObject.data.draggedAnchor;
    }

    set draggedAnchor(anchor: "inAnchor" | "outAnchor") {
        this.entireObject.data.draggedAnchor = anchor;
    }

    private get draggedAnchorPoint() {
        return this.anchorOwner[this.draggedAnchor];
    }

    private set draggedAnchorPoint(point: number) {
        this.anchorOwner[this.draggedAnchor] = point;
    }

    private get anchorOwner() {
        return this.draggedAnchor === "inAnchor" ? this.target : this.origin;
    }

    isConnectedTo(p: Participant) {
        return this.target === p || this.origin === p;
    }

    dragAnchor = (e: paper.MouseEvent) => {
        const connectedParticipant = this.anchorOwner.connectedParticipant;

        if (connectedParticipant) {
            const pointA = this.anchorOwner.innerGroup.localToGlobal(
                this.anchorOwner.border.getNearestPoint(
                    this.anchorOwner.innerGroup.globalToLocal(e.point)
                )
            );
            const pointB = connectedParticipant.innerGroup.localToGlobal(
                connectedParticipant.border.getNearestPoint(
                    connectedParticipant.innerGroup.globalToLocal(e.point)
                )
            );
            if (e.point.getDistance(pointB) < e.point.getDistance(pointA)) {
                if (this.draggedAnchor === "inAnchor") {
                    this.changeTarget(connectedParticipant);
                } else {
                    this.changeOrigin(connectedParticipant);
                }
            }
        }

        const curveLoc = this.anchorOwner.border.getNearestLocation(
            this.anchorOwner.innerGroup.globalToLocal(e.point)
        );

        const anchorPoint = Math.round(
            curveLoc.offset / (this.anchorOwner.border.length / NUM_OF_ANCHORS)
        );

        this.draggedAnchorPoint = anchorPoint;

        this.update();
    };

    private get grabbedSegment() {
        return this.line.segments[this.grabbedIndex];
    }

    dragBend = (e: paper.MouseEvent) => {
        this.grabbedSegment.point = e.point;
        this.update();
    };

    /**
     * Calculates a length from the difference between the items' distance and rotation.
     * Used to help straighten the arrow segments immediately entering/exiting an object and
     * make the curve between the objects look reasonable for turns, etc .
     * @param line
     * @returns The calculated force
     */
    private getForce(): number {
        let force = 0;

        const objectLength = this.origin.core.bounds.height;
        const dist = Math.min(objectLength * 10, this.line.length);

        const fromRot =
            this.origin.innerGroup.rotation +
            (360 / NUM_OF_ANCHORS) * this.origin.outAnchor;
        const toRot =
            this.target.innerGroup.rotation +
            (360 / NUM_OF_ANCHORS) *
                (this.target.inAnchor - NUM_OF_ANCHORS / 2);
        let rotationDiff = fromRot - toRot;

        if (rotationDiff > 180) {
            rotationDiff -= 360;
        } else if (rotationDiff < -180) {
            rotationDiff += 360;
        }

        force = (dist + Math.abs(rotationDiff) / 2) / this.ARROW_FORCE_DIVISOR;

        return force;
    }

    private addNode(p: paper.Point) {
        new ArrowNode(p).addTo(this.nodeGroup);
    }

    /**
     * Positions the arrow
     */
    update() {
        if (Arrow.trackAlreadyUpdated) {
            if (Arrow.alreadyUpdated.has(this.id)) {
                return;
            }

            Arrow.alreadyUpdated.add(this.id);
        }

        const toVector = this.target.getAnchorPoint(this.target.inAnchor);
        const fromVector = this.origin.getAnchorPoint(this.origin.outAnchor);

        for (let i = 1; i < this.line.segments.length - 1; ++i) {
            const j = i - 1;

            if (j >= this.nodeGroup.children.length) {
                this.addNode(this.line.segments[i].point);
            } else {
                this.nodeGroup.children[j].position =
                    this.line.segments[i].point;
            }
        }

        this.line.smooth({ type: "catmull-rom", factor: 0.7 });
        // this.line.smooth({type: "continuous"});
        this.line.firstSegment.point = fromVector;
        this.line.lastSegment.point = toVector;

        const force = this.getForce();
        const outForce = Math.min(
            force,
            this.line.firstSegment.point.getDistance(
                this.line.firstSegment.next.point
            ) * 0.5
        );
        const inForce = Math.min(
            force,
            this.line.lastSegment.point.getDistance(
                this.line.lastSegment.previous.point
            ) * 0.5
        );

        this.line.firstSegment.handleOut = fromVector
            .subtract(this.origin.globalPointFor("position"))
            .normalize(outForce);
        this.line.lastSegment.handleIn = toVector
            .subtract(this.target.globalPointFor("position"))
            .normalize(inForce);

        const arrowheadVector = this.line.lastSegment.handleIn.rotate(
            180,
            new paper.Point(0, 0)
        );
        const arrowheadEdge = arrowheadVector.normalize(12.5);

        this.arrowhead.segments[0] = new paper.Segment(
            this.line.lastSegment.point
                .add(arrowheadEdge)
                .rotate(145, this.line.lastSegment.point)
        );
        this.arrowhead.segments[1] = new paper.Segment(
            this.line.lastSegment.point
        );
        this.arrowhead.segments[2] = new paper.Segment(
            this.line.lastSegment.point
                .add(arrowheadEdge)
                .rotate(-145, this.line.lastSegment.point)
        );
    }

    reverse() {
        [this.origin, this.target] = [this.target, this.origin];
        this.update();
    }

    changeOrigin(newOrigin: Participant) {
        if (this.origin === newOrigin) return;
        const oldOrigin = this.origin;
        this.origin.outArrow = undefined;
        this.origin = newOrigin;
        oldOrigin.updateQuickAdds();
        this.update();
    }

    changeTarget(newTarget: Participant) {
        if (this.target === newTarget) return;
        const oldTarget = this.target;
        this.target.inArrow = undefined;
        this.target = newTarget;
        oldTarget.updateQuickAdds();
        this.update();
    }

    reset() {
        this.nodeGroup.removeChildren();
        this.line.removeSegments(1, this.line.segments.length - 1);
        this.update();
    }

    remove() {
        this.target.inArrow = undefined;
        this.origin.outArrow = undefined;
        super.remove();
    }

    onAddToGroupSelection(): void {
        this.entireObject.locked = true;
    }

    onRemoveFromGroupSelection(): void {
        this.entireObject.locked = false;
    }

    getOutline(): paper.PathItem {
        return this.line.clone({ insert: false });
    }
}
