// fundamental
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// project components
import { CdtDiagramComponent } from './cdt-diagram/cdt-diagram.component';
import { SidePanelComponent } from './side-panel/side-panel.component';
import { TopPanelComponent } from './top-panel/top-panel.component';
import { DemoPageComponent } from './demo-page/demo-page.component';
import { MainPageComponent } from './main-page/main-page.component';
import { PageDisablerComponent } from './page-disabler/page-disabler.component';
import { CrashReportGridComponent } from './crash-report-grid/crash-report-grid.component';
import { ControlBarComponent } from './control-bar/control-bar.component';
import { MapControlsComponent } from './map-controls/map-controls.component';
import { ZoomControlComponent } from './map-controls/zoom-control/zoom-control.component';

// 3rd party modules
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { PopupModule } from '@progress/kendo-angular-popup';
import { MenusModule } from '@progress/kendo-angular-menu';
import { GridModule } from '@progress/kendo-angular-grid';
import { ContextMenuModule } from '@progress/kendo-angular-menu';
import { GeolocPointMenuComponent } from './geoloc-point-menu/geoloc-point-menu.component';
import { TestPageComponent } from './test-page/test-page.component';
import { HelpBarComponent } from './help-bar/help-bar.component';
import { PopUpMenuComponent } from './popup-menu/popup-menu.component';
import { IconGridComponent } from './icon-grid/icon-grid.component';
import { ScaleBarComponent } from './map-controls/scale-bar/scale-bar.component';
import { CrashLegendComponent } from './map-controls/crash-legend/crash-legend.component';
import { BottomBarComponent } from './bottom-bar/bottom-bar.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { CrashReportInfoComponent } from './crash-report-info/crash-report-info.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { AppearanceControlComponent } from './map-controls/appearance-control/appearance-control.component';
import { LocationInfoComponent } from './location-info/location-info.component';
import { LittleLoctypeButtonsComponent } from './little-loctype-buttons/little-loctype-buttons.component';
import { TextInputSelectComponent } from './text-input-select/text-input-select.component';
import { NotAuthPageComponent } from './not-auth-page/not-auth-page.component';
import { InsetToolComponent } from './inset-tool/inset-tool.component';

@NgModule({
    declarations: [
        AppComponent,
        CdtDiagramComponent,
        SidePanelComponent,
        TopPanelComponent,
        DemoPageComponent,
        MainPageComponent,
        PageDisablerComponent,
        CrashReportGridComponent,
        ControlBarComponent,
        MapControlsComponent,
        AppearanceControlComponent,
        ZoomControlComponent,
        GeolocPointMenuComponent,
        TestPageComponent,
        HelpBarComponent,
        PopUpMenuComponent,
        IconGridComponent,
        ScaleBarComponent,
        CrashLegendComponent,
        BottomBarComponent,
        CrashReportInfoComponent,
        IconButtonComponent,
        LocationInfoComponent,
        LittleLoctypeButtonsComponent,
        TextInputSelectComponent,
        NotAuthPageComponent,
        InsetToolComponent
    ],
    imports: [
        BrowserModule,
        InputsModule,
        LabelModule,
        ButtonsModule,
        LayoutModule,
        BrowserAnimationsModule,
        GridModule,
        // MatGridListModule,
        TooltipModule,
        FormsModule,
        PopupModule,
        MenusModule,
        ContextMenuModule,
        AppRoutingModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
