import * as paper from "paper";

interface Point {
    x: number;
    y: number;
}

export class S4Circle {
    public x: number;
    public y: number;
    public radius: number;

    get center(): Point {
        return { x: this.x, y: this.y };
    }

    constructor(p: Point, radius: number) {
        this.x = p.x;
        this.y = p.y;
        this.radius = radius;
    }

    intersectsLine(lineStart: Point, lineEnd: Point): boolean {
        // Translate the line and circle so that the circle is at the origin
        const translatedLineStart = {
            x: lineStart.x - this.center.x,
            y: lineStart.y - this.center.y
        };
        const translatedLineEnd = {
            x: lineEnd.x - this.center.x,
            y: lineEnd.y - this.center.y
        };

        // Compute the direction vector of the line
        const lineVector = {
            x: translatedLineEnd.x - translatedLineStart.x,
            y: translatedLineEnd.y - translatedLineStart.y
        };

        // Compute the coefficients of the quadratic equation
        const a = lineVector.x ** 2 + lineVector.y ** 2;
        const b =
            2 *
            (lineVector.x * translatedLineStart.x +
                lineVector.y * translatedLineStart.y);
        const c =
            translatedLineStart.x ** 2 +
            translatedLineStart.y ** 2 -
            this.radius ** 2;

        // Compute the discriminant of the quadratic equation
        const discriminant = b ** 2 - 4 * a * c;

        // If the discriminant is negative, there are no real roots, meaning no intersection
        if (discriminant < 0) {
            return false;
        }

        // If the discriminant is zero or positive, there could be one or two intersection points
        const t1 = (-b + Math.sqrt(discriminant)) / (2 * a);
        const t2 = (-b - Math.sqrt(discriminant)) / (2 * a);

        // If either root is within the range [0,1], there is an intersection
        return (t1 >= 0 && t1 <= 1) || (t2 >= 0 && t2 <= 1);
    }
}

export class S4Rectangle {
    public width: number;
    public height: number;
    public x: number;
    public y: number;
    private _rotation: number;

    constructor(paperItem: paper.Item, rotation?: number);
    constructor(
        width: number,
        height: number,
        x: number,
        y: number,
        rotation: number
    );
    constructor(
        paperItemOrWidth: paper.Item | number = 1,
        rotationOrHeight: number | undefined = undefined,
        x = 0,
        y = 0,
        rotation = 0
    ) {
        if (paperItemOrWidth instanceof paper.Item) {
            ({ width: this.width, height: this.height } =
                paperItemOrWidth.bounds);
            ({ x: this.x, y: this.y } = paperItemOrWidth.localToGlobal(
                paperItemOrWidth.bounds.center
            ));
            this.rotation = rotationOrHeight ?? paperItemOrWidth.rotation;
        } else {
            this.width = paperItemOrWidth;
            this.height = rotationOrHeight || 1;
            this.rotation = rotation;
            this.x = x;
            this.y = y;
        }
    }

    get rotation(): number {
        return this._rotation;
    }

    set rotation(rotation: number) {
        // Ensure rotation stays within -180 to 180 degrees
        this._rotation = rotation % 360;
        if (this._rotation > 180) {
            this._rotation -= 360;
        } else if (this._rotation <= -180) {
            this._rotation += 360;
        }
    }

    intersectsCircle(point: Point, radius: number): boolean {
        const circle = new S4Circle(this.globalToLocal(point), radius);

        /* The below commented out code draws the circle and rectangle in the coordinate system
         in which they are compared for intersection (for debugging) */

        // const activeLayerIndex = paper.projects[0].layers.indexOf(
        //     paper.projects[0].activeLayer
        // );
        // paper.projects[0].layers.at(-1)?.activate();
        // const r = new paper.Path.Rectangle([
        //     this.left,
        //     this.top,
        //     this.width,
        //     this.height
        // ]);
        // r.strokeColor = new paper.Color("red");
        // r.strokeWidth = 1;
        // // r.pivot = r.bounds.topLeft;
        // // r.rotation = this.rotation;
        // const c = new paper.Path.Circle([circle.x, circle.y], radius);
        // c.strokeColor = new paper.Color("green");
        // c.strokeWidth = 1;
        // paper.projects[0].layers[activeLayerIndex].activate();
        // setTimeout(() => {
        //     r.remove();
        //     c.remove();
        // }, 5000);

        return (
            this.contains(circle.center) ||
            circle.intersectsLine(this.topLeft, this.topRight) ||
            circle.intersectsLine(this.topRight, this.bottomRight) ||
            circle.intersectsLine(this.bottomRight, this.bottomLeft) ||
            circle.intersectsLine(this.bottomLeft, this.topLeft)
        );
    }

    private globalToLocal(p: Point) {
        const angle = this.rotation * (Math.PI / 180); // Convert degrees to radians
        const cos = Math.cos(-angle);
        const sin = Math.sin(-angle);
        const dx = p.x - this.x;
        const dy = p.y - this.y;
        return {
            x: dx * cos - dy * sin + this.x,
            y: dx * sin + dy * cos + this.y
        };
    }

    contains(p: Point) {
        return (
            p.x >= this.left &&
            p.x <= this.right &&
            p.y >= this.top &&
            p.y <= this.bottom
        );
    }

    get topLeft(): Point {
        return {
            x: this.left,
            y: this.top
        };
    }

    get topRight(): Point {
        return {
            x: this.right,
            y: this.top
        };
    }

    get bottomLeft(): Point {
        return {
            x: this.left,
            y: this.bottom
        };
    }

    get bottomRight(): Point {
        return {
            x: this.right,
            y: this.bottom
        };
    }

    get left() {
        return this.x - this.width / 2;
    }

    get right() {
        return this.x + this.width / 2;
    }

    get top() {
        return this.y - this.height / 2;
    }

    get bottom() {
        return this.y + this.height / 2;
    }
}
