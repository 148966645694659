<div class="d-flex flex-column pointer-events-auto mb-1">
        <button [disabled]="isAtMaxZoom" class="map-control-btn bg-base cursor-pointer"
            (click)="onZoomIn()" title="Zoom In">
            <i class="fal fa-plus fa-fw fa-lg"></i>
        </button>
        <button [disabled]="isAtMinZoom" class="map-control-btn bg-base cursor-pointer"
            (click)="onZoomOut()" title="Zoom Out"><i class="fal fa-minus fa-fw fa-lg"></i>
        </button>

</div>
