<div
    #pageDisabler
    id="page-disabler"
    class="page-disabler"
    [class]="{ grayOut: doGrayOut }"
    *ngIf="pageIsDisabled"
>
    <div #messageBox id="messageBox" class="message-box" *ngIf="showMessageBox">
        <h3>
            {{ messageTitle }}
        </h3>
        <div
            style="margin: 0"
            [style]="messageStyles"
            [class]="messageClasses"
            [innerHtml]="message"
        ></div>
        <div #buttonBar id="buttonBar">
            <ng-template ngFor let-btn [ngForOf]="buttons">
                <button
                    (click)="
                        functions && functions[btn] && functions[btn]();
                        resolve(btn)
                    "
                >
                    {{ btn }}
                </button>
            </ng-template>
            <!-- <button>Ok</button><button>Cancel</button> -->
        </div>
    </div>
</div>
