import * as paper from 'paper';
import { LineToolBuilder } from './LineTool';

export class CurveToolBuilder extends LineToolBuilder {
    /**
     * @returns the middle segment or second segment if a middle segment was not added
     */
    get middleSegment () : paper.Segment {
        return this.shape.segments[1];
    }

    protected continueShape(point: paper.Point): void {
        if (!this.unfinishedAnno) return;

        if(this.shape.segments.length === 2) {
            // Update last segment position
            this.shape.lastSegment.point = point;

            paper.projects[0].view.off('mousemove', this.activeHandlers.mousemove!);

            // Add segment in the middle
            this.shape.insert(1, new paper.Segment(point));

            // Add mouseHandler
            this.makeCurve = this.makeCurve.bind(this);
            paper.projects[0].view.on("mousemove", this.makeCurve);
            this.activeHandlers.mousemove = this.makeCurve;
        } else {
            this.updateLineArrows();
            this.completeShape();
        }
    }

    /**
     * Make the curve with the three segments of the line
     * @param e paper.MouseEvent
     * @returns don't execute code if unfinishedAnno us undefined
     */
    makeCurve(e: paper.MouseEvent) {
        if (!this.unfinishedAnno) return;

        // update middle segment position and make it smooth
        this.middleSegment.point = e.point;
        this.shape.smooth({type: 'continuous'});
    }
}
