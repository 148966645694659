import { Component, Output, EventEmitter, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
@Component({ selector: 'icon-btn', templateUrl: './icon-button.component.html' })

export class IconButtonComponent implements OnChanges, OnInit {
    @Input() icon = '';
    @Input() cssClass = '';
    @Input() customIcon = false;
    @Input() iconClass = '';
    @Input() active = false;
    @Input() disabled = false;
    @Input() isLoadingWhenDisabled = false;
    @Input() hasLabel = false;
    @Output() trigger: EventEmitter<any> = new EventEmitter<any>();
    classList = '';
    _customIcon = false;
    get isCustomIcon() {return this._customIcon;}
    set isCustomIcon(value) {this._customIcon = value; }
    ngOnInit() {
        this.isCustomIcon = this.customIcon;
    }
    ngOnChanges(changes: SimpleChanges) {
        // if (changes.active) { console.log(changes); }
        if (changes.active && changes.active.currentValue === true) {
            this.classList = `${this.cssClass} active`;
        }
        else {
            this.classList = this.cssClass;
        }
        if (changes.disabled) {
            if (changes.disabled.currentValue === true) {
                if (this.isLoadingWhenDisabled) {
                    this.isCustomIcon = false;
                }
            }
            else if (changes.disabled.currentValue === false) {
                this.isCustomIcon = this.customIcon;
            }
        }
    }
    get buttonClass(): string {
        return `s4-btn ${this.classList}`;
    }
    get disabledIconClassList(): string { return `fal fa-spinner fa-pulse ${this.iconClass}`; }
    get iconClassList(): string {
        return this.isCustomIcon ? this.iconClass : `fal fa-${this.icon} ${this.iconClass}`;
    }

    onTrigger(): void {
        if (this.trigger) {
            this.trigger.emit();
        }
    }
}
