import { CenterlinePair } from "./../../cdt-diagram/cdt-diagram-models";
import { Injectable } from "@angular/core";
import { CrashReportService } from "./crash-report.service";
import {
    finishMajorDraw,
    promptBox,
    startMajorDraw
} from "src/app/page-disabler/disable-enable-page";
import { UIService } from "./ui.service";
import * as Models from "../../cdt-diagram/cdt-diagram-models";
import * as map from "../diagram-map";
import {
    drawDebugCircle,
    getDiagramBounds,
    to180Angle,
    ungroup
} from "../helpers";
import { ParticipantBuilder } from "src/app/classes/ObjectBuilders";
import { NonMotorist } from "src/app/classes/NonMotorist";
import { IconId } from "../icon";
import { Vehicle } from "src/app/classes/Vehicle";
import { NUM_OF_ANCHORS, Participant } from "src/app/classes/Participant";
import { Trailer } from "src/app/classes/Trailer";
import * as paper from "paper";
import { DiagramService } from "./diagram.service";
import { LatLng, latLng } from "leaflet";
import { GeolocationService } from "./geolocation.service";

@Injectable({
    providedIn: "root"
})
export class DiagramAutomationService {
    private readonly animalBuilder = new ParticipantBuilder(NonMotorist);
    private readonly trailerBuilder = new ParticipantBuilder(Trailer);

    get crashPoint() {
        return DiagramService.crashPointObject.position;
    }

    constructor(
        private crashReport: CrashReportService,
        private ui: UIService,
        private geo: GeolocationService
    ) {
        this.animalBuilder.colorCode = "GRN";
        this.animalBuilder.icon = "Alligator";
        this.animalBuilder.label = "Animal";
        this.animalBuilder.subtype = "animal";
        this.animalBuilder.iconFolder = "Animals";
        this.trailerBuilder.pivotLocked = true;
        this.trailerBuilder.icon = IconId.UtilityTrailer;
    }

    /** Uses the crashReportData and the participants array from cdt-diagram-read-write.ts to place
     *  up to the first 2 participants on the diagram */
    async placeParticipants() {
        if (!this.crashReport.participants.length) {
            return;
        }

        startMajorDraw();

        let participantIndex = 0; // index of the participant array corresponding to participant in question
        let participantsPlaced = 0;
        let animalCrash = false;
        let maxVehiclesToPlace = 2;

        // If this is a animal crash, place 1st vehicle and the animal
        // If this is a ped or bicycle crash, place 1st vehicle and 1st ped
        if (this.crashReport.crashReportData.firstHarmfulEvent) {
            if (
                this.crashReport.crashReportData.firstHarmfulEvent ==
                Models.FirstHarmfulEvent.Animal
            ) {
                animalCrash = true;
                maxVehiclesToPlace = 1;
            } else if (
                this.crashReport.crashReportData.firstHarmfulEvent ==
                    Models.FirstHarmfulEvent.Pedestrian ||
                this.crashReport.crashReportData.firstHarmfulEvent ==
                    Models.FirstHarmfulEvent.Pedalcycle
            ) {
                maxVehiclesToPlace = 1;
            }
        }

        const promises = new Array<Promise<any>>();
        const skippedParticipantIndices = new Array<number>();

        // loop through the vehicles up to one of the limits, placing them on the diagram
        if (this.crashReport.crashReportData.vehicles) {
            let i = 0;
            const numVehicles =
                this.crashReport.crashReportData.vehicles.length;
            for (
                i = 0;
                i < numVehicles && participantsPlaced < maxVehiclesToPlace;
                ++i, ++participantIndex
            ) {
                const veh = this.crashReport.crashReportData.vehicles[i];
                if (
                    // do not place if travel direction or manuever is not provided
                    veh.travelDirection &&
                    veh.maneuver &&
                    veh.maneuver != Models.VehicleManeuver.NotProvided &&
                    !Models.unknownDirs.includes(
                        veh.travelDirection.toUpperCase()
                    )
                ) {
                    ++participantsPlaced;
                    promises.push(
                        this.placeVehicle(
                            this.crashReport.crashReportData.vehicles[i],
                            participantIndex
                        )
                    );
                } else {
                    skippedParticipantIndices.push(participantIndex);
                }
            }

            participantIndex = this.crashReport.crashReportData.vehicles.length; // if any vehicles were skipped, make sure next index is a NM.
            skippedParticipantIndices.push(
                ...Array.from({ length: numVehicles - i }, (_, j) => j + i)
            );
        }

        // loop through the non-motorists up to one of the limits, placing them on the diagram
        if (this.crashReport.crashReportData.nonMotorists) {
            if (!animalCrash) {
                for (
                    let i = 0;
                    i < this.crashReport.crashReportData.nonMotorists.length &&
                    participantsPlaced < 2;
                    ++i, ++participantIndex
                ) {
                    const nm = this.crashReport.crashReportData.nonMotorists[i];
                    if (
                        // Do not place if not explicity a pedestrian or cyclist.
                        nm.desc &&
                        [
                            Models.NonMotoristDescription.Pedestrian,
                            Models.NonMotoristDescription.OtherPedestrian,
                            Models.NonMotoristDescription.Bicyclist,
                            Models.NonMotoristDescription.OtherCyclist
                        ].includes(nm.desc)
                    ) {
                        ++participantsPlaced;
                        promises.push(
                            this.placeNonMotorist(nm, participantIndex)
                        );
                    } else {
                        skippedParticipantIndices.push(participantIndex);
                    }
                }
            }
        }

        if (animalCrash) {
            promises.push(this.placeAnimal());
        }

        await Promise.all(promises);
        finishMajorDraw();

        map.goHome(
            { ...this.ui.padding, animate: true, duration: 1 },
            getDiagramBounds(50)
        );

        const numParticipants = this.crashReport.participants.length;

        if (participantsPlaced == 2 && numParticipants > 2) {
            const plurality = numParticipants - 2 > 1 ? "s" : "";
            await promptBox(
                `Alert - ${
                    numParticipants - 2
                } Participant${plurality} Not Placed`,

                `Two crash participants ` +
                    `have been placed on the diagram for you. ` +
                    `The remaining ${
                        numParticipants - 2
                    } participant${plurality} must be added manually by using the checkboxes in the ` +
                    `Reported Participants Legend (bottom left of map).`
            );
            this.ui.legendIsOpen = true;
            this.ui.legend.flashRows(
                Array.from({ length: numParticipants - 2 }, (v, k) => k + 2)
            );
        } else if (participantsPlaced < numParticipants) {
            const numSkipped = skippedParticipantIndices.length;
            const plurality = numSkipped > 1 ? "s" : "";
            const reason = animalCrash
                ? `since it is not known where ${
                      numSkipped > 1 ? "they" : "it"
                  } should be located. `
                : "because at least one necessary attribute was not filled out on the crash report. ";
            await promptBox(
                `Alert - ${numSkipped} Participant${plurality} Not Placed`,
                `${numSkipped} participant${plurality} ${
                    numSkipped > 1 ? "were" : "was"
                } ` +
                    "not automatically added to the diagram " +
                    reason +
                    "To add " +
                    `${
                        numSkipped > 1 ? "them" : "it"
                    } to the diagram manually, check the box${
                        numSkipped > 1 ? "es" : ""
                    } ` +
                    "in the Reported Participants Legend (bottom left of map)."
            );
            this.ui.legendIsOpen = true;
            this.ui.legend.flashRows(skippedParticipantIndices);
        }
    }

    /** Given a non-motorist description, returns the data.thing string that should be used for the diagram object core */
    private determineNonMotoristType(
        desc: Models.NonMotoristDescription | undefined
    ): string {
        if (desc) {
            switch (desc) {
                case Models.NonMotoristDescription.Pedestrian:
                case Models.NonMotoristDescription.OtherPedestrian:
                    return "pedestrian";
                case Models.NonMotoristDescription.Bicyclist:
                case Models.NonMotoristDescription.OtherCyclist:
                    return "bicycle";
            }
        }

        return "pedestrian";
    }

    private async placeAnimal() {
        const animal = await this.animalBuilder.build(this.crashPoint);
    }

    /** Places a non-motorist on the diagram
     * @param nm the data that describes this non-motorist
     * @param participantIndex should be the index of the participants array that corresponds to this participant
     */
    async placeNonMotorist(
        nm: Models.NonMotoristData,
        participantIndex: number
    ) {
        startMajorDraw();

        const nmBuilder = new ParticipantBuilder(NonMotorist);
        nmBuilder.scalable = true;
        const subtype = this.determineNonMotoristType(nm.desc);

        const newNm = await nmBuilder.build(
            subtype == "pedestrian"
                ? this.crashPoint.add(new paper.Point(0, 15))
                : this.crashPoint,
            this.crashReport.participants[participantIndex].icon,
            "NM" + nm.number
        );

        newNm.subtype = subtype;
        newNm.participantIndex = participantIndex;
        newNm.participantData = this.crashReport.participants[participantIndex];
        this.crashReport.participants[participantIndex].isDiagrammed = true;

        finishMajorDraw();
        return newNm;
    }

    /** Places a vehicle and its prior position (ghost) on the diagram
     * @param veh the data that describes this vehicle
     * @param participantIndex should be the index of the participants array that corresponds to this participant
     */
    async placeVehicle(veh: Models.VehicleData, participantIndex: number) {
        startMajorDraw();

        let iconOrientation =
            veh.impactArea == Models.VehicleImpactArea.Overturn
                ? "Bottom"
                : "Top";
        if (
            // motorcycle does not have bottom-up.
            iconOrientation == "Bottom" &&
            this.crashReport.participants[participantIndex].icon ==
                IconId.Motorcycle
        ) {
            iconOrientation = "Side";
        }

        const newVeh = await new ParticipantBuilder(Vehicle).build(
            new paper.Point(0, 0),
            this.crashReport.participants[participantIndex].icon,
            "V" + veh.number,
            veh.colorCode && Models.ncicColorCodes.hasOwnProperty(veh.colorCode)
                ? veh.colorCode
                : "SIL",
            iconOrientation,
            veh.isHitAndRun === "Y"
        );

        // give the object core a participantIndex for interaction w/ the data grid
        newVeh.participantIndex = participantIndex;
        newVeh.participantData =
            this.crashReport.participants[participantIndex];

        if (veh.travelDirection) {
            switch (veh.travelDirection.toUpperCase()) {
                case "E":
                case "EAST":
                    newVeh.rotate(90);
                    break;
                case "S":
                case "SOUTH":
                    newVeh.rotate(180);
                    break;
                case "W":
                case "WEST":
                    newVeh.rotate(-90);
                    break;
            }
        }

        let impactPivot: paper.Point;

        if (veh.impactArea) {
            // temporarily set pivot to the point of impact so that placement is offset by this point
            impactPivot = this.getPointOfImpact(newVeh, veh.impactArea);
        } else {
            impactPivot = this.getPointOfImpact(
                newVeh,
                Models.VehicleImpactArea.Front
            );
        }

        newVeh.entireObject.pivot = impactPivot;
        newVeh.position = this.crashPoint;
        // reset pivot to the center of the vehicle icon
        newVeh.entireObject.pivot = newVeh.globalPointFor("position");

        // let latLng1: LatLng | undefined = undefined;
        // let latLng2: LatLng | undefined = undefined;

        // latLng1 = map.projectPointToLatLng(
        //     newVeh.globalPointFor("bounds.topCenter")
        // );
        // latLng2 = map.projectPointToLatLng(
        //     newVeh.globalPointFor("bounds.bottomCenter")
        // );

        let ghost: Participant | undefined = undefined;

        // if this vehicle has a maneuver that can be automated, automate it
        if (
            veh.maneuver &&
            veh.maneuver != Models.VehicleManeuver.Parked &&
            veh.maneuver != Models.VehicleManeuver.StoppedInTraffic
        ) {
            if (
                veh.maneuver === Models.VehicleManeuver.Backing ||
                veh.maneuver === Models.VehicleManeuver.UTurn
            ) {
                if (veh.maneuver === Models.VehicleManeuver.Backing) {
                    newVeh.reverse();
                }

                newVeh.rotate(180);
            }

            ghost = newVeh.addPosition("Prior");
            this.positionGhost(ghost, veh.maneuver, veh.impactArea);
            if (veh.impactArea == Models.VehicleImpactArea.Overturn) {
                ghost.changeOrientation("Top");
            }

            // latLng2 = map.projectPointToLatLng(
            //     ghost.globalPointFor("bounds.bottomCenter")
            // );
        }

        // if the location type is not offroadway, align the positions to the roadway
        if (this.geo.locationType != "OFFROADWAY") {
            if (ghost) {
                const turns = [
                    Models.VehicleManeuver.TurningLeft,
                    Models.VehicleManeuver.TurningRight,
                    Models.VehicleManeuver.UTurn
                ];

                // if (veh.maneuver && !turns.includes(veh.maneuver)) {
                //     let roadwayAngle = await this.getRoadwayAngle(
                //         latLng1,
                //         latLng2,
                //         veh.travelDirection?.toUpperCase() || "N",
                //         await this.fetchBestLinkId(
                //             newVeh.globalPointFor("position"),
                //             newVeh.rotation
                //         )
                //         // "purple"
                //     );

                //     const group = new paper.Group([
                //         newVeh.entireObject,
                //         ghost.entireObject,
                //         ...newVeh.arrows.map((v) => v.entireObject)
                //     ]);
                //     group.pivot = this.crashPoint;
                //     group.rotate(roadwayAngle);
                //     newVeh.label.rotate(-roadwayAngle);
                //     ghost.label.rotate(-roadwayAngle);
                //     ungroup(group);
                // }

                // ghost.rotation = await this.getRoadwayAngle(
                //     map.projectPointToLatLng(
                //         ghost.globalPointFor("bounds.topCenter")
                //     ),
                //     map.projectPointToLatLng(
                //         ghost.globalPointFor("bounds.bottomCenter")
                //     ),
                //     "N",
                //     await this.fetchBestLinkId(
                //         ghost.globalPointFor("position"),
                //         ghost.rotation
                //     )
                // );
            }

            // latLng1 = map.projectPointToLatLng(
            //     newVeh.globalPointFor("bounds.position")
            // );

            // latLng2 = map.projectPointToLatLng(
            //     newVeh.globalPointFor("bounds.bottomCenter")
            // );

            // newVeh.rotation = await this.getRoadwayAngle(
            //     latLng1,
            //     latLng2,
            //     "N",
            //     await this.fetchBestLinkId(
            //         newVeh.globalPointFor("position"),
            //         newVeh.rotation
            //     )
            // );

            if (veh.impactArea) {
                newVeh.entireObject.pivot = this.getPointOfImpact(
                    newVeh,
                    veh.impactArea
                );
                newVeh.position = this.crashPoint;
                newVeh.entireObject.pivot = newVeh.globalPointFor("position");
            }
        }

        this.crashReport.participants[participantIndex].isDiagrammed = true;

        // if the area of initial impact = "Trailer" create and attach a trailer
        if (veh.impactArea == Models.VehicleImpactArea.Trailer) {
            const trailer = await this.trailerBuilder.build(
                Trailer,
                new paper.Point(0, 0)
            );

            newVeh.entireObject.pivot = newVeh.core.localToGlobal(
                new paper.Point(
                    newVeh.core.bounds.bottomCenter.x,
                    newVeh.core.bounds.bottomCenter.y +
                        trailer.core.bounds.height
                )
            );
            newVeh.position = this.crashPoint;
            newVeh.entireObject.pivot = newVeh.globalPointFor("position");
            trailer.attachTo(newVeh);

            if (this.geo.locationType !== "OFFROADWAY" && ghost) {
                const positionsVector = newVeh
                    .globalPointFor("position")
                    .subtract(ghost.globalPointFor("position"));
                newVeh.trailer!.rotation = positionsVector.angle + 90;
                ghost.trailer!.rotation = positionsVector.angle + 90;
            }
        }

        newVeh.updateArrows();
        finishMajorDraw();

        return newVeh;
    }

    // debugColorIndex = 0;
    // debugWidth = 10;
    // debugColorList = ["gold", "blue", "green", "orange", "cyan", "red", "yellow", "purple"];

    // private async fetchBestLinkId(
    //     p1: paper.Point,
    //     angle: number
    // ): Promise<number> {
    //     const normal = p1.normalize();
    //     normal.angle = angle - 90;
    //     const p2 = p1.add(normal.multiply(-300));
    //     const p3 = p1.add(normal.multiply(300));
    //     // drawDebugCircle(p1, this.debugColor, 50);
    //     // drawDebugCircle(p2, this.debugColor, 50);
    //     // drawDebugCircle(p3, this.debugColor, 50);
    //     // this.debugColor = this.debugColor == "gold" ? "green" : "gold";

    //     const latLngs = [p1, p2, p3].map((v) => {
    //         const latLng = map.projectPointToLatLng(v);
    //         return {
    //             latitude: latLng.lat,
    //             longitude: latLng.lng
    //         };
    //     });

    //     const linkTripleReq = {
    //         latLng1: latLngs[0],
    //         latLng2: latLngs[1],
    //         latLng3: latLngs[2]
    //     };

    //     const req = await fetch("api/diagram/get-link-triple", {
    //         method: "POST",
    //         body: JSON.stringify(linkTripleReq),
    //         headers: {
    //             "Content-Type": "application/json"
    //         }
    //     });

    //     if (req.ok) {
    //         const [a, b, c] = await req.json();

    //         if (a === b || a === c) {
    //             return a;
    //         } else if (b === c) {
    //             return b;
    //         } else {
    //             return a;
    //         }
    //     }

    //     throw new Error("link id lookup error");
    // }

    // private async getRoadwayAngle(
    //     latLng1: LatLng,
    //     latLng2: LatLng,
    //     dir = "N",
    //     linkId?: number
    //     // debugColor?: string
    // ) {
    //     // drawDebugCircle(
    //     //     map.latLngToProjectPoint(latLng1),
    //     //     debugColor ?? "blue"
    //     // );
    //     // drawDebugCircle(
    //     //     map.latLngToProjectPoint(latLng2),
    //     //     debugColor ?? "blue"
    //     // );

    //     const centerlineLatLngs = await this.fetchCenterlineLatLngs(
    //         latLng1,
    //         latLng2,
    //         linkId
    //     );

    //     console.log("input:", [latLng1, latLng2], "output:", centerlineLatLngs);

    //     const p1 = map.latLngToProjectPoint(
    //         new LatLng(
    //             centerlineLatLngs.centerline1.latitude,
    //             centerlineLatLngs.centerline1.longitude
    //         )
    //     );
    //     const p2 = map.latLngToProjectPoint(
    //         new LatLng(
    //             centerlineLatLngs.centerline2.latitude,
    //             centerlineLatLngs.centerline2.longitude
    //         )
    //     );

    //     // drawDebugCircle(p1, "red");
    //     // drawDebugCircle(p2, "red");

    //     let angle = p1.subtract(p2).angle + 90;

    //     if (dir === "S" || dir === "SOUTH") {
    //         angle += 180;
    //     } else if (dir === "E" || dir === "EAST") {
    //         angle -= 90;
    //     } else if (dir === "W" || dir === "WEST") {
    //         angle += 90;
    //     }

    //     return angle;
    // }

    // private async fetchCenterlineLatLngs(
    //     latLng1: LatLng,
    //     latLng2: LatLng,
    //     linkId?: number
    // ): Promise<CenterlinePair> {
    //     const latLngRequest: Models.CenterlinePairReq = {
    //         latLng1: {
    //             latitude: latLng1.lat,
    //             longitude: latLng1.lng,
    //             linkId: linkId
    //         },
    //         latLng2: {
    //             latitude: latLng2.lat,
    //             longitude: latLng2.lng,
    //             linkId: linkId
    //         }
    //     };

    //     const req = await fetch("api/diagram/get-centerline-pair", {
    //         method: "POST",
    //         body: JSON.stringify(latLngRequest),
    //         headers: {
    //             "Content-Type": "application/json"
    //         }
    //     });

    //     if (req.ok) {
    //         return await req.json();
    //     }

    //     throw new Error("angle lookup error");
    // }

    /** Positions the prior ghost of a vehicle based on the provided manuever and orientation of the vehicle */
    private positionGhost(
        ghost: Participant,
        maneuver: Models.VehicleManeuver,
        impact: Models.VehicleImpactArea | undefined
    ) {
        switch (maneuver) {
            case Models.VehicleManeuver.TurningLeft:
                ghost.rotate(90);
                this.shiftTowards("down", ghost, 200);
                this.shiftTowards("left", ghost, 70);
                break;
            case Models.VehicleManeuver.UTurn:
                ghost.rotate(180);
                this.shiftTowards("right", ghost, 75);
                this.shiftTowards("down", ghost, 186);
                break;
            case Models.VehicleManeuver.TurningRight:
                ghost.rotate(-90);
                this.shiftTowards("down", ghost, 140);
                this.shiftTowards("right", ghost, 70);
                break;
            case Models.VehicleManeuver.ChangingLanes:
                if (impact) {
                    if (impact >= 2 && impact <= 7) {
                        // guess would be changing from a lane on the left
                        this.shiftTowards("left", ghost, 30);
                    } else if (impact >= 9 && impact <= 14) {
                        this.shiftTowards("right", ghost, 30);
                    }
                }
                break;
            case Models.VehicleManeuver.Backing:
                this.shiftTowards("up", ghost, 75);
                break;
            case Models.VehicleManeuver.Slowing:
                this.shiftTowards(
                    "up",
                    ghost,
                    Math.max(
                        ghost.entireObject.bounds.height,
                        ghost.entireObject.bounds.width
                    )
                );
                break;
            case Models.VehicleManeuver.StraightAhead:
                // increase the default distance between positions
                this.shiftTowards(
                    "down",
                    ghost,
                    Math.max(
                        ghost.entireObject.bounds.height,
                        ghost.entireObject.bounds.width
                    )
                );
                break;
        }
    }

    /** Translates object relative to its orientation, e.g. up = forward */
    private shiftTowards(
        dir: "left" | "right" | "up" | "down",
        ghost: Participant,
        amt = 50
    ) {
        let vector = new paper.Point(0, 0);

        switch (dir) {
            case "left":
                vector = ghost
                    .globalPointFor("bounds.leftCenter")
                    .subtract(ghost.globalPointFor("bounds.center"));
                break;
            case "right":
                vector = ghost
                    .globalPointFor("bounds.rightCenter")
                    .subtract(ghost.globalPointFor("bounds.center"));
                break;
            case "up":
                vector = ghost
                    .globalPointFor("bounds.topCenter")
                    .subtract(ghost.globalPointFor("bounds.center"));
                break;
            case "down":
                vector = ghost
                    .globalPointFor("bounds.bottomCenter")
                    .subtract(ghost.globalPointFor("bounds.center"));
        }

        ghost.position = ghost.position.add(vector.normalize(amt));
    }

    /** Returns a point on the icon of the item based on the provided area of initial impact and the vehicle's orientation */
    private getPointOfImpact(
        vehicle: Vehicle,
        impact: Models.VehicleImpactArea
    ): paper.Point {
        if (impact > 14) {
            const centerlineVector = vehicle
                .globalPointFor("bounds.center")
                .subtract(vehicle.globalPointFor("bounds.topCenter"))
                .normalize();

            switch (impact) {
                case Models.VehicleImpactArea.Windshield:
                    return vehicle
                        .globalPointFor("bounds.topCenter")
                        .add(
                            centerlineVector.multiply(
                                vehicle.core.bounds.width / 1.75
                            )
                        );
                case Models.VehicleImpactArea.Hood:
                    return vehicle
                        .globalPointFor("bounds.topCenter")
                        .add(
                            centerlineVector.multiply(
                                vehicle.core.bounds.width / 4
                            )
                        );
                case Models.VehicleImpactArea.TopBackHalf:
                    return vehicle
                        .globalPointFor("bounds.bottomCenter")
                        .subtract(
                            centerlineVector.multiply(
                                vehicle.core.bounds.width / 5
                            )
                        );
            }

            return vehicle.globalPointFor("position");
        }

        const step = NUM_OF_ANCHORS / 14;
        const mappedAnchor = Math.floor((step * (impact - 1)) % NUM_OF_ANCHORS);
        return vehicle.getAnchorPoint(mappedAnchor);
    }
}
