<div class="appearance-control-container pointer-events-auto">
    <icon-btn [customIcon]="true" icon="appearance" cssClass="bg-base std cursor-pointer dont-save"></icon-btn>
    <div class="pos-rel" [class.display-none]="!isOpen">
        <div class="pos-abs bg-transparent" style="right: 0px; top: 0px; width: 15px; height: 100px;"></div>
    </div>
    <div class="appearace-selector-container pos-rel" [class.display-none]="!isOpen">
        <div class="appearance-selectors p-2 bg-base box-shadow-flat">

            <div *ngFor="let option of options; let i = index" class="ml-1 appearance-selector mb-2" [class.selected]="option === selected">
                <input
                    type="radio"
                    [value]="option"
                    [checked]="option === selected"
                    id="option{{i}}"
                    (change)="onSelect(option)"
                    name="selected">
                <label for="option{{i}}" class="appearance-title ml-1">{{option}}</label>
            </div>
        </div>
    </div>
</div>
