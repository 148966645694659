<page-disabler></page-disabler>
<div id="test-page">
    <div class="input-field" style="align-items: flex-end; margin: 0 0.2em">
        <div>
            <label for="hsmv-input" style="display: block">HSMV#: </label>
            <input id="hsmv-input" type="text" pattern="\d{8}" name="hsmv" />
            <button style="margin-left: 2px" (click)="import()">Import</button>
        </div>
        <div>
            <button (click)="clearForm()">Clear Form</button>
        </div>
    </div>
    <div>
        <fieldset>
            <legend>Location</legend>
            <button (click)="openS4Geolocation()">Geolocate</button>
            <div>
                <div class="input-field">
                    <p>GeoServiceRecordId:</p>
                    <span class="align-right">{{ artId }}</span>
                </div>
                <div class="input-field">
                    <p>VendorReportId:</p>
                    <span class="align-right" style="font-size: 0.7em; align-self: center;">{{ vendorId }}</span>
                </div>
                <div class="input-field">
                    <p>Latitude:</p>
                    <span class="align-right">{{ latitude }}</span>
                </div>
                <div class="input-field">
                    <p>Longitude:</p>
                    <span class="align-right">{{ longitude }}</span>
                </div>
            </div>
        </fieldset>
    </div>
    <div>
        <fieldset>
            <legend>Global Crash Form Attributes</legend>
            <div class="input-field">
                <label for="MOC">Manner of Collision/Impact:</label>
                <select
                    id="MOC"
                    class="align-right"
                    [(ngModel)]="crashFormData.mannerOfCollision"
                >
                    <option></option>
                    <option
                        *ngFor="
                            let item of crashReportFields.mannerOfCollision
                                | keyvalue: sortByValue
                        "
                        [value]="item.value"
                    >
                        {{ item.key }}: {{ item.value }}
                    </option>
                </select>
            </div>
            <div class="input-field">
                <label for="FHE">First Harmful Event:</label>
                <select
                    id="FHE"
                    class="align-right"
                    [(ngModel)]="crashFormData.firstHarmfulEvent"
                >
                    <option></option>
                    <option
                        *ngFor="
                            let item of crashReportFields.firstHarmfulEvent
                                | keyvalue: sortByValue
                        "
                        [value]="item.value"
                    >
                        {{ item.key }}: {{ item.value }}
                    </option>
                </select>
            </div>
        </fieldset>
    </div>
    <div>
        <fieldset>
            <legend>Vehicles</legend>
            <div class="input-field" style="justify-content: center">
                <label for="num-of-vehs">Number of Vehicles:</label>
                <input
                    class="align-right"
                    id="num-of-vehs"
                    type="number"
                    min="0"
                    max="999"
                    style="width: 3em; text-align: center"
                    [(ngModel)]="numberOfVehicles"
                    (change)="changeVehNumber($event)"
                />
            </div>
            <div *ngFor="let veh of crashFormData.vehicles">
                <fieldset style="width: auto">
                    <legend>Vehicle {{ veh.number }}</legend>
                    <div class="input-field">
                        <label [for]="'body-type' + veh.number"
                            >Body Type:</label
                        >
                        <select
                            [id]="'body-type' + veh.number"
                            class="align-right"
                            [(ngModel)]="veh.bodyType"
                        >
                            <option></option>
                            <option
                                *ngFor="
                                    let item of crashReportFields.bodyType
                                        | keyvalue: sortByValue
                                "
                                [value]="item.value"
                            >
                                {{ item.key }}: {{ item.value }}
                            </option>
                        </select>
                    </div>
                    <div class="input-field">
                        <label [for]="'special-function' + veh.number"
                            >Special Function:</label
                        >
                        <select
                            [id]="'special-function' + veh.number"
                            class="align-right"
                            [(ngModel)]="veh.specialFunction"
                        >
                            <option></option>
                            <option
                                *ngFor="
                                    let item of crashReportFields.specialFunction
                                        | keyvalue: sortByValue
                                "
                                [value]="item.value"
                            >
                                {{ item.key }}: {{ item.value }}
                            </option>
                        </select>
                    </div>
                    <div class="input-field">
                        <label [for]="'cmv-config' + veh.number"
                            >CMV Config:</label
                        >
                        <select
                            [id]="'cmv-config' + veh.number"
                            class="align-right"
                            [(ngModel)]="veh.cmvConfig"
                        >
                            <option></option>
                            <option
                                *ngFor="
                                    let item of crashReportFields.cmvConfig
                                        | keyvalue: sortByValue
                                "
                                [value]="item.value"
                            >
                                {{ item.key }}: {{ item.value }}
                            </option>
                        </select>
                    </div>
                    <div class="input-field">
                        <label [for]="'cargo-type' + veh.number"
                            >Cargo Body Type:</label
                        >
                        <select
                            [id]="'cargo-type' + veh.number"
                            class="align-right"
                            [(ngModel)]="veh.cargoType"
                        >
                            <option></option>
                            <option
                                *ngFor="
                                    let item of crashReportFields.cargoType
                                        | keyvalue: sortByValue
                                "
                                [value]="item.value"
                            >
                                {{ item.key }}: {{ item.value }}
                            </option>
                        </select>
                    </div>
                    <div class="input-field">
                        <label [for]="'color-code' + veh.number"
                            >Color Code:</label
                        >
                        <select
                            [id]="'color-code' + veh.number"
                            class="align-right"
                            [(ngModel)]="veh.colorCode"
                        >
                            <option></option>
                            <option
                                *ngFor="
                                    let item of crashReportFields.colorCodes
                                        | keyvalue
                                "
                                [value]="item.key"
                            >
                                {{ item.value }}: {{ item.key }}
                            </option>
                        </select>
                    </div>
                    <div class="input-field">
                        <label [for]="'travel-dir' + veh.number"
                            >Travel Direction:</label
                        >
                        <select
                            [id]="'travel-dir' + veh.number"
                            class="align-right"
                            [(ngModel)]="veh.travelDirection"
                        >
                            <option></option>
                            <option
                                *ngFor="
                                    let item of crashReportFields.directions
                                "
                            >
                                {{ item }}
                            </option>
                        </select>
                    </div>
                    <div class="input-field">
                        <label [for]="'impact-area' + veh.number"
                            >Area of Initial Impact:</label
                        >
                        <select
                            [id]="'impact-area' + veh.number"
                            class="align-right"
                            [(ngModel)]="veh.impactArea"
                        >
                            <option></option>
                            <option
                                *ngFor="
                                    let item of crashReportFields.AOII
                                        | keyvalue: sortByValue
                                "
                                [value]="item.value"
                            >
                                {{ item.key }}: {{ item.value }}
                            </option>
                        </select>
                    </div>
                    <div class="input-field">
                        <label [for]="'maneuver' + veh.number"
                            >Maneuver Action:</label
                        >
                        <select
                            [id]="'maneuver' + veh.number"
                            class="align-right"
                            [(ngModel)]="veh.maneuver"
                        >
                            <option></option>
                            <option
                                *ngFor="
                                    let item of crashReportFields.maneuver
                                        | keyvalue: sortByValue
                                "
                                [value]="item.value"
                            >
                                {{ item.key }}: {{ item.value }}
                            </option>
                        </select>
                    </div>
                    <div class="input-field">
                        <label [for]="'hit-n-run' + veh.number"
                            >Is Hit and Run:</label
                        >
                        <select
                            [id]="'hit-n-run' + veh.number"
                            class="align-right"
                            [(ngModel)]="veh.isHitAndRun"
                        >
                            <option></option>
                            <option>Y</option>
                            <option>N</option>
                            <option>?</option>
                        </select>
                    </div>
                </fieldset>
            </div>
        </fieldset>
    </div>
    <div>
        <fieldset>
            <legend>Non-Motorists</legend>
            <div class="input-field" style="justify-content: center">
                <label for="num-of-nms">Number of Non-Motorists:</label>
                <input
                    class="align-right"
                    id="num-of-nms"
                    type="number"
                    min="0"
                    max="999"
                    style="width: 3em; text-align: center"
                    [(ngModel)]="numberOfNonMotorists"
                    (change)="changeNMNumber($event)"
                />
            </div>
            <div *ngFor="let nm of crashFormData.nonMotorists">
                <fieldset style="width: auto">
                    <legend>Non-Motorist {{ nm.number }}</legend>
                    <div class="input-field">
                        <label [for]="'nm-desc' + nm.number"
                            >Description:</label
                        >
                        <select
                            [id]="'nm-desc' + nm.number"
                            class="align-right"
                            [(ngModel)]="nm.desc"
                        >
                            <option></option>
                            <option
                                *ngFor="
                                    let item of crashReportFields.nmDescription
                                        | keyvalue: sortByValue
                                "
                                [value]="item.value"
                            >
                                {{ item.key }}: {{ item.value }}
                            </option>
                        </select>
                    </div>
                </fieldset>
            </div>
        </fieldset>
    </div>
    <div class="bottom-buttons">
        <button id="submit-btn" [disabled]="!artId" (click)="putData()">
            Submit Data
        </button>
        <button
            [disabled]="!artId || !vendorId || !dataPut"
            (click)="goToDiagram()"
        >
            Go to Diagram
        </button>
    </div>
</div>
