import { MenuItem } from "@progress/kendo-angular-menu";
import { degreesToRadians } from "../shared/helpers";
import { Participant } from "./Participant";
import {
    addGhost,
    addPostPositionOption,
    addPriorPositionOption,
    arrowColorMenu,
    changeLabelMenu,
    colorMenu,
    defaultOrientationOptions,
    removeOption,
    resetScaleOption,
    selectAllPositionsOption
} from "./ellipsis-menu-options";
import { GroupSelection } from "./GroupSelection";
import * as paper from "paper";

export class NonMotorist extends Participant {
    protected applyClassName() {
        this.className = "NonMotorist";
    }

    get ellipsisOptions(): MenuItem[] {
        const [commonFirst, commonLast] = this.getCommonEllipsisOptions();

        if (this.subtype !== "animal") {
            let orientationOptions = structuredClone(defaultOrientationOptions);

            if (["wheelchair", "bicycle"].includes(this.subtype)) {
                orientationOptions.items.splice(1, 1); // remove "Bottom up"
            } else {
                orientationOptions = {
                    text: "Orientation",
                    items: [
                        { data: "orientation", text: "Standing" },
                        { data: "orientation", text: "Supine" },
                        { data: "orientation", text: "Prostrate" }
                    ]
                };
            }

            commonFirst.push(orientationOptions);
        }

        if (this.isScaled) {
            commonFirst.push(resetScaleOption);
        }

        if (this.isPrimary) {
            if (this.subtype == "animal") {
                commonFirst.push({
                    data: "swap",
                    text: "Change Icon"
                });
            }
        }

        if (this.isFromCrashReport) {
            return [...commonFirst, ...commonLast];
        }

        return [changeLabelMenu, ...commonFirst, colorMenu, ...commonLast];
    }

    set subtype(type: string) {
        this.core.data.subtype = type;
    }

    get subtype() {
        return this.core.data.subtype;
    }

    repositionLabel(groupSelection?: GroupSelection): void {
        let rot = this.rotation;
        if (groupSelection) {
            rot = this.innerGroup.matrix
                .clone()
                .rotate(
                    groupSelection.rotation,
                    groupSelection.position
                ).rotation;
        }
        const angle = degreesToRadians(rot);
        const width =
            Math.abs(Math.sin(angle)) *
            this.core.bounds.width *
            this.innerGroup.scaling.x;
        const height =
            Math.abs(Math.cos(angle)) *
            this.core.bounds.height *
            this.innerGroup.scaling.y;
        const r = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2)) / 2;
        const labelR = this.label.internalBounds.height;

        if (groupSelection) {
            this.label.position = groupSelection.innerGroup.globalToLocal(
                this.globalPointFor("position").subtract(
                    new paper.Point(0, r + labelR)
                )
            );
        } else {
            this.label.position = this.position.subtract(
                new paper.Point(0, r + labelR)
            );
        }
    }

    dragRotate = (e: paper.MouseEvent) => {
        super.dragRotate(e);
        this.repositionLabel();
    };
}
