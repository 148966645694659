import { OnInit, Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "text-input-select",
    templateUrl: "./text-input-select.component.html",
    styleUrls: ["./text-input-select.component.scss"]
})
export class TextInputSelectComponent implements OnInit {
    @Input() options: string[];
    @Input() textEditDisabled: boolean = false;
    @Input() disabled: boolean = false;
    @Input() value: string;
    @Output() onValueChange = new EventEmitter<string>();
    @Output() onOptionsChange = new EventEmitter<string[]>();

    constructor() {}

    ngOnInit(): void {
        this.value = this.options[0];
    }

    createNewOption() {
        this.options.push(this.value);
        this.onOptionsChange.emit(this.options);
    }

    onChange() {
        this.onValueChange.emit(this.value);
    }

    selectOnFocus(event: FocusEvent) {
        (event.currentTarget as HTMLInputElement).select();
    }
}
