<div
    #geolocWindow
    id="geoloc-window"
    [hidden]="!shown"
    class="geoloc-point-window"
    [style.left]="windowLeft"
    [style.bottom]="windowBottom"
    *ngIf="isReady"
>
    <div class="side-by-side">
        <div
            class="location-group justify-content-between align-items-center text-center"
        >
            <div>
                <h2 class="info-window-title">Prior Location</h2>
                <h3 class="loc-type-title">
                    ({{ originalLocation.RelationshipToNetwork }})
                </h3>
            </div>
            <div class="og-loc">
                <div>
                    <span
                        *ngIf="
                            originalLocation.RelationshipToNetwork ==
                            'OFFROADWAY'
                        "
                    >
                        {{ originalLocation.StreetAddressNumber + " " }} </span
                    >{{ originalLocation.StreetName }}
                </div>
                <div
                    *ngIf="
                        originalLocation.RelationshipToNetwork ==
                            'INTERSECTION' ||
                        originalLocation.RelationshipToNetwork == 'SEGMENT'
                    "
                >
                    <div>{{ originalRelation }}</div>
                    <div>
                        {{ originalLocation.NearestIntersectingStreetName }}
                    </div>
                </div>
            </div>
            <div>
                ({{ originalLocation.Latitude.toFixed(6) }},
                {{ originalLocation.Longitude.toFixed(6) }})
            </div>
        </div>
        <div class="vertical-bar"></div>
        <div class="location-group">
            <h2 class="info-window-title">New Location</h2>
            <h3 class="loc-type-title">
                ({{
                    chosenRelToNwk ||
                        this.originalLocation.RelationshipToNetwork
                }})
            </h3>

            <div class="ctrl-btns">
                <little-loctype-buttons
                    [value]="originalLocation.RelationshipToNetwork"
                    (newSelection)="onChangeRelToNwt($event)"
                    [disabled]="geocodingIsInProgress"
                ></little-loctype-buttons>
                <div class="checkbox-and-label">
                    <input
                        #overrideCheckbox
                        id="override-check"
                        type="checkbox"
                        [(ngModel)]="manualOverwriteEnabled"
                    /><label for="override-check">Manual<br />Overwrite</label>
                </div>
            </div>

            <fieldset id="info-window-fields" class="info-window-inputs">
                <div
                    class="d-flex align-items-center"
                    *ngIf="chosenRelToNwk == 'OFFROADWAY'"
                >
                    <label
                        for="address-nbr"
                        style="margin-right: 2px; font-size: small"
                        >Address #:</label
                    >
                    <input
                        id="address-nbr"
                        type="text"
                        style="width: 75%"
                        [(ngModel)]="streetAddress"
                        [disabled]="!manualOverwriteEnabled"
                    />
                </div>
                <!-- <select [(ngModel)]="street1">
                    <option *ngFor="let street of street1Names">
                        {{ street }}
                    </option>
                </select> -->
                <text-input-select
                    [value]="street1"
                    [options]="street1Names"
                    [textEditDisabled]="!manualOverwriteEnabled"
                    (onValueChange)="street1 = $event"
                    (onOptionsChange)="street1Names = $event"
                ></text-input-select>
                <div
                    *ngIf="chosenRelToNwk !== 'OFFROADWAY'"
                    class="info-window-inputs"
                >
                    <div
                        class="d-flex flex-row justify-content-between align-items-center"
                    >
                        <p
                            *ngIf="
                                chosenRelToNwk === 'INTERSECTION';
                                else segRampBlock
                            "
                        >
                            at intersection with
                        </p>
                        <ng-template #segRampBlock>
                            <fieldset
                                [disabled]="!manualOverwriteEnabled"
                                class="invisible-fieldset"
                            >
                                <input
                                    id="feet-input"
                                    class="feet-input"
                                    type="number"
                                    [(ngModel)]="offsetFeet"
                                    [style.width]="
                                        inputLength(
                                            'feet-input',
                                            offsetFeet.toString() + ' ',
                                            14
                                        )
                                    "
                                />
                                feet
                                <select
                                    id="dir-select"
                                    class="dir-select"
                                    type="text"
                                    [(ngModel)]="offsetDir"
                                    (mousedown)="changeDetector.detectChanges()"
                                    [style.width]="
                                        inputLength(
                                            'dir-select',
                                            offsetDir + ' ',
                                            5
                                        )
                                    "
                                >
                                    <option>North</option>
                                    <option>East</option>
                                    <option>South</option>
                                    <option>West</option>
                                    <option>?</option>
                                </select>
                                of
                            </fieldset>
                        </ng-template>
                        <button
                            id="swap-streets"
                            *ngIf="chosenRelToNwk == 'INTERSECTION'"
                            (click)="swapStreets()"
                        >
                            <i class="fal fa-sort-alt fa-fw fa"></i>
                        </button>
                    </div>
                    <!-- <select [(ngModel)]="street2">
                        <option *ngFor="let street of street2Names">
                            {{ street }}
                        </option>
                    </select> -->
                    <text-input-select
                        [value]="street2"
                        [options]="street2Names"
                        [textEditDisabled]="!manualOverwriteEnabled"
                        (onValueChange)="street2 = $event"
                        (onOptionsChange)="street2Names = $event"
                    ></text-input-select>
                </div>
            </fieldset>
            <p>
                ({{ newLocation?.Latitude?.toFixed(6) }},
                {{ newLocation?.Longitude?.toFixed(6) }})
            </p>
        </div>
    </div>
    <div class="button-bar">
        <button
            *ngFor="let btn of windowButtons"
            class="geoloc-point-window-button"
            (click)="btn.action()"
        >
            <i *ngIf="btn.icon" [class]="btn.icon"></i>
            {{ btn.text }}
        </button>
    </div>
    <div class="triangle-bottom"></div>
</div>
