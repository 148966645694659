import { Injectable } from "@angular/core";
import { DiagramObject } from "src/app/classes/DiagramObject";
import { Participant } from "src/app/classes/Participant";
import { CrashReportService } from "./crash-report.service";
import { GroupSelectionBuilder } from "src/app/classes/GroupSelection";

@Injectable({
    providedIn: "root"
})
export class SelectionService {
    private readonly groupSelectionBuilder = new GroupSelectionBuilder();

    constructor(private crashReport: CrashReportService) {}

    removeSelection() {
        const selected = DiagramObject.selectedObject;
        if (selected instanceof Participant) {
            const removalData = selected.remove();
            if (removalData) {
                this.crashReport.setParticipantHidden(
                    selected.participantIndex!,
                    removalData
                );
            }
        } else selected?.remove();
    }

    selectAllPositions(participant: Participant) {
        const allObjects = new Array<paper.Item>();
        for (const pos of participant.allPositions) {
            allObjects.push(pos.entireObject);
        }
        this.groupSelectionBuilder.build(allObjects);
    }
}
