import { Annotation } from "./Annotation";
import * as paper from "paper";
import { getDiagramZoomScale } from "src/app/shared/diagram-map";
import { Cursor } from "src/app/shared/cursor";
import { MenuItem } from "@progress/kendo-angular-menu";
import {
    borderColorOptions,
    fillColorOptions,
    lineStyleOptions,
    lineWeightOptions,
    removeOption
} from "../ellipsis-menu-options";
import { AnnotationNode } from "./AnnotationNode";
import { drawNode } from "../ControlNode";
import {
    DiagramElementAction,
    PropTrackingAction
} from "src/app/shared/Action";

export class RotationNode extends AnnotationNode {
    protected applyClassName() {
        this.className = "RotationNode";
    }

    constructor(pointOrEntireObject?: paper.Item) {
        if (!pointOrEntireObject) {
            const node = drawNode(new paper.Point(0, 0));

            const line = new paper.Path.Line({
                strokeColor: "white",
                strokeWidth: 1,
                locked: true
            });

            pointOrEntireObject = new paper.Group({
                children: [line, node],
                name: "rotation node",
                visible: false,
                data: {
                    cursor: Cursor.Spin
                }
            });
        }

        super(pointOrEntireObject);
    }

    onMouseDown(e: paper.MouseEvent) {
        new PropTrackingAction<number>({
            name: "rotate",
            elementRef: this.parentElement,
            propKey: "rotation"
        }).startRecording();
        super.onMouseDown(e);
        this.parentElement.entireObject.pivot =
            this.parentElement.shape.localToParent(
                this.parentElement.shape.bounds.center
            );
    }

    onMouseDrag = (e: paper.MouseEvent) => {
        // below is a refactored version of the old nodeRotate()
        const shape = this.parentElement.shape; // verbosity reducer for next line
        const newAngle =
            shape.localToGlobal(shape.bounds.center).subtract(e.point).angle -
            90;
        this.parentElement.rotation = newAngle;
    };
}

export class GeometricShapeNode extends AnnotationNode {
    get parentElement(): GeometricShape {
        return super.parentElement as GeometricShape;
    }

    protected onMouseDown(e?: paper.MouseEvent | undefined): void {
        super.onMouseDown(e);
        this.parentElement.rotationNode.visible = false;
    }

    protected onMouseUp(e: paper.MouseEvent): void {
        this.parentElement.rotationNode.visible = true;
        super.onMouseUp(e);
    }
}

export class GeometricShape extends Annotation {
    protected applyClassName() {
        this.className = "GeometricShape";
    }

    get ellipsisOptions(): MenuItem[] {
        return [
            lineWeightOptions,
            lineStyleOptions,
            borderColorOptions,
            fillColorOptions,
            removeOption
        ];
    }

    get rotationNode(): paper.Group {
        return this.entireObject.children["rotation node"];
    }

    get isInvisible() {
        return (
            !this.shape.strokeColor &&
            (!this.shape.fillColor ||
                this.shape.fillColor.equals(Annotation.nearlyInvisible))
        );
    }

    changeStrokeColor(color: paper.Color | null) {
        if (
            !color &&
            (!this.shape.fillColor ||
                this.shape.fillColor.equals(Annotation.nearlyInvisible))
        ) {
            this.remove();
            return;
        } // remove if invisible

        const changeStrokeColorAction = new DiagramElementAction({
            name: "change stroke color",
            elementRef: this
        });
        changeStrokeColorAction.startRecording();
        this.shape.strokeColor = Annotation.defaultStrokeColor = color;
        changeStrokeColorAction.stopRecording();

    }

    changeFillColor(color: paper.Color | null) {
        if (!color && !this.shape.strokeColor) {
            this.remove();
            return;
        } // remove if invisible

        const changeFillColorAction = new DiagramElementAction({
            name: "change fill color",
            elementRef: this
        });
        changeFillColorAction.startRecording();
        this.shape.fillColor = Annotation.defaultFillColor = color;
        changeFillColorAction.stopRecording();

    }

    select() {
        super.select();
        this.rotationNode!.visible = true;
    }

    deselect() {
        super.deselect();
        this.rotationNode!.visible = false;
    }

    repositionRotationNode() {
        const offset = this.shape.getNearestLocation(
            this.shape.bounds.topCenter
        ).offset;
        const p1 = this.shape.getPointAt(offset);
        const normalJut = this.shape.getNormalAt(offset).multiply(30);
        // .divide(this.shape.parent.scaling);
        // const normalJut = this.shape.getPointAt(offset).subtract(this.shape.bounds.center).normalize().multiply(30);
        let p2: paper.Point;

        if (normalJut.y < 0) {
            p2 = p1.add(normalJut);
        } else {
            p2 = p1.subtract(normalJut);
        }

        const line = this.rotationNode.children[0] as paper.Path;
        line.segments[0].point = p1;
        line.segments[1].point = p2;
        this.rotationNode.children[1].position = p2;
    }

    positionEllipsis(): void {
        const zoomScale = getDiagramZoomScale();
        this.ellipsisButton.position = this.shape
            .getNearestPoint(this.shape.bounds.topRight)
            .add(
                new paper.Point(25 / zoomScale, -25 / zoomScale).divide(
                    this.entireObject.scaling
                )
            );
    }
}
