<div class="stack">
    <top-panel
        [hasLoaded]="hasLoaded"
        [controlButtonsVisible]="controlButtonsVisible"
    ></top-panel>
    <help-bar #helpBar></help-bar>
    <img
        *ngIf="hasLoaded"
        class="north-arrow dont-save white-round-bg"
        [class.display-none]="isCropping"
        style="user-select: none"
        src="assets/SVG/Misc Icons/north arrow.svg"
        draggable="false"
    />
    <!-- <div *ngIf="isCropping" class="insets"> -->
    <cdt-diagram
        *ngIf="hasLoaded"
        id="cdt-diagram"
        [isEnlarged]="isCropping"
    ></cdt-diagram>

    <div
        class="main-page-grid"
        [class.panel-open]="sidePanelOpen"
        *ngIf="hasLoaded"
    >
        <side-panel class="side-panel"></side-panel>
        <map-controls class="center-panel pointer-events-none"></map-controls>
    </div>
    <bottom-bar *ngIf="hasLoaded" [isHidden]="isCropping"></bottom-bar>

    <div id="icon-set" style="visibility: hidden; position: absolute" id="svgs">
        <div id="Top-View">
            <ng-template ngFor let-icon [ngForOf]="topViewIcons">
                <img
                    class="injectable"
                    [src]="'assets/SVG/Top View/' + icon + '.svg'"
                    [attr.data-icon-id]="icon"
                />
            </ng-template>
            <ng-template ngFor let-icon [ngForOf]="hitAndRunIcons">
                <img
                    class="injectable"
                    [src]="'assets/SVG/hit-and-run/' + icon + '.svg'"
                    [attr.data-icon-id]="icon + '-hit-and-run'"
                />
            </ng-template>
        </div>
        <div id="Side-View">
            <ng-template ngFor let-icon [ngForOf]="sideViewIcons">
                <img
                    class="injectable"
                    [src]="'assets/SVG/Side View/' + icon + '.svg'"
                    [attr.data-icon-id]="icon"
                />
            </ng-template>
        </div>
        <div id="Bottom-View">
            <ng-template ngFor let-icon [ngForOf]="bottomViewIcons">
                <img
                    class="injectable"
                    [src]="'assets/SVG/Bottom View/' + icon + '.svg'"
                    [attr.data-icon-id]="icon"
                />
            </ng-template>
        </div>
        <div id="misc-ui">
            <ng-template ngFor let-icon [ngForOf]="miscIcons">
                <img
                    class="injectable"
                    [src]="'assets/SVG/Misc Icons/' + icon + '.svg'"
                    [attr.data-icon-id]="icon"
                />
            </ng-template>
        </div>
    </div>
</div>
