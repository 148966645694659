import { Component, Input, ViewChild } from "@angular/core";
import { ScaleBarComponent } from "../map-controls/scale-bar/scale-bar.component";
import { UIService } from "../shared/services";

@Component({
    selector: "bottom-bar",
    templateUrl: "./bottom-bar.component.html",
    styleUrls: ["./bottom-bar.component.scss"]
})

export class BottomBarComponent {
    @ViewChild("scaleBar")
    scaleBarEl: ScaleBarComponent;
    @Input() isHidden: boolean;
    // disclaimerTxt = "Background imagery does not depict time of crash. Diagram not to scale.";
    constructor(private ui: UIService) {}
    get isMobileOrTablet() {return this.ui.isMobileOrTablet; }
}
