<div id="mySidepanel" style="user-select: none">
    <kendo-tabstrip class="myTabstrip" [tabAlignment]="'center'">
        <kendo-tabstrip-tab [selected]="true">
            <ng-template kendoTabTitle>
                <div class="tab-title-btn">
                    <div class="icon-circle-wrapper">
                        <i class="fal fa-car fa-fw"></i>
                    </div>
                    <div class="tab-label">Participants</div>
                </div>
            </ng-template>
            <ng-template kendoTabContent>
                <div class="icon-list">
                    <kendo-splitbutton
                        [data]="pedestrianIcons"
                        (itemClick)="selectPedestrian($event)"
                        fillMode="link"
                        buttonClass="icon-drop-down"
                        [popupSettings]="{
                            appendTo: 'component',
                            popupClass: 'scrollable-popup'
                        }"
                        arrowButtonClass="arrow-button-thin"
                        style="box-shadow: none"
                    >
                        <div
                            class="side-panel-icon lg"
                            style="padding: 0; margin: 0"
                        >
                            <img
                                [title]="getIconName(selectedPedestrianIcon)"
                                draggable="true"
                                class="icon-image participant-icon"
                                (dragstart)="
                                    startDrag($event, selectedPedestrianIcon)
                                "
                                (dragend)="stopDrag($event)"
                                src="assets/SVG/Top View/{{
                                    selectedPedestrianIcon
                                }}.svg"
                            />
                            <div class="icon-name">
                                <span class="icon-name-text">{{
                                    getIconName(selectedPedestrianIcon)
                                }}</span>
                            </div>
                        </div>
                        <ng-template
                            kendoSplitButtonItemTemplate
                            let-icon
                            style="overflow-y: auto"
                        >
                            <div
                                class="side-panel-icon lg"
                                style="
                                    padding: 0;
                                    margin: 0;
                                    text-align: center;
                                "
                            >
                                <img
                                    [id]="icon.text"
                                    [title]="getIconName(icon.text)"
                                    draggable="true"
                                    class="icon-image participant-icon"
                                    (mousedown)="selectPedestrian($event)"
                                    (dragstart)="startDrag($event, icon.text)"
                                    (dragend)="stopDrag($event)"
                                    src="assets/SVG/Top View/{{
                                        icon.text
                                    }}.svg"
                                />
                                <div class="icon-name">
                                    <span class="icon-name-text">{{
                                        getIconName(icon.text)
                                    }}</span>
                                </div>
                            </div>
                        </ng-template>
                    </kendo-splitbutton>
                    <ng-template ngFor let-icon [ngForOf]="iconList">
                        <div class="side-panel-icon lg">
                            <img
                                [id]="icon"
                                [title]="getIconName(icon)"
                                draggable="true"
                                class="icon-image participant-icon"
                                (dragstart)="startDrag($event, icon)"
                                (dragend)="stopDrag($event)"
                                src="assets/SVG/Top View/{{ icon }}.svg"
                            />
                            <div class="icon-name">
                                <span class="icon-name-text">{{
                                    getIconName(icon)
                                }}</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngFor let-icon [ngForOf]="trailers">
                        <div class="side-panel-icon lg">
                            <img
                                [id]="icon"
                                [title]="getIconName(icon)"
                                draggable="true"
                                class="icon-image participant-icon"
                                (dragstart)="startDrag($event, icon)"
                                (dragend)="stopDrag($event)"
                                src="assets/SVG/Top View/{{ icon }}.svg"
                            />
                            <div class="icon-name">
                                <span class="icon-name-text">{{
                                    getIconName(icon)
                                }}</span>
                            </div>
                        </div>
                    </ng-template>
                    <kendo-splitbutton
                        [data]="animalIcons"
                        (itemClick)="selectAnimal($event)"
                        fillMode="link"
                        buttonClass="icon-drop-down"
                        [popupSettings]="{
                            appendTo: 'component',
                            popupClass: 'scrollable-popup'
                        }"
                        arrowButtonClass="arrow-button-thin"
                        style="box-shadow: none"
                    >
                        <div
                            class="side-panel-icon lg"
                            style="padding: 0; margin: 0"
                        >
                            <img
                                [title]="selectedAnimalIcon"
                                draggable="true"
                                class="icon-image participant-icon"
                                (dragstart)="
                                    startDrag($event, selectedAnimalIcon)
                                "
                                (dragend)="stopDrag($event)"
                                src="assets/SVG/Animals/{{
                                    selectedAnimalIcon
                                }}.svg"
                            />
                            <div class="icon-name">
                                <span class="icon-name-text">{{
                                    selectedAnimalIcon
                                }}</span>
                            </div>
                        </div>
                        <ng-template
                            kendoSplitButtonItemTemplate
                            let-icon
                            style="overflow-y: auto"
                        >
                            <div
                                class="side-panel-icon lg"
                                style="
                                    padding: 0;
                                    margin: 0;
                                    text-align: center;
                                "
                            >
                                <img
                                    [id]="icon.text"
                                    [title]="icon.text"
                                    draggable="true"
                                    class="icon-image participant-icon"
                                    (mousedown)="
                                        selectAnimal($event, icon.text)
                                    "
                                    (dragstart)="startDrag($event, icon.text)"
                                    (dragend)="stopDrag($event)"
                                    src="assets/SVG/Animals/{{ icon.text }}.svg"
                                />
                                <div class="icon-name">
                                    <span class="icon-name-text">{{
                                        icon.text
                                    }}</span>
                                </div>
                            </div>
                        </ng-template>
                    </kendo-splitbutton>

                </div>
            </ng-template>
        </kendo-tabstrip-tab>

        <!-- <kendo-tabstrip-tab [title]="'Trailers'">
            <ng-template kendoTabContent>
                <div class="icon-list">
                    <ng-template ngFor let-icon [ngForOf]="trailers">
                        <div class="side-panel-icon lg">
                            <img
                                [id]="icon"
                                [title]="getIconName(icon)"
                                draggable="true"
                                class="icon-image participant-icon"
                                (dragstart)="startDrag($event, icon)"
                                (dragend)="stopDrag($event)"
                                src="assets/SVG/Top View/{{ icon }}.svg"
                            />
                            <div class="icon-name">
                                <span class="icon-name-text">{{
                                    getIconName(icon)
                                }}</span>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </ng-template>
        </kendo-tabstrip-tab> -->

        <kendo-tabstrip-tab>
            <ng-template kendoTabTitle>
                <div class="tab-title-btn">
                    <div class="icon-circle-wrapper">
                        <span class="svg-sign">
                            <svg class="svg-sign"
                            viewBox="0 0 313 417"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xml:space="preserve"
                            xmlns:serif="http://www.serif.com/"
                            style="
                                fill-rule: evenodd;
                                clip-rule: evenodd;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-miterlimit: 1.5;
                            "
                        >
                            <path
                                d="M184.601,402.958l0,-90.586c-0.25,-0.617 34.54,-0 34.54,-0l86.441,-87.807l-0,-124.016l-89.575,-86.84l-120.825,-0l-88.264,88.973l0,121.883l88.264,87.807l42.175,-0c0.102,-0.042 -0,90.586 -0,90.586l-0,-90.586l47.244,-0"
                                style="fill: none; stroke-width: 25px"
                            />
                        </svg>
                        </span>

                    </div>
                    <div class="tab-label">Signs</div>
                </div>
            </ng-template>
            <ng-template kendoTabContent>
                <div class="icon-list">
                    <ng-template ngFor let-icon [ngForOf]="signageList">
                        <div class="side-panel-icon sm">
                            <img
                                [id]="icon"
                                draggable="true"
                                [class]="darkBgIfNeeded(icon)"
                                (dragstart)="startDrag($event, icon)"
                                (dragend)="stopDrag($event)"
                                src="assets/SVG/Signage/{{ icon }}.svg"
                            />
                        </div>
                    </ng-template>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab>
            <ng-template kendoTabTitle>
                <div class="tab-title-btn">
                    <div class="icon-circle-wrapper">
                        <i class="fal fa-tree fa-fw"></i>
                    </div>
                    <div class="tab-label">Objects</div>
                </div>
            </ng-template>
            <ng-template kendoTabContent>
                <div class="icon-list">
                    <ng-template ngFor let-icon [ngForOf]="objectsList">
                        <div class="side-panel-icon sm">
                            <img
                                [id]="icon"
                                draggable="true"
                                class="icon-image sign-icon"
                                (dragstart)="startDrag($event, icon)"
                                (dragend)="stopDrag($event)"
                                src="assets/SVG/Objects/{{ icon }}.svg"
                            />
                        </div>
                    </ng-template>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab>
            <ng-template kendoTabTitle>
                <div class="tab-title-btn">
                    <div class="icon-circle-wrapper">
                        <i class="fal fa-hammer fa-fw"></i>
                    </div>
                    <div class="tab-label">Tools</div>
                </div>
            </ng-template>
            <ng-template kendoTabContent>
                <div class="icon-list single-item-list">
                    <ng-template ngFor let-tool [ngForOf]="annotationToolList">
                        <button
                            [id]="tool.title"
                            class="side-panel-icon sm toggle-button"
                            (click)="
                                annotationButtonClick(tool.title, $event);
                                showHelp(tool.help, 7500)
                            "
                            (mousedown)="
                                showHelp(
                                    'Click to toggle the annotation tool.',
                                    3000
                                )
                            "
                        >
                            <img
                                draggable="false"
                                class="icon-image annotation-tool no-select"
                                [src]="tool.icon"
                            />
                            <div class="icon-name">
                                <span class="icon-name-text">{{
                                    tool.title
                                }}</span>
                            </div>
                        </button>
                    </ng-template>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</div>
