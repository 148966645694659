import {
    CargoBodyType,
    CMVConfig,
    NonMotoristData,
    NonMotoristDescription,
    StringCodeMap,
    VehicleData,
    VehicleBodyType,
    VehicleSpecialFunction
} from "../cdt-diagram/cdt-diagram-models";

export enum Icon {
    // Signage
    Sign_Generic = "Sign_Generic",
    Sign_Do_Not_Enter = "Sign_Do_Not_Enter",
    Sign_No_Left_Turn = "Sign_No_Left_Turn",
    Sign_No_Right_Turn = "Sign_No_Right_Turn",
    Sign_Traffic_Light = "Sign_Traffic_Light",
    Sign_Red_Light = "Sign_red_light",
    Sign_Yellow_Light = "Sign_yellow_light",
    Sign_Green_Light = "Sign_green_light",
    Sign_No_Turns = "Sign_No_Turns",
    Sign_Railroad_Crossing = "Sign_Railroad_Crossing",
    Sign_Stop_Sign = "Sign_Stop_Sign",
    Sign_Yield = "Sign_Yield",
    Sign_Collision = "Sign_Collision",
    Sign_Fire = "Sign_Fire",
    Arrow_left_right_or_straight = "Arrow_left, right, or straight",
    Arrow_left_or_straight = "Arrow_left or straight",
    Arrow_left_turn_only = "Arrow_left turn only",
    Arrow_left_turn = "Arrow_left turn",
    Arrow_right_or_straight = "Arrow_right or straight",
    Arrow_right_turn_only = "Arrow_right turn only",
    Arrow_right_turn = "Arrow_right turn",
    Arrow_straight_only = "Arrow_straight only",
    Arrow_U_turn = "Arrow_U turn",
    Light_Blue_Circle = "x-button"
}

/** NCIC color code to CSS rgb(x, y, z) string map */
export const colorCodeRGB = {
    SIL: "rgb(208, 213, 219)",
    AME: "rgb(153, 102, 204)",
    BGE: "rgb(245, 245, 220)",
    BLK: "rgb(0, 0, 0)",
    BLU: "rgb(0, 0, 255)",
    DBL: "rgb(0, 0, 139)",
    LBL: "rgb(173, 216, 230)",
    BRZ: "rgb(205, 127, 50)",
    BRO: "rgb(155, 103, 60)",
    MAR: "rgb(128, 0, 32)",
    CAM: "rgb(120, 134, 107)",
    COM: "rgb(219, 228, 235)",
    CPR: "rgb(184, 115, 51)",
    CRM: "rgb(255, 253, 208)",
    GLD: "rgb(255, 215, 0)",
    GRY: "rgb(128, 128, 128)",
    GRN: "rgb(0, 172, 0)",
    DGR: "rgb(0, 100, 0)",
    LGR: "rgb(144, 238, 144)",
    LAV: "rgb(230, 230, 250)",
    MVE: "rgb(224, 176, 255)",
    "MUL/COL": "rgb(255, 255, 255)",
    ONG: "rgb(255, 165, 0)",
    PNK: "rgb(255, 192, 203)",
    PLE: "rgb(128, 0, 128)",
    RED: "rgb(255, 0, 0)",
    TAN: "rgb(210, 180, 140)",
    TPE: "rgb(72, 60, 50)",
    TEA: "rgb(0, 128, 128)",
    TRQ: "rgb(64, 224, 208)",
    WHI: "rgb(255, 255, 255)",
    YEL: "rgb(255, 255, 0)",
    OTH: "rgb(255, 255, 255)",
    UNK: "rgb(208, 213, 219)"
} as StringCodeMap;

export const labelColorForCode = {
    SIL: "black",
    AME: "white",
    BGE: "black",
    BLK: "white",
    BLU: "white",
    DBL: "white",
    LBL: "black",
    BRZ: "white",
    BRO: "white",
    MAR: "white",
    CAM: "white",
    COM: "black",
    CPR: "white",
    CRM: "black",
    GLD: "black",
    GRY: "white",
    GRN: "white",
    DGR: "white",
    LGR: "black",
    LAV: "black",
    MVE: "black",
    "MUL/COL": "black",
    ONG: "black",
    PNK: "black",
    PLE: "white",
    RED: "white",
    TAN: "black",
    TPE: "white",
    TEA: "white",
    TRQ: "black",
    WHI: "black",
    YEL: "black",
    OTH: "black",
    UNK: "black"
} as StringCodeMap;

export function getIconUrl(icon: Icon): string {
    let pathBase = "assets/SVG/";

    if (icon.startsWith("Sign_") || icon.startsWith("Arrow_")) {
        pathBase += "Signage/";
    }

    return pathBase + icon + ".svg";
}

export function getIconStringUrl(icon: string): string {
    let pathBase = "assets/SVG/";

    if (icon.startsWith("Sign_") || icon.startsWith("Arrow_")) {
        pathBase += "Signage/";
    } else if (icon.startsWith("Object_")) {
        pathBase += "Objects/";
    } else {
        pathBase += "Animals/";
    }

    return pathBase + icon + ".svg";
}

export function getIconScale(icon: string): number {
    const nameParts = icon.split("_");
    const thing = nameParts[0];

    if (nameParts[1] !== "Collision") {
        switch (thing) {
            case IconId.PassengerCar:
                return 0.57;
            case IconId.SUV:
                return 0.56;
            case IconId.Pickup:
                return 0.5;
            case IconId.HeavyTruck:
                return 0.92;
            case IconId.LightTruck:
                return 0.67;
            case IconId.Motorcycle:
                return 0.25;
            case IconId.Bus:
                return 1.25;
            case IconId.Ambulance:
                return 0.75;
            case IconId.ATV:
                return 0.3;
            case IconId.Bicycle:
                return 0.24;
            case IconId.Pedestrian:
                return 0.4;
            case IconId.Wheelchair:
                return 0.05;
            case IconId.UtilityTrailer:
                return 0.09;
            case IconId.LowSpeedVehicle:
                return 0.08;
            case "Sign":
                return 0.2;
            case "Arrow":
                return 1.05;
            case "Object":
                return 0.5;
            case "Deer":
                return 0.029;
            case "Alligator":
                return 0.2;
            case "Panther":
                return 0.1;
            case "Chicken":
            case "Dog":
            case "Turtle":
            case "Bird":
            case "Fox":
            case "Hog":
                return 0.04;
            case "Horse":
            case "Cow":
                return 0.07;
            case "Deer2":
            case "Bear":
            case "Deer3":
                return 0.05;
            default:
                if (parseFloat(thing)) {
                    return 0.59;
                } else {
                    return 0.25;
                }
        }
    } else {
        return 0.48;
    }
}

export enum IconId {
    PassengerCar = "1",
    SUV = "2",
    Pickup = "3",
    PassengerVan = "4",
    HeavyTruck = "5",
    LightTruck = "6",
    Motorcycle = "7",
    CargoVan = "8",
    Bus = "9",
    PoliceCar = "10",
    Ambulance = "11",
    GolfCart = "12",
    ATV = "13",
    Bicycle = "14",
    Pedestrian = "15",
    UtilityTrailer = "16",
    LowSpeedVehicle = "17",
    Wheelchair = "18"
}

export const animalsIcons = [
    "Alligator",
    "Deer",
    "Bear",
    "Dog",
    "Turtle",
    "Bird",
    "Chicken",
    "Cow",
    "Deer2",
    "Deer3",
    "Fox",
    "Hog",
    "Horse",
    "Panther"
];

export const idToIconNameMap = {
    "1": "Passenger Car",
    "2": "SUV",
    "3": "Pickup",
    "4": "Passenger Van",
    "5": "Heavy Truck",
    "6": "Medium Truck",
    "7": "Motorcycle / Moped",
    "8": "Cargo Van",
    "9": "Bus / Motor Coach",
    "10": "Police Car",
    "11": "Ambulance",
    "12": "Golf Cart",
    "13": "ATV",
    "14": "Bicycle",
    "15": "Pedestrian",
    "16": "Utility Trailer",
    "17": "Low Speed Vehicle",
    "18": "Wheelchair",
    "19": "Hit and Run"
} as StringCodeMap;

export const pedestrianIcons = ["15", "18"];
export const nonMotoristIcons = [...pedestrianIcons, "14"];

export function determineVehicleIcon(veh: VehicleData): string {
    if (veh.bodyType) {
        if (
            veh.bodyType == VehicleBodyType.Moped ||
            veh.bodyType == VehicleBodyType.Motorcycle
        ) {
            return IconId.Motorcycle;
        }

        if (
            veh.bodyType == VehicleBodyType.FarmLaborVehicle ||
            veh.bodyType == VehicleBodyType.ATV
        ) {
            return IconId.ATV;
        }

        if (
            [
                VehicleBodyType.MotorCoach,
                VehicleBodyType.MotorHome,
                VehicleBodyType.Bus
            ].includes(veh.bodyType)
        ) {
            return IconId.Bus;
        }

        if (veh.bodyType == VehicleBodyType.Other) {
            return IconId.LowSpeedVehicle;
        }
    }

    if (veh.specialFunction) {
        if (veh.specialFunction == VehicleSpecialFunction.Police) {
            return IconId.PoliceCar;
        }

        if (veh.specialFunction == VehicleSpecialFunction.Ambulance) {
            return IconId.Ambulance;
        }
    }

    if (
        !veh.bodyType ||
        (veh.bodyType &&
            (veh.bodyType == VehicleBodyType.Unknown ||
                veh.bodyType == VehicleBodyType.NotProvided))
    ) {
        if (
            veh.specialFunction &&
            VehicleSpecialFunction[veh.specialFunction].includes("Bus")
        ) {
            return IconId.Bus;
        }

        if (veh.specialFunction == VehicleSpecialFunction.Taxi) {
            return IconId.PassengerCar;
        }

        if (
            veh.cargoType &&
            ![
                // note the NOT
                CargoBodyType.Other,
                CargoBodyType.Unknown,
                CargoBodyType.NoCargo,
                CargoBodyType.VehicleTowingAnother,
                CargoBodyType.NotApplicable,
                CargoBodyType.NotProvided
            ].includes(veh.cargoType)
        ) {
            if (veh.cargoType == CargoBodyType.Bus) {
                return IconId.Bus;
            } else {
                return IconId.HeavyTruck;
            }
        }

        if (
            veh.cmvConfig &&
            ![
                // note the NOT
                CMVConfig.Other,
                CMVConfig.Unknown,
                CMVConfig.HazardousMaterials,
                CMVConfig.NotProvided
            ].includes(veh.cmvConfig)
        ) {
            if (CMVConfig[veh.cmvConfig].includes("Bus")) {
                return IconId.Bus;
            } else {
                return IconId.HeavyTruck;
            }
        }

        return IconId.PassengerCar;
    }

    if (veh.bodyType) {
        switch (veh.bodyType) {
            case VehicleBodyType.PassengerCar:
                return IconId.PassengerCar;
            case VehicleBodyType.PassengerVan:
                return IconId.PassengerVan;
            case VehicleBodyType.Pickup:
                return IconId.Pickup;
            case VehicleBodyType.LowSpeedVehicle:
                return IconId.GolfCart;
            case VehicleBodyType.SUV:
                return IconId.SUV;
            case VehicleBodyType.CargoVan:
                return IconId.CargoVan;
            case VehicleBodyType.OtherLightTrucks:
                return IconId.LightTruck;
            case VehicleBodyType.MediumToHeavyTrucks:
                return IconId.HeavyTruck;
        }
    }

    return IconId.PassengerCar;
}

export function determineNonMotoristIcon(nm: NonMotoristData): string {
    if (nm.desc) {
        switch (nm.desc) {
            case NonMotoristDescription.Bicyclist:
            case NonMotoristDescription.OtherCyclist:
                return IconId.Bicycle;
        }
    }

    return IconId.Pedestrian;
}
