import { environment } from "./../environments/environment";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DemoPageComponent } from "./demo-page/demo-page.component";
import { MainPageComponent } from "./main-page/main-page.component";
import { TestPageComponent } from "./test-page/test-page.component";
import { NotAuthPageComponent } from "./not-auth-page/not-auth-page.component";

const routes: Routes = environment.production
    ? [
          { path: "diagram", component: MainPageComponent },
          { path: "not-authorized", component: NotAuthPageComponent },
          { path: "", redirectTo: "not-authorized", pathMatch: "full" },
          { path: "**", redirectTo: "/not-authorized", pathMatch: "full" }
      ]
    : [
          { path: "demo-page", component: DemoPageComponent },
          { path: "diagram", component: MainPageComponent },
          { path: "test", component: TestPageComponent },
          { path: "not-authorized", component: NotAuthPageComponent },
          { path: "", redirectTo: "test", pathMatch: "full" },
          { path: "**", redirectTo: "/test", pathMatch: "full" }
      ];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
