<div
    id="map-wrapper"
    class="pointer-events-auto"
    [class.is-enlarged]="isEnlarged"
>
    <div #map id="map">
        <div class="insets" *ngIf="isCropping">
            <inset-tool
                *ngFor="let inset of insets"
                [options]="inset"
                class="insets"
            >
            </inset-tool>
        </div>
        <img
            #canHitch
            id="can-hitch"
            src="assets/SVG/Misc Icons/link-50.png"
            style="display: none"
        />
        <canvas #diagram id="diagram" resize="true"></canvas>
        <div #textboxes id="textboxes"></div>
        <canvas #textboxRects id="textboxRects" resize="true"></canvas>
    </div>
    <kendo-contextmenu
        #ellipsisMenu
        [items]="items"
        [popupAlign]="{ horizontal: 'left', vertical: 'bottom' }"
        [popupAnimate]="{ direction: 'up', duration: 100 }"
        (select)="onEllipsisMenuItemSelect($event)"
    >
        <ng-template kendoMenuItemTemplate let-item="item">
            <input
                *ngIf="item.data == 'label input'"
                type="text"
                [value]="menuInputValue"
                (keydown)="onKeyLabel($event)"
                onmouseenter="this.select()"
                style="width: 100%"
            />

            <span
                *ngIf="
                    item.data == 'color' ||
                    item.data == 'fill' ||
                    item.data == 'font color' ||
                    item.data == 'arrow color'
                "
                style="width: 1em; height: 1em"
                [ngStyle]="{
                    background: item.rgb ? item.rgb : 'none',
                    border: '1px black solid'
                }"
            >
            </span>

            {{ item.text }}
        </ng-template>
    </kendo-contextmenu>
    <popup-menu #animalSwap [title]="'Change Icon'" [moveable]="true">
        <icon-grid
            [directory]="'assets/SVG/Animals/'"
            [icons]="animalIcons"
            (selectEvent)="swapIcon($event)"
        >
        </icon-grid>
    </popup-menu>
    <popup-menu #locInfo [title]="'Location Info'" [moveable]="true">
        <location-info></location-info>
    </popup-menu>
</div>
<geoloc-point-menu #geolocMenu class="pos-abs"></geoloc-point-menu>
