<div class="report-info" *ngIf="isReady">
    <div class="report-field">
        <span class="field-label">Location</span>
        <div class="field-data" style="text-align: justify">
            <div style="font-weight: bold">{{locationType}}</div>
            <div>{{preposition}} {{street1}}</div>
            <div>{{relationToStreet2.toLowerCase()}} {{street2}}</div>
            <div>{{placeAndPoint}}</div>
            <div>({{lat}}, {{lng}})</div>
        </div>
    </div>
    <hr>
    <div *ngFor="let field of globalFields" class="report-field">
        <span class="field-label">{{field[0]}}</span><span class="field-data">{{field[1]}}</span>
    </div>
    <hr>
    <div class="report-field">
        <span class="field-label">Number of Vehicles</span><span class="field-data">{{vehicles ? vehicles.length : 0}}</span>
    </div>
    <hr>
    <div *ngFor="let veh of vehicles" style="flex-direction: column" class="report-field">
        <div>Vehicle {{veh.number}}</div>
        <div *ngFor="let entry of getParticipantData(veh)" class="report-field tabbed">
            <span class="field-label">{{entry[0] | titlecase}}</span><span class="field-data">{{entry[1]}}</span>
        </div>
    </div>
    <hr>
    <div class="report-field">
        <span class="field-label">Number of Non-Motorists</span><span class="field-data">{{nonMotorists ? nonMotorists.length : 0}}</span>
    </div>
    <hr>
    <div *ngFor="let nm of nonMotorists" style="flex-direction: column" class="report-field">
        <div>Non-Motorist {{nm.number}}</div>
        <div *ngFor="let entry of getParticipantData(nm)" class="report-field tabbed">
            <span class="field-label">{{entry[0] | titlecase}}</span><span class="field-data">{{entry[1]}}</span>
        </div>
    </div>
</div>
