<fieldset class="select-container" [disabled]="disabled">
    <input
        class="select-edit-input"
        type="text"
        *ngIf="!textEditDisabled"
        [(ngModel)]="value"
        (change)="createNewOption(); onChange()"
        (focus)="selectOnFocus($event)"
    />
    <select class="select-edit" [(ngModel)]="value" (change)="onChange()">
        <option *ngFor="let option of options">
            {{ option }}
        </option>
    </select>
</fieldset>
