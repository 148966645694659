<div
    #popUpMenu
    (mouseleave)="mouseLeavesPopup()"
    class="popup-menu"
    [style.left]="isShown ? windowLeft : '-999px'"
    [style.top]="isShown ? windowTop : '-999px'"
>
    <img *ngIf="hasXBtn" class="x-btn" src="assets/SVG/Misc Icons/xmark-solid.svg" (mousedown)="close()"/>
    <h2 *ngIf="title" class="popup-title">{{ title }}</h2>
    <div class="popup-content">
        <ng-content></ng-content>
    </div>
</div>
