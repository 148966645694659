import { Component, OnInit } from "@angular/core";
// import { geoServiceData } from "../geoloc-point-menu/crash-point-update";
import { GeolocationService } from "../shared/services/geolocation.service";
import { DiagramService } from "../shared/services/diagram.service";

@Component({
    selector: "location-info",
    templateUrl: "./location-info.component.html",
    styleUrls: ["./location-info.component.scss"]
})
export class LocationInfoComponent implements OnInit {
    isReady = false;

    constructor(private diagram: DiagramService, private geo: GeolocationService) {}

    get loc() {
        return this.geo.geoServiceData.Location;
    }

    get street1() {
        return this.loc.StreetName;
    }
    get street2() {
        return this.loc.NearestIntersectingStreetName;
    }
    get locationType() {
        if (this.loc.RelationshipToNetwork == "SEGMENT") {
            return "ROAD SEGMENT";
        }

        return this.loc?.RelationshipToNetwork;
    }
    get preposition() {
        switch (this.loc.RelationshipToNetwork) {
            case "SEGMENT":
            case "RAMP":
                return "On";
            case "INTERSECTION":
            case "OFFROADWAY":
                return "At";
        }

        return "";
    }
    get relationToStreet2() {
        switch (this.loc.RelationshipToNetwork) {
            case "SEGMENT":
            case "RAMP":
                return `${this.loc.NearestIntersectionOffsetFeet} feet ${this.loc.NearestIntersectionOffsetDir} of`;
            case "INTERSECTION":
                return "&";
        }

        return "";
    }
    get placeAndPoint() {
        return `${this.loc.CityName}, ${this.loc.CountyName} County, ${this.loc.StateName} `; // +
        // `(${this.loc.Latitude.toFixed(6)}, ` +
        // `${this.loc.Longitude.toFixed(6)})`
    }

    ngOnInit(): void {
        this.diagram.isReady.when(true, () => {
            setTimeout(() => {
                this.isReady = true;
            });
        });
    }
}
