import { compressSync, decompressSync, strFromU8, strToU8 } from "fflate";

export enum DiffWorkerReqType {
    CREATE_PATCH,
    UNDO,
    REDO
}

export enum DiffWorkerResType {
    LOG,
    PATCH_OBJ,
    RESTORE
}

export type DiffWorkerData = [number, string, string] | [string, Uint8Array];

export interface DiffWorkerReq {
    type: DiffWorkerReqType;
    data: DiffWorkerData;
}

export interface DiffWorkerRes {
    type: DiffWorkerResType;
    data: any;
}

type JSONTransformer = (this: any, key: string, value: any) => any;

export function compressString(str: string) {
    const buf = strToU8(str);
    return compressSync(buf, { level: 9, mem: 12 });
}

export function compressObject(obj: object, replacer?: JSONTransformer): Uint8Array {
    const strRep = JSON.stringify(obj, replacer);
    return compressString(strRep);
}

export function decompressString(compressed: Uint8Array) {
    return strFromU8(decompressSync(compressed));
}

export function decompressObject(
    compressed: Uint8Array,
    reviver?: JSONTransformer
): object {
    const decompressed = decompressString(compressed);
    return JSON.parse(decompressed, reviver);
}

export function pack(...[obj, replacer]: Parameters<typeof compressObject>): Blob {
    return new Blob([compressObject(obj, replacer)]);
}

export async function unpack(
    packed: Blob,
    reviver?: JSONTransformer
): Promise<object> {
    const data = await blobToUInt8Array(packed);
    return decompressObject(data, reviver);
}

export function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

export function base64ToBlob(base64: string, mimeType = "text/plain") {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
}

function blobToUInt8Array(blob: Blob): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const arrayBuffer = reader.result as ArrayBuffer;
            const uint8Array = new Uint8Array(arrayBuffer);
            resolve(uint8Array);
        };
        reader.onerror = (error) => {
            reject(error);
        };
        reader.readAsArrayBuffer(blob);
    });
}

export class EncodedJSON {
    static encode(obj: object, replacer?: JSONTransformer) {
        const instance = new EncodedJSON();
        instance.encode(obj, replacer);
        return instance;
    }

    static decode(encoded: EncodedJSON | object, reviver?: JSONTransformer) {
        if (!encoded.hasOwnProperty("decode")) {
            encoded = new EncodedJSON(encoded["codeStr"]);
        }

        return (encoded as EncodedJSON).decode(reviver);
    }

    constructor(codeStr: string = "") {
        this.codeStr = codeStr;
    }

    private codeStr: string = "";

    encode(obj: object, replacer?: JSONTransformer) {
        this.codeStr = btoa(JSON.stringify(obj, replacer));
    }

    decode(reviver?: JSONTransformer) {
        return JSON.parse(atob(this.codeStr), reviver);
    }
}
