import { Component, OnInit } from "@angular/core";

@Component({
    selector: "page-disabler",
    templateUrl: "./page-disabler.component.html",
    styleUrls: ["./page-disabler.component.scss"]
})
export class PageDisablerComponent implements OnInit {
    static globalInstance: PageDisablerComponent;
    private disableCount = 0;

    pageIsDisabled = false;
    doGrayOut = false;

    showMessageBox = false;
    buttons = ["OK"];
    message = "Hello";
    messageTitle = "Popup Message";
    messageClasses: string | undefined;
    messageStyles: string | undefined;
    functions?: { [btnName: string]: Function };
    resolver: Function;

    constructor() {
        PageDisablerComponent.globalInstance = this;
    }

    disablePage(visible = false) {
        this.pageIsDisabled = true;

        if (visible || window.matchMedia("(any-hover: none)").matches) {
            this.doGrayOut = true;
        }

        ++this.disableCount;
    }

    enablePage() {
        if (--this.disableCount <= 0) {
            this.pageIsDisabled = false;
            this.doGrayOut = false;
            if (this.disableCount < 0) {
                this.disableCount == 0;
            }
        }
    }

    initPromptBox(
        title: string,
        msg: string,
        btns = ["OK"],
        classes: string | undefined = "at-least-20",
        styles: string | undefined = undefined,
        functions?: { [btnName: string]: Function }
    ) {
        this.disablePage();
        this.buttons = btns;
        this.message = msg;
        this.messageTitle = title;
        this.messageClasses = classes;
        this.messageStyles = styles;
        this.functions = functions;
        this.showMessageBox = true;
    }

    resolve(btnName: string) {
        this.showMessageBox = false;
        this.enablePage();
        this.resolver(btnName);
    }

    ngOnInit(): void {}
}
