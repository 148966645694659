import { Component, ViewChild, AfterViewInit, NgZone } from "@angular/core";
import * as CrashFormModels from "../cdt-diagram/cdt-diagram-models";
import { GridComponent, RowClassArgs } from "@progress/kendo-angular-grid";
import { take } from "rxjs/operators";
import { UIService } from "../shared/services/ui.service";
import { CrashReportService } from "../shared/services/crash-report.service";
import { DiagramAutomationService } from "../shared/services/diagram-automation.service";
import { CRUDService } from "../shared/services/crud.service";
import { DiagramElementAction } from "../shared/Action";
import { NonMotorist } from "../classes/NonMotorist";
import { Vehicle } from "../classes/Vehicle";
import { DiagramObject } from "../classes/DiagramObject";

@Component({
    selector: "crash-report-grid",
    templateUrl: "./crash-report-grid.component.html",
    styleUrls: ["./crash-report-grid.component.scss"]
})
export class CrashReportGridComponent implements AfterViewInit {
    @ViewChild("crGrid")
    public grid: GridComponent;
    showingImage = -1;
    private flashingRows = new Set<number>();

    get crashParticipants(): CrashFormModels.ParticipantData[] {
        return this.report.participants;
    }
    get height(): number {
        if (this.crashParticipants && this.crashParticipants.length > 0) {
            if (this.crashParticipants.length <= 4) {
                return this.crashParticipants.length * 75 + 30;
            } else {
                return 330;
            }
        }
        return 105;
    }
    constructor(
        private ngZone: NgZone,
        private ui: UIService,
        private report: CrashReportService,
        private automation: DiagramAutomationService,
        private crud: CRUDService
    ) {
        this.ui.legend = this;
    }

    ngAfterViewInit() {
        this.fitColumns();
    }

    public onDataStateChange(): void {
        this.fitColumns();
    }

    get gridDisabled() {
        return this.ui.gridDisabled;
    }

    set gridDisabled(bool: boolean) {
        this.ui.gridDisabled = bool;
    }

    private flashTimeout = 0;

    flashRows(rows: number[]) {
        rows.sort();

        setTimeout(() => this.grid.scrollTo({ row: rows[0] }));

        for (const row of rows) {
            this.flashingRows.add(row);
        }

        window.clearTimeout(this.flashTimeout);

        this.flashTimeout = window.setTimeout(() => {
            this.flashingRows.clear();
        }, 3001);
    }

    // Use an arrow function to capture the 'this' execution context of the class.
    public rowCallback = (context: RowClassArgs) => {
        if (this.flashingRows.has(context.index)) {
            return { "flash-row": true, "tr-bottom-border": true };
        } else if (!context.dataItem.isDiagrammed) {
            return { "validation-flag": true, "tr-bottom-border": true };
        } else {
            return { "tr-bottom-border": true };
        }
    };

    private fitColumns(): void {
        this.ngZone.onStable
            .asObservable()
            .pipe(take(1))
            .subscribe(() => {
                this.grid.autoFitColumns();
            });
    }

    /** Gets the "Area of Initial Impact" image associated with the provided VehicleImpactArea */
    public getAOIIImage(impactArea: CrashFormModels.VehicleImpactArea): string {
        if (impactArea && impactArea !== -1) {
            return `assets/AOII/AOII_${impactArea}.png`;
        } else {
            return `assets/AOII/AOII_blank.png`;
        }
    }

    public getImpactText(
        initialImpact: CrashFormModels.VehicleImpactArea | undefined
    ) {
        return initialImpact ? initialImpact.toString() : "_";
    }

    public async checkBoxChangeHandler(e: Event, i: number) {
        const target = e.target as HTMLInputElement;
        // makeUndoable();

        if (target.checked && this.report.crashReportData) {
            const index = this.report.participantsIndexMap.get(
                this.report.participants[i].number
            ) as number;

            if (!this.report.restoreParticipant(i)) {
                const placeParticipantAction = new DiagramElementAction({
                    name: "add participant"
                });
                placeParticipantAction.startRecording();
                let newParticipant: Vehicle | NonMotorist;
                if (
                    this.report.participants[i].number[0] == "V" &&
                    this.report.crashReportData.vehicles
                ) {
                    newParticipant = await this.automation.placeVehicle(
                        this.report.crashReportData.vehicles[index],
                        i
                    );
                } else if (this.report.crashReportData.nonMotorists) {
                    newParticipant = await this.automation.placeNonMotorist(
                        this.report.crashReportData.nonMotorists[index],
                        i
                    );
                }
                DiagramObject.select(newParticipant!);
                placeParticipantAction.stopRecording(newParticipant!);
            }
        } else {
            this.report.hideParticipant(i);
        }
    }
}
