import * as paper from "paper";
import { PageDisablerComponent } from "./page-disabler.component";

export function disablePage(visible = false): void {
    PageDisablerComponent.globalInstance.disablePage(visible);
}

export function enablePage(): void {
    PageDisablerComponent.globalInstance.enablePage();
}

/** Disables the page and displays a pop-up message box until the user clicks one of the buttons
 *
 * Note: Will not work unless Angular has finished initializing the content of the PageDisabler component!
 * @param msg The message the pop-up will display
 * @param btns An array of strings, each becoming a button at the bottom of the box
 * @returns A Promise which resolves with the text of the button the user ends up clicking
 */
export async function promptBox(
    title: string,
    msg: string,
    btns = ["OK"],
    classes: string | undefined = "at-least-20",
    styles: string | undefined = undefined,
    functions?: { [btnName: string]: Function }
): Promise<string> {
    if (!btns.length) {
        return "";
    }

    PageDisablerComponent.globalInstance.initPromptBox(
        title,
        msg,
        btns,
        classes,
        styles,
        functions
    );

    return new Promise((resolve) => {
        PageDisablerComponent.globalInstance.resolver = resolve;
    });
}

var majorDrawCounter = 0;

/** Disables the page and paper auto updates (so intermediate drawings don't show). */
export function startMajorDraw() {
    ++majorDrawCounter;

    if (majorDrawCounter == 1) {
        disablePage();
        paper.projects[0].view.autoUpdate = false; // disabled until all drawing is complete
    }
}

/** Enables the page and paper auto updates */
export function finishMajorDraw() {
    --majorDrawCounter;

    if (majorDrawCounter == 0) {
        if (!paper.projects[0].view.update()) {
            console.log("paper.projects[0].view update error");
        }
        paper.projects[0].view.autoUpdate = true;

        enablePage();
    }
}
