import { Component, OnInit } from "@angular/core";

@Component({
    selector: "help-bar",
    templateUrl: "./help-bar.component.html",
    styleUrls: ["./help-bar.component.scss"]
})
export class HelpBarComponent implements OnInit {
    message = "Hello, this is a plain text message";
    showHelp = false;
    timeout: number;

    constructor() {}

    ngOnInit(): void {}

    show(msg: string, dur?: number) {
        this.message = msg;
        this.showHelp = true;
        window.clearTimeout(this.timeout);

        if (dur) {
            this.timeout = window.setTimeout(() => this.close(), dur);
        }
    }

    close() {
        this.showHelp = false;
    }

    isShown() {
        return this.showHelp;
    }
}
