import { Component, OnInit } from "@angular/core";
import { leafletMap } from "src/app/shared/diagram-map";
import { DiagramService } from "src/app/shared/services/diagram.service";

interface mapScaleDivision {
    width: number;
    measure: number;
}

@Component({
    selector: "scale-bar",
    templateUrl: "./scale-bar.component.html",
    styleUrls: ["./scale-bar.component.scss"]
})
export class ScaleBarComponent implements OnInit {
    divisions: Array<mapScaleDivision> = new Array(3);
    maxWidth = 250;
    unit = "Feet";

    constructor(private diagram: DiagramService) {}

    updateScale(map: L.Map) {
        const y = map.getSize().y / 2;
        const maxMeters = map.distance(
            map.containerPointToLatLng([0, y]),
            map.containerPointToLatLng([this.maxWidth, y])
        );
        const maxFeet = maxMeters * 3.2808399;

        if (maxFeet > 5280) {
            this.unit = "Miles";
            const maxMiles = maxFeet / 5280;
            const miles = this.getRoundNum(maxMiles);
            this.updateDivisions(miles, miles / maxMiles);
        } else {
            this.unit = "Feet";
            const feet = this.getRoundNum(maxFeet);
            this.updateDivisions(feet, feet / maxFeet);
        }
    }

    private updateDivisions(dist: number, ratio: number) {
        const barLength = Math.round(this.maxWidth * ratio);
        const halfLength = Math.round(barLength / 2);
        const quarterLength = Math.round(barLength / 4);
        const halfMeasure = dist / 2;
        const quarterMeasure = dist / 4;
        this.divisions[0] = { width: quarterLength, measure: quarterMeasure };
        this.divisions[1] = {
            width: quarterLength,
            measure: halfMeasure
        };
        this.divisions[2] = {
            width: halfLength,
            measure: dist
        };
    }

    isOdd(n: number) {
        return n % 2;
    }

    private getRoundNum(n: number) {
        const pow10 = Math.pow(10, (Math.floor(n) + "").length - 1);

        let d = n / pow10;
        d = d >= 10 ? 10 : d >= 5 ? 5 : d >= 3 ? 3 : d >= 2 ? 2 : 1;

        return pow10 * d;
    }

    ngOnInit(): void {
        this.divisions.fill({ width: 0, measure: 0 });
        this.diagram.isReady.when(true, () => {
            this.updateScale(leafletMap);
            this.applyUpdateHandler();
        });
    }

    private applyUpdateHandler() {
        leafletMap.on("zoomend", () => {
            this.updateScale(leafletMap);
        });
    }
}
