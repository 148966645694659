import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'not-auth-page',
  templateUrl: './not-auth-page.component.html',
  styleUrls: ['./not-auth-page.component.scss']
})
export class NotAuthPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
